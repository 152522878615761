import React from "react";
import styles from "./Content.module.css";
import Loader from "../HelperComponents/Loader";
import Platforms from "../Home/Platforms";
import CustomTitleTab from "../HelperComponents/CustomTitleTab";
import HorizentalGrid from "../HelperComponents/HorizentalGrid.js";
import playIcon from "../../Assets/home/Play Icon.png";
import Lobby from "../ContentComponents/LobbyType/Lobby";
import UnLobby from "../ContentComponents/LobbyType/UnLobby";

const Content = (props) => {
  const {
    platformLoading,
    platforms,
    selectedPlatforms,
    setSelectedPlatforms,
    activeCat,
    homeAllSegmentData,
    games,
    allAvailableGames,
    setGames,
    showLoader,
    multipleSelectedPlatforms,
    setMulitpleSelectedPlatforms,
    page,
    setPage,
    setSearchData,
    searchData,
    setUpdateAllPlatForm,
    HandleSearchButton,
  } = props;

  const renderContent = (activeCategory) => {
    if (activeCategory?.child_categories?.length > 0) {
      if (platformLoading || platforms === 0) {
        return <Loader />;
      } else {
        //render HomePage
        return (
          <>
            {homeAllSegmentData.map((segment1) => (
              <div
                key={segment1.id}
                style={{
                  width: "100%",
                }}
              >
                <CustomTitleTab
                  segmentId={segment1?.id}
                  parentDivStyle={styles.newArrival}
                  leftContentStyle={styles.leftContent}
                  rightContentStyle={styles.rightContent}
                  imgStyle={styles.newArrIcon}
                  buttonStyle={styles.buttonStyle}
                  leftText={segment1?.segmentTitle}
                  leftTextStyle={styles.gamesCategoryTitleText}
                  rightTextStyle={styles.gamesCategorySeeAllText}
                  rightText="See All"
                  image={segment1?.segmentIcon}
                />
                <HorizentalGrid
                  segmentId={segment1?.id}
                  games={segment1?.games}
                  imageStyling={styles.newArrImage}
                  imageContainer={styles.imageContainer}
                  parentDivStyling={styles.gridParentStyling}
                  imageOverlay={styles.imageOverlay}
                  playIcon={playIcon}
                  playButtonContainer={styles.playButtonContainer}
                  loadingImageOverlay={styles.loadingImageOverlay}
                />
              </div>
            ))}
          </>
        );
      }
    } else {
      //render Other Categories
      if (activeCategory?.is_lobby) {
        if (games && games.length > 0) {
          return <Lobby games={games} categoryType={JSON.parse(activeCategory.name).en}/>;
        } else {
          return <Loader />;
        }
      } else {
        return (
          <UnLobby
            platformLoading={platformLoading}
            platforms={platforms}
            selectedPlatforms={selectedPlatforms}
            setSelectedPlatforms={setSelectedPlatforms}
            setMulitpleSelectedPlatforms={setMulitpleSelectedPlatforms}
            games={games}
            allAvailableGames={allAvailableGames}
            setGames={setGames}
            multipleSelectedPlatforms={multipleSelectedPlatforms}
            page={page}
            setPage={setPage}
            setSearchData={setSearchData}
            searchData={searchData}
            HandleSearchButton={HandleSearchButton}
            setUpdateAllPlatForm={setUpdateAllPlatForm}
          />
        );
      }
    }
  };

  return (
    <>
      {platforms?.length > 0 && !activeCat?.is_lobby && (
        <Platforms
          setUpdateAllPlatForm={setUpdateAllPlatForm}
          setSearchData={setSearchData}
          setGames={setGames}
          setPage={setPage}
          platforms={platforms}
          selectedPlatforms={selectedPlatforms}
          setSelectedPlatforms={setSelectedPlatforms}
          setMulitpleSelectedPlatforms={setMulitpleSelectedPlatforms}
        />
      )}

      <div className={styles.paddingWrapper}>
        {/* <div className={styles.platformStyling}> */}
        {renderContent(activeCat)}
        {showLoader && <Loader />}
        {/* </div> */}
      </div>
    </>
  );
};

export default Content;
