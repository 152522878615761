import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "1",
    lng: "1",
    resources: {
      1: {
        translation: {
          transaction: "Transaction",
          //login page
          login: "Login",
          signUp: "Sign up",
          userName: "Your username",
          userPassword: "Your password",
          fullName: "Enter your full name",
          phoneNumber: "Phone Number",
          agentId: "Agent Id",
          userNameReq: "user name is required",
          nameReq: "name is required",
          phoneReq: "Phone is required",
          Register: "Register",
          phonepwderror:
            "user name or password does not match. Please check again.",
          servererror:
            "An error occurred while connecting to the system. Please contact Customer Care",
          userNotExist:
            "Username or Password Incorrect. Please try again or contact Customer Care for support",
          accountInactive:
            "Your account is inactive. Please contact the customer support to activate your account.",
          passwordIncorrect:
            "Username or Password Incorrect. Please try again or contact Customer Care for support",
          userNameAlreadyExist: "Username Already Taken",
          depositNotAllowed:
            "Your deposit feature has been locked, please contact customer support for assistance.",
          internalServerError: "Internal Server Error. Please try again later.",
          noDataFound: "No Data Found !",

          //profile page
          networkerror: "Network Error",
          deposit: "DEPOSIT",
          withdrawmoney: "WITHDRAW ",

          //home page
          marque:
            "For the security of your account and funds, please do not disclose your account and password to others to avoid losses.",
          affiliateProgram: "Affiliate Program",
          support: "CSKH 24/7",
          account: "Account",
          errormessage: "Something went wrong, please contact Customer Service",
          depositamt: "Deposit Amount",
          errormessage2: "Something went wrong, please try again later.",
          copy: "Copy",
          dealercode: "Dealer code",
          // passwordError:"The password can't have special characters and must contain lowercase, numbers and the length between 6 and 16",
          passwordError:
            "The password must be 6-16 characters long and can include letters, numbers, and special characters.",
          pwderror: "Password must contain capital letters",
          pwderror1: "Password must have lowercase letters",
          pwderror2: "Password must have numbers (0~9)",
          pwdlength: "Be 10 characters or more in length",
          pwdrepeat: "Password Repeat",
          selectlang: "Select Language",
          en: "EN",
          vn: "VN",
          ch: "CH",
          currency: "Currency",
          selectcurrency: "Select Currency",
          pwdnomatch: "Password does not match",
          rejecterror:
            "Your account has not been activated or you have not made a transfer",
          mybankcard: "My bank card",
          //withdraw errors
          withdrawPoints: "Number of withdrawal points",
          minWithdraw: "Min",
          maxWithdraw: "Max",
          addPaymentMethod: "Add Payment Method",
          minamounterror: "Please choose over 150,000",
          pendingreqerror:
            "You have a pending request. You cannot create a new request while there is a pending request. Please request again later.",
          insufficientbalance: " INSUFFICIENT_BALANCE ",
          status: "Status",
          processing: "Processing",
          reason: "Reason",
          nameMinLengthError: "Name must be at least 3 characters long",
          nameMaxLengthError: "Name must not exceed 255 characters",
          nameValidationError:
            "The name field must be at least 5 characters.The name field must contain at least two words and should not contain numbers or special characters Eg: Alden Reyes",
          phoneerror: "Phone Already Taken",
          promotion: "Promotion",
          minWidthdrawAmmErrPart1: "Minimum withdrawal amount from",
          notEnoughBalanceErr:
            "You do not have enough balance to make this transaction",
          customerSupport: "Customer Support",
          withdrawFeatureLock:
            "Your withdrawal feature has been locked, please contact customer support for assistance.",
          logout: "Logout",
          bankErrMessage:
            "No bank information available. Please add a bank to continue.",
          withdrawStep1: "Balance",
          depositFirstErrMessage:
            "Your account has not been activated, please deposit first.",
          temporaryLockWithdraw:
            "Your account's withdrawal function is temporarily locked, please contact Customer Support.",
          notEligibleWithdraw:
            "Your are not eligible for withdrawal for not reaching turnover limit, please contact Customer Support.",
          advanceDeposit: "Advance Deposit Required",
          privacyPolicy: "Privacy Policy",
          userNameError1:
            "Username must be 8-12 characters long and contain only lowercase letters and numbers.",
          phoneError1: "Phone number must be 10 digits",
          defaultUserNameMessage:
            "Minimum 8, Maximum 12 Characters. No Special Characters or Space Allowed.",
          defaultPasswordMessage:
            "Minimum 6, Maximum 16 Characters and can include letters, numbers, and special characters.",
          defaultFullNameMessage:
            "Full name without any special characters. Eg: Alden Reyes",
          defaultPhoneMessage: "Enter 10 digit phone number",

          //deposit

          underMaintenance:
            "The System is Under Maintenance. Please try after some time or contact our",
          depositeAmount: "Amount",
          payVia: "Pay via",
          depositTitle: "Deposit",

          //Support

          contactTitle: "Contact",
          telegramSupportChannel: "Telegram support channel",

          //Accout
          eWalletManagement: "E-Wallet Management",

          //Transaction
          depositBank: "Deposit Bank",
          date: "Date",
          copied: "Copied!",
          time: "Time",

          //Affiliate
          affiliate1:
            "PLAYERS can earn bonus just by being SUBAGENT and invite more Players.",
        },
      },
      4: {
        translation: {
          MoneyFooter: "Giao dịch",
          passwordError:
            "Mật khẩu phải có độ dài từ 6 đến 16 ký tự và có thể bao gồm chữ cái, số và ký tự đặc biệt.",
          nameMinLengthError: "Tên phải có ít nhất 3 ký tự",
          nameMaxLengthError: "Tên không được vượt quá 255 ký tự",
          phoneerror: "Điện thoại đã được lấy",
          networkerror: "lỗi mạng",
          account: "Tài Khoản",
          status: "Trạng thái",
          minamounterror: "Vui lòng chọn trên 150,000",
          pendingreqerror:
            "Bạn có yêu cầu đang chờ xử lý. Bạn không thể tạo yêu cầu mới khi đang có yêu cầu chờ xử lý. Vui lòng yêu cầu lại sau.",
          insufficientbalance: "Số dư không đủ",
          Register: "Đăng ký",
          //error messages
          phonepwderror:
            "Tên người dùng hoặc mật khẩu không khớp. Vui lòng kiểm tra lại",
          servererror:
            "Đã xảy ra lỗi trong quá trình kết nối tới hệ thống. Vui lòng liên hệ Chăm sóc khách hàng",
          deposit: "Nạp Tiền",
          withdrawmoney: "Rút Tiền",
          transaction: "Giao Dịch",
          errormessage: "Đã xảy ra lỗi, vui lòng liên hệ Chăm sóc khách hàng",
          marque:
            "Để bảo mật tài khoản và tiền của bạn, vui lòng không tiết lộ tài khoản và mật khẩu của bạn cho người khác để tránh tổn thất.",
          support: "CSKH 24/7",
          reason: "Lý do",
          depositamt: "Số tiền nạp",
          copy: "sao chép",
          processing: "Đang xử lý",
          promotion: `Khuyến mãi`,
          minWidthdrawAmmErrPart1: `Số tiền rút tối thiểu từ`,
          notEnoughBalanceErr: `Bạn không có đủ số dư để thực hiện giao dịch này`,
          customerSupport: `Hỗ trợ khách hàng`,
          withdrawFeatureLock: `Tính năng rút tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ.`,
          logout: "Đăng xuất",
          bankErrMessage:
            "Chưa có thông tin ngân hàng. Vui lòng thêm ngân hàng để tiếp tục.",
          withdrawStep1: "Số dư",
          depositFirstErrMessage:
            "Tài khoản của bạn chưa được kích hoạt vui lòng gửi tiền trướ",
          temporaryLockWithdraw:
            "Tài khoản của bạn đang tạm khóa chức năng rút tiền, vui lòng liên hệ Chăm sóc khách hàng",
          advanceDeposit: "Yêu cầu đặt cọc trước",
          privacyPolicy: "Chính sách Riêng tư",
          userNameError1:
            "Tên người dùng phải dài từ 8-12 ký tự và chỉ bao gồm chữ thường và số.",
          phoneError1: "Số điện thoại phải có 10 chữ số",
          login: "đăng nhập",
          signUp: "đăng ký",
          userName: "tên người dùng của bạn",
          userPassword: "Mật khẩu của bạn",
          fullName: "Nhập tên đầy đủ của bạn",
          phoneNumber: "Số điện thoại",
          agentId: "ID đại lý",
          userNameReq: "tên người dùng là bắt buộc",
          nameReq: "Tên là bắt buộc",
          phoneReq: "Cần có điện thoại",
          userNotExist:
            "Tên hoặc mật khẩu không đúng. Vui lòng thử lại hoặc liên hệ bộ phận Chăm sóc khách hàng để được hỗ trợ",
          accountInactive:
            "Tài khoản của bạn không hoạt động. Vui lòng liên hệ bộ phận hỗ trợ khách hàng để kích hoạt tài khoản của bạn",
          passwordIncorrect:
            "Tên hoặc mật khẩu không đúng. Vui lòng thử lại hoặc liên hệ bộ phận Chăm sóc khách hàng để được hỗ trợ",
          userNameAlreadyExist: "Tên người dùng đã được sử dụng",
          depositNotAllowed:
            "Tính năng gửi tiền của bạn đã bị khóa, vui lòng liên hệ bộ phận hỗ trợ khách hàng để được hỗ trợ.",
          internalServerError: "Lỗi máy chủ nội bộ. Vui lòng thử lại sau.",
          noDataFound: "Không tìm thấy dữ liệu nào !",
          affiliateProgram: "Chương trình liên kết",
          errormessage2: "Đã xảy ra lỗi, vui lòng thử lại sau.",
          aboutUs: "Về chúng tôi",
          responsibleGambling: "Cờ bạc có trách nhiệm",
          termsCondition: "Các điều khoản và điều kiện",
          paymentMethods: "Phương thức thanh toán",
          followUs: "theo chúng tôi",
          responsibleGaming: "chơi game có trách nhiệm",
          footerLogoContent:
            "Lorem ipsum dolor sit amet, consetetur At vero eossadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore .",
          homeFooter: "Trang chủ",
          homeBonus: "Thưởng",
          homeChat: "trò chuyện",
          withdrawPoints: "Số điểm rút tiền",
          minWithdraw: "phút",
          maxWithdraw: "tối đa",
          addPaymentMethod: "Thêm phương thức thanh toán",
          nameValidationError:
            "Trường tên phải có ít nhất 5 ký tự. Trường tên phải chứa ít nhất hai từ và không được chứa số hoặc ký tự đặc biệt. Ví dụ: Alden Reyes",
          defaultUserNameMessage:
            "Tối thiểu 8, Tối đa 12 ký tự. Không có ký tự đặc biệt hoặc khoảng trống được phép.",
          defaultPasswordMessage:
            "Tối thiểu 6, tối đa 16 ký tự và có thể bao gồm chữ cái, số và ký tự đặc biệt.",
          defaultFullNameMessage:
            "Tên đầy đủ không có ký tự đặc biệt. Ví dụ: Alden Reyes",
          defaultPhoneMessage: "Nhập số điện thoại 10 chữ số",
          underMaintenance:
            "Hệ thống đang được bảo trì. Vui lòng thử sau một thời gian hoặc liên hệ với chúng tôi",
          depositeAmount: "Số lượng",
          payVia: "Thanh toán qua",
          depositTitle: "Tiền gửi",
          contactTitle: "Liên hệ",
          telegramSupportChannel: "Kênh hỗ trợ Telegram",
          eWalletManagement: "Quản lý ví điện tử",
          depositBank: "Ngân hàng tiền gửi",
          date: "ngày",
          copied: "sao chép !",
          time: "thời gian",
          affiliate1:
            "NGƯỜI CHƠI có thể kiếm được tiền thưởng chỉ bằng cách ĐĂNG NHẬP và mời thêm Người chơi.",
        },
      },
      2: {
        translation: {
          MoneyFooter: "लेन-देन",
          nameMinLengthError: "नाम कम से कम 3 अक्षर का होना चाहिए",
          nameMaxLengthError: "नाम 255 अक्षरों से अधिक नहीं होना चाहिए",
          phoneerror: "फ़ोन पहले ही ले लिया गया है",
          networkerror: "नेटवर्क त्रुटि",
          status: "दर्जा",
          minamounterror: "कृपया 150,000 से अधिक चुनें ",
          pendingreqerror:
            "आपका एक अनुरोध लंबित है। लंबित अनुरोध होने पर आप नया अनुरोध नहीं बना सकते हैं। कृपया बाद में पुनः अनुरोध करें।",
          insufficientbalance: "अपर्याप्त शेषराशि ",
          Register: "पंजीकरण करवाना",

          //error messages
          phonepwderror:
            "उपयोगकर्ता नाम या पासवर्ड मेल नहीं खाता है। कृपया फिर से जांचें।",
          servererror:
            "सिस्टम से कनेक्ट करते समय एक त्रुटि हुई। कृपया कस्टमर केयर से संपर्क करें",

          deposit: "फिर से दाम लगाना",
          withdrawmoney: "पैसे निकाले",
          transaction: "लेन-देन",
          support: "CSKH 24/7",
          errormessage: "कुछ गलत हुआ, कृपया ग्राहक सेवा से संपर्क करें",
          depositamt: "जमा राशि",
          copy: "प्रतिलिपि",
          processing: "प्रसंस्करण",
          reason: "कारण",
          promotion: "प्रोमोशन",
          minWidthdrawAmmErrPart1: "न्यूनतम निकासी राशि",
          notEnoughBalanceErr:
            "इस लेन-देन को पूरा करने के लिए आपके पास पर्याप्त बैलेंस नहीं है",
          customerSupport: "ग्राहक सहायता",
          withdrawFeatureLock:
            "आपकी निकासी सुविधा बंद कर दी गई है, कृपया सहायता के लिए ग्राहक सेवा से संपर्क करें।",
          account: "खाता",
          logout: "लॉगआउट",
          bankErrMessage:
            "बैंक की जानकारी उपलब्ध नहीं है। कृपया जारी रखने के लिए बैंक जोड़ें।",
          withdrawStep1: "शेष राशि",
          depositFirstErrMessage:
            "आपका खाता सक्रिय नहीं हुआ है, कृपया पहले धनराशि जमा करें।",
          temporaryLockWithdraw:
            "आपके खाते की निकासी सुविधा अस्थायी रूप से बंद है, कृपया ग्राहक सहायता से संपर्क करें।",
          advanceDeposit: "अग्रिम जमा आवश्यक है",
          privacyPolicy: "गोपनीयता नीति",
          userNameError1:
            "उपयोगकर्ता नाम 8-12 वर्ण लंबा होना चाहिए और केवल लोअरकेस अक्षर और संख्याएं ही शामिल होनी चाहिए।",
          phoneError1: "फ़ोन नंबर 10 अंकों का होना चाहिए",
        },
      },
    },
  });

export default i18n;
