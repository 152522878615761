import React, { useEffect, useState } from "react";
import styles from "./Deposit.module.css";
import { Button, Col, Image, Row, Typography, Card } from "antd";
import Gcash from "../../Assets/payment icons/G.png";
import GcashText from "../../Assets/payment icons/GCash.png";
import InputField from "../HelperComponents/InputField";
import { MdCurrencyRuble, MdHorizontalRule } from "react-icons/md";
import selectedIcon from "../../Assets/payment icons/Selected Method.png";
import {
  APIMakeDepositRequest,
  depositAllowed,
  paymentMethodsAndCategories,
} from "../../Api/TransactionApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useLogout from "../Layout/Header/useLogout";
import PopupErrorModal from "../HelperComponents/PopupErrorModal";
import Loader from "../Loader/Loader";
import {
  CommaSeperator,
  handleKeyPress,
  toFixedFunc,
} from "../Helper/HelperFunction";
import LoginPopupModal from "../Login/LoginPopupModal";
import GCashPage from "./GCashPage";
import NoDataFoundIcon from "../../Assets/No Data Icon.png";
import NoDataComp from "../NoData/NoDataComp";
import ErrorsHandlingComp from "../Helper/Validator/ErrorsHandlingComp";
import { currencyHelper } from "../Helper/currencyHelper";

import PaymentMethod from "../DepositComponents/PaymentMethod";
import PaymentCategories from "../DepositComponents/PaymentCategories";
import DepositInput from "../DepositComponents/DepositInput";
import ErrorPopup from "./errorPopup/ErrorPopup";

const Deposit = () => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const logout = useLogout();
  const [amount, setAmount] = useState("");
  const [depositMinAmount, setDepositMinAmount] = useState(500);
  const [depositMaxAmount, setDepositMaxAmount] = useState(50000);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [methodLoading, setMethodLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [paymentCategories, setPaymentCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [isDeposit, setIsDeposit] = useState(true);
  const [isDepositLoader, setIsDepositLoader] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popupError, setPopupError] = useState("");
  const [gCashData, setgCashData] = useState(null);
  const [sugestedAmount, setSuggestedAmount] = useState([]);
  const [errors, setErrors] = useState({});
  const [bankCodes, setBankCodes] = useState([]);
  const [bankChannels, setBankChannels] = useState([]);
  const [noCategory, setNoCategory] = useState(false);

  useEffect(() => {
    if (paymentMethods) {
      // Use an object to store unique bank codes based on ID
      const uniqueBankCodes = {};

      paymentMethods.forEach((item) => {
        // Check if item.bank_code.id is not already in the object
        if (!uniqueBankCodes[item?.bank_code?.id]) {
          // Save the entire bank_code object with its ID as key
          uniqueBankCodes[item?.bank_code?.id] = item?.bank_code;
        }
      });

      // Convert object values back to an array and update the state
      setBankCodes(Object.values(uniqueBankCodes));
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (paymentMethods && selectedBank) {
      // Filter payment methods to get only those that match the condition
      const matchedChannels = paymentMethods.filter(
        (item) => item?.bank_code_id === selectedBank.id
      );

      // Save the matched channels to state
      setBankChannels(matchedChannels);
    }
  }, [paymentMethods, selectedBank]);

  useEffect(() => {
    const newSuggestedAmount = [];
    for (let i = 2; i <= 5; i++) {
      const value = depositMinAmount * i;
      newSuggestedAmount.push({
        id: i,
        label: value.toString(),
        value: value.toString(),
      });
    }
    setSuggestedAmount(newSuggestedAmount);
  }, [depositMinAmount]);

  const handleSelectCategory = (e, category) => {
    // setSelectedCategory(category);
    setSelectedMethod(category);
  };

  const handleSelectMethod = (e, method) => {
    // setSelectedMethod(method);
    setSelectedBank(method);
  };
  const handleSelectAmount = (e, btn) => {
    setAmount(btn.value);
  };

  const handleInputChange = (name, value) => {
    setAmount(value);
  };
  const getAllCategoriesFunc = async () => {
    setCategoriesLoading(true);
    setMethodLoading(true);
    const res = await paymentMethodsAndCategories();

    if (res?.status === 200) {
      if (res?.data && !res?.data?.length) {
        setNoCategory(true);
      }
      if (res?.data?.length && res.data) {
        setPaymentCategories(res.data);
        setSelectedCategory(res.data[0]);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          noCategory: (() => (
            <Typography.Text className={styles.enterAmountText}>
              The System is Under Maintenance. Please try after some time or
              contact our
              <a href="/support"> customer support</a>.
            </Typography.Text>
          ))(),
        }));
        setMethodLoading(false);
      }
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    }
    setCategoriesLoading(false);
  };

  useEffect(() => {
    if (selectedCategory) {
      setPaymentMethods(selectedCategory?.payment_methods);
      setMethodLoading(false);
      setSelectedBank(selectedCategory?.payment_methods[0]?.bank_code);
      // setSelectedMethod(selectedCategory?.payment_methods[0]);
      // setSelectedMethod(bankChannels[0]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (bankChannels) {
      setSelectedMethod(bankChannels[0]);
    }
  }, [selectedCategory, bankChannels]);

  useEffect(() => {
    if (selectedMethod) {
      selectedMethod?.min_deposit_amount &&
        setDepositMinAmount(selectedMethod?.min_deposit_amount);
      selectedMethod?.max_deposit_amount &&
        setDepositMaxAmount(selectedMethod?.max_deposit_amount);
    }
  }, [selectedMethod]);

  useEffect(() => {
    getAllCategoriesFunc();
  }, []);

  // check deposit block API
  useEffect(() => {
    checkDiposetAllow();
  }, []);

  // deposit blocked API
  const checkDiposetAllow = async () => {
    setIsDepositLoader(true);
    const res = await depositAllowed();
    if (res?.status === 200) {
      if (res?.data?.message === "PLAYER_NOT_ALLOWED_TO_DEPOSIT") {
        setPopupError(
          "Your deposit feature has been locked, please contact customer support for assistance."
        );
      } else {
        setIsDeposit(res.data.status);
      }
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else if (res?.response?.status === 401) {
      logout();
    }
    setIsDepositLoader(false);
  };
  // set block deposit error
  useEffect(() => {
    if (!isDeposit) {
      setErrorModal(true);
      setErrorMessage(
        "Tính năng nạp tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ."
      );
    }
  }, [isDeposit]);

  const depositFunc = async (e) => {
    if (!checkValidation()) {
      setLoading(true);
      const deposit = await APIMakeDepositRequest(selectedMethod?.id, amount);
      if (deposit?.response?.data?.message === "PENDING_TRANSACTION") {
        setPopupError("pendingreqerror");
      } else if (deposit?.response?.data?.message === "SUCCESS") {
        navigate("/");
      } else if (deposit?.response?.data?.message === "FAILED") {
        setPopupError("Something went wrong, please try again later.");
      } else if (deposit?.response?.data?.message === "PENDING") {
        navigate("/account/transactions");
      } else if (deposit?.response?.data?.message === "INVALID_AMOUNT") {
        setPopupError(
          `Enter a valid amount between ${CommaSeperator(
            toFixedFunc(depositMinAmount)
          )} - ${CommaSeperator(
            toFixedFunc(depositMaxAmount)
          )} to make deposit request.`
        );
      } else if (deposit?.data?.message === "WAITING_FOR_PAYMENT") {
        setgCashData(deposit?.data?.link);
      } else if (deposit?.response?.status === 403) {
        if (
          deposit?.response?.data?.message === "PLAYER_NOT_ALLOWED_TO_DEPOSIT"
        ) {
          setPopupError(
            "Your deposit feature has been locked, please contact customer support for assistance."
          );
        }
      } else if (deposit?.response?.status === 400) {
        if (deposit?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        }
      } else if (deposit?.response?.data?.message === "TRANSACTION_FAILED") {
        setPopupError(
          "Deposit Request Failed. Please try again with a different payment option or please try after some time."
        );
      } else if (deposit?.response?.status === 401) {
        logout();
      } else if (deposit?.response?.status === 422) {
        if (deposit?.response?.data?.message?.payment_method_id) {
          setPopupError(deposit?.response?.data?.message?.payment_method_id[0]);
        } else if (deposit?.response?.data?.message?.amount) {
          setPopupError(deposit?.response?.data?.message?.amount[0]);
        } else {
          setPopupError(
            "Your deposit feature has been locked, please contact customer support for assistance."
          );
        }
      } else if (deposit?.response?.status === 500) {
        setPopupError("Internal Server Error. Please try again later.");
      } else if (deposit === "WAIT_PLEASE") {
        setErrorModal(true);
        // setRejectDeposit(true);
        setErrorMessage("pendingreqerror");
      } else {
        if (deposit?.data?.status === 200) {
          navigate("/account/transactions");
        } else {
          setPopupError(
            "We're sorry, but the selected payment method is under maintenance. Kindly choose another method."
          );
        }
        // return deposit;
      }

      setLoading(false);
    }
    return null;
  };

  const checkValidation = () => {
    if (
      !Number(amount) ||
      Number(amount) < Number(depositMinAmount) ||
      Number(amount) > Number(depositMaxAmount) ||
      !selectedMethod ||
      isDepositLoader ||
      amount.includes(".")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {noCategory && (
        <ErrorPopup
          show={noCategory}
          hideModal={() => {
            setNoCategory(false);
          }}
          message="Currently, there is no payment method available. Please try again later."
        />
      )}
      {gCashData ? (
        <GCashPage data={gCashData} />
      ) : (
        <div className={`${styles.container} deposit-container`}>
          <PaymentMethod
            methodLoading={methodLoading}
            paymentMethods={paymentMethods}
            selectedBank={selectedBank}
            // selectedMethod={selectedMethod}
            bankCodes={bankCodes}
            handleSelectMethod={handleSelectMethod}
          />

          <PaymentCategories
            methodLoading={methodLoading}
            paymentMethods={paymentMethods}
            selectedMethod={selectedMethod}
            selectedBank={selectedBank}
            bankChannels={bankChannels}
            // paymentChannels={paymentChannels}
            handleSelectMethod={handleSelectCategory}
          />

          <DepositInput
            depositMinAmount={depositMinAmount}
            depositMaxAmount={depositMaxAmount}
            amount={amount}
            userInfoReduxData={userInfoReduxData}
            handleInputChange={handleInputChange}
            sugestedAmount={sugestedAmount}
            handleSelectAmount={handleSelectAmount}
          />

          <div
            style={{
              display: "flex",
              width: "100%",
              height: "auto",
            }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Button
                onClick={(e) => depositFunc(e)}
                className={`${styles.depositBtn}
            ${checkValidation() ? styles.disabled : styles.activeButton}`}
              >
                Deposit
              </Button>
            )}{" "}
          </div>

          {errorModal && (
            <PopupErrorModal
              show={errorModal}
              setErrorMessage={setErrorMessage}
              setErrorModal={setErrorModal}
              message={errorMessage}
              hideModal={() => {
                setErrorModal(false);
                // setRejectDeposit(false);
                setErrorMessage(null);
              }}
              selectedBankId={selectedBank?.id}
              // rejectDeposit={rejectDeposit}
              amount={amount}
              modalClose={
                errorMessage ===
                "Bạn có yêu cầu đang chờ xử lý. Bạn không thể tạo yêu cầu mới khi đang có yêu cầu chờ xử lý. Vui lòng yêu cầu lại sau."
                  ? false
                  : true
              }
              blockDeposit={!isDeposit}
            />
          )}
          {popupError && (
            <LoginPopupModal
              message={t(popupError)}
              show={popupError ? true : false}
              errorIcon={popupError ? true : false}
              hideModal={() => {
                setPopupError("");
                if (
                  popupError ===
                  "Your deposit feature has been locked, please contact customer support for assistance."
                ) {
                  navigate("/support");
                } else if (popupError === "pendingreqerror") {
                  navigate("/account/transactions", {
                    state: { active: "deposit" },
                  });
                } else {
                  navigate("/account/transactions", {
                    state: { active: "deposit" },
                  });
                }
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Deposit;
