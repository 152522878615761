import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import { Image } from "antd";
import CustomButton from "../HelperComponents/CustomButton";
import InputField from "../HelperComponents/InputField";
import Paragraph from "antd/es/typography/Paragraph";
import UsernameIcon from "../../Assets/login-register/Username.png";
import PasswordIcon from "../../Assets/login-register/Password.png";
import Eye from "../../Assets/login-register/Eye.png";
import EyeOpen from "../../Assets/login-register/Eye Open.png";
import Register from "../Register/Register";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Logo from "../../Assets/BESTWAY9 Logo.png";
import Logo from "../../Assets/bw9Logo.png";
import Support from "../Support/Support";
import { APILoginUser } from "../../Api/Apis";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { setUser } from "../../Redux/Slice/UserSlice";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import LoginPopupModal from "./LoginPopupModal";
import { DeviceAndBrowserInfo } from "../shared-components/DeviceAndBrowserInfo/DeviceAndBrowserInfo";

const Login = () => {
  const urlLocation = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toggleBtn = document.querySelector(".toggleBtns");
  const [showPassword, setShowPassword] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [popupError, setPopupError] = useState("");
  const dispatch = useDispatch();
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });
  const [toggleButton, setToggleButton] = useState({
    login: true,
    signup: false,
  });

  useEffect(() => {
    if (urlLocation.pathname === "/register") {
      setToggleButton({
        login: false,
        signup: true,
      });
    }
    if (urlLocation.pathname === "/login") {
      setToggleButton({
        login: true,
        signup: false,
      });
    }
  }, [urlLocation]);

  const handleLoginInputChange = (name, value) => {
    setLoginFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onFinish = async () => {
    // e.preventDefault();
    if (loginFormData?.email && loginFormData?.password) {
      setLoading(true);
      const info = DeviceAndBrowserInfo();
      const res = await APILoginUser(
        loginFormData?.email,
        loginFormData?.password,
        info.deviceType,
        info.browserName
      );
      if (res?.status === 200) {
        if (res?.data?.token) {
          localStorage.setItem("auth_token", res?.data?.token);
          localStorage.setItem("currency_id",res?.data?.data?.currency_id);
          dispatch(setUser(res?.data?.token));
          navigate("/");
        }
      } else {
        if (res.data.message === "USER_DOES_NOT_EXIST") {
          setErrorMessage(
            "Username or Password Incorrect. Please try again or contact Customer Care for support"
          );
        } else if (res.data.message === "ACCOUNT_INACTIVE") {
          setPopupError(
            "Your account is inactive. Please contact the customer support to activate your account."
          );
        } else if (res.data.message === "LOGIN_ATTEMPTS_EXCEEDED") {
          setErrorMessage(
            "Account Login Disabled. Please wait a few minutes and try again or contact customer support. Error 10004"
          );
        } else if (res.data.message === "USERNAME_OR_PASSWORD_INCORRECT") {
          setErrorMessage(
            "Username or Password Incorrect. Please try again or contact Customer Care for support"
          );
        } else if (res.data.message === "PASSWORD_INCORRECT") {
          setErrorMessage(
            "Username or Password Incorrect. Please try again or contact Customer Care for support"
          );
        } else if (res.status === 500) {
          setErrorMessage(
            "An error occurred while connecting to the system. Please contact Customer Care"
          );
          setLoading(false);
        } else {
          setErrorMessage(
            "user name or password does not match. Please check again."
          );
        }
      }

      setLoading(false);
    }
  };
  const inputBox = {
    maxWidth: "521px",
    width: "100%",
    height: "50px",
    background: "#000326 0% 0% no-repeat padding-box",
    border: "1px solid #3C3562",
    borderRadius: "7px",
    opacity: 1,
    textAlign: "left",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: "Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
  };

  return (
    <div className={styles.container}>
      {!showContactUs ? (
        <>
          <div className={styles.logoContainer}>
            <Image
              src={Logo}
              className={styles.loginpageLogo}
              preview={false}
            />
          </div>

          <div className={styles.toggleBtnContainer}>
            <div className={styles.togglebox}>
              <div
                className={`${styles.toggleBtn} ${
                  toggleButton.login ? styles.toggleLeft : styles.toggleRight
                }`}
              ></div>
              <CustomButton
                className="no-hover"
                type={`${styles.toggleLoginBtn} ${
                  toggleButton.login
                    ? styles.toggleLoginBtnFontColorActive
                    : styles.toggleLoginBtnFontColorInactive
                }`}
                text="Login"
                onClick={() => {
                  setToggleButton({
                    login: true,
                    signup: false,
                  });
                  navigate("/login");
                }}
              />
              <CustomButton
                className="no-hover"
                type={`${styles.toggleLoginBtn} ${
                  toggleButton.signup
                    ? styles.toggleLoginBtnFontColorActive
                    : styles.toggleLoginBtnFontColorInactive
                }`}
                text="Sign up"
                onClick={() => {
                  setToggleButton({
                    login: false,
                    signup: true,
                  });
                  navigate("/register");
                }}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div
              className={`${styles.loginContainer} ${
                urlLocation.pathname === "/register"
                  ? styles.addMarginToLogin
                  : ""
              } login-container`}
            >
              <Form
                onFinish={onFinish}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "auto",
                }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "user name is required",
                    },
                  ]}
                >
                  <InputField
                    placeHolder="YOUR USERNAME"
                    extraStyle={inputBox}
                    type="text"
                    name="email"
                    prefix={
                      <div style={{ width: 20, height: 20 }}>
                        <Image
                          src={UsernameIcon}
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 1,
                            marginRight: 5,
                            objectFit: "contain",
                          }}
                          preview={false}
                        />
                      </div>
                    }
                    value={loginFormData.email}
                    onChange={handleLoginInputChange}
                    required={true}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                  ]}
                >
                  <InputField
                    placeHolder="YOUR PASSWORD"
                    extraStyle={inputBox}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    required={true}
                    prefix={
                      <div style={{ width: 20, height: 20 }}>
                        <Image
                          src={PasswordIcon}
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 1,
                            marginRight: "5px",
                            objectFit: "contain",
                          }}
                          preview={false}
                        />
                      </div>
                    }
                    suffix={
                      showPassword ? (
                        <Image
                          src={EyeOpen}
                          style={{
                            width: 25,
                            height: 25,
                            opacity: 1,
                            objectFit: "contain",
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                          preview={false}
                        />
                      ) : (
                        <Image
                          src={Eye}
                          style={{
                            width: 25,
                            height: 25,
                            opacity: 1,
                            objectFit: "contain",
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                          preview={false}
                        />
                      )
                    }
                    value={loginFormData.password}
                    onChange={handleLoginInputChange}
                  />
                  <Link to="/support">
                    <div className={`${styles.forgetPasswordText} `}>
                      Forgot Password?
                    </div>
                  </Link>
                </Form.Item>

                {errorMessage && (
                  <span className={styles.error} style={{ color: "red" }}>
                    {errorMessage}
                  </span>
                )}

                {loading ? (
                  <Loader />
                ) : (
                  <Form.Item>
                    <CustomButton
                      text={t("login")}
                      type={styles.loginBtn}
                      // onClick={handleSubmit}
                      htmlType="submit"
                    />
                  </Form.Item>
                )}
                {/* <Paragraph className={styles.loginParagrap}>
                  <p>By accessing you confirm the you are at least 18 years old and
                    agree to the{" "}
                    <Link style={{
                      textAlign: "right",
                      textDecoration: "underline",
                      font: "normal normal medium 16px/19px Roboto",
                      letterSpacing: "0px",
                      color: "#BE8AFF",
                      opacity: 1,
                    }}>Terms and service</Link></p>
                </Paragraph> */}
              </Form>
            </div>
            <Register />
          </div>
        </>
      ) : (
        <Support />
      )}
      {popupError && (
        <LoginPopupModal
          message={popupError}
          show={popupError ? true : false}
          hideModal={() => {
            setPopupError("");
          }}
        />
      )}
    </div>
  );
};

export default Login;
