import React, { useEffect, useState } from "react";
import styles from "./UnLobby.module.css";
import HomePageAllGames from "../../Home/HomePageAllGames";
import Filter from "./Filter";
import Loader from "../../HelperComponents/Loader";
const UnLobby = (props) => {
  const {
    platforms,
    selectedPlatforms,
    setSelectedPlatforms,
    setMulitpleSelectedPlatforms,
    games,
    allAvailableGames,
    setGames,
    multipleSelectedPlatforms,
    page,
    setPage,
    setSearchData,
    searchData,
    HandleSearchButton,
    platformLoading,
    setUpdateAllPlatForm,
  } = props;

  return (
    <>
      <div className={styles.unlobbyWrapper}>
        {platforms?.length > 0 && (
          <>
            <Filter
              games={games}
              setGames={setGames}
              allAvailableGames={allAvailableGames}
              multipleSelectedPlatforms={multipleSelectedPlatforms}
              setSelectedPlatforms={setSelectedPlatforms}
              platforms={platforms}
              setMulitpleSelectedPlatforms={setMulitpleSelectedPlatforms}
              selectedPlatforms={selectedPlatforms}
              setSearchData={setSearchData}
              searchData={searchData}
              HandleSearchButton={HandleSearchButton}
              setUpdateAllPlatForm={setUpdateAllPlatForm}
            />
          </>
        )}
        {platformLoading || platforms === 0 ? (
          <div style={{marginTop: "10%"}}>
            <Loader />
          </div>
        ) : (
          <HomePageAllGames
            games={games}
            page={page}
            setPage={setPage}
            allAvailableGames={allAvailableGames}
          />
        )}
      </div>
    </>
  );
};

export default UnLobby;
