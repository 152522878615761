import React, { useEffect, useState } from "react";
import styles from "./AddPaymentMethod.module.css";
import { Input, Select, Typography } from "antd";
// import Ewallet from '../../Assets/payment icons/E-Wallet.png';
// import BankIcon from '../../Assets/payment icons/Bank.png';
import seletedIcon from "../../Assets/payment icons/Selected Method.png";
import lockIcon from "../../Assets/Lock.png";
import CustomButton from "../HelperComponents/CustomButton";
import useLogout from "../Layout/Header/useLogout";
import {
  APIMakePaymentMethod,
  paymentCategoriesApi,
} from "../../Api/TransactionApi";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import ErrorsHandlingComp from "../Helper/Validator/ErrorsHandlingComp";
import NoDataComp from "../NoData/NoDataComp";

const AddPaymentMethod = () => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  // const [selectCategory, setSelectCategory] = useState('');
  const [formData, setFormData] = useState({
    selectWallet: "Select bank code",
    accountNumber: "",
  });
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [paymentCategories, setPaymentCategories] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const logout = useLogout();
  // const categoryData = [
  //     { id: 'ewallet', label: 'E-Wallet', icon: Ewallet },
  //     { id: 'bank', label: 'Bank', icon: BankIcon }
  // ]

  const handleSelectData = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectWallet: value,
    }));
  };

  const handleInputData = (event) => {
    const { value } = event.target;
    const onlyNumbers = /^[0-9]*$/; // Allow empty string to clear input

    // Check if the value consists only of numbers (or is empty to allow deletion)
    if (onlyNumbers.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        accountNumber: value, // Update only if the input is numbers
      }));

      // Check the length and set or clear errors accordingly
      if (value.length < 3) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          account_number: "Minimum 3 digits required",
        }));
      } else {
        // Clear the error for account_number if it exists
        setErrors((prevErrors) => ({
          ...prevErrors,
          account_number: "", // Clear the specific error message
        }));
      }
    } else {
      // Set error for non-numeric values
      setErrors((prevErrors) => ({
        ...prevErrors,
        account_number: "Enter Only Numbers",
      }));
    }
  };
  useEffect(() => {
    if (!formData.accountNumber.length) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        account_number: "", // Clear the specific error message
      }));
    }
  }, [formData.accountNumber]);

  const getPaymentCategories = async () => {
    setCategoriesLoading(true);
    // setMethodLoading(true)
    const res = await paymentCategoriesApi();
    if (res?.status === 200) {
      if (res.data.length === 0 || !res.data) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          noCategory: (() => (
            <Typography.Text className={styles.enterAmountText}>
              The System is Under Maintenance. Please try after some time or
              contact our
              <a href="/support"> customer support</a>.
            </Typography.Text>
          ))(),
        }));
      } else {
        setPaymentCategories(res.data);
        setSelectedCategory(res.data[0]);
      }
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else if (res?.response?.status === 401) {
      logout();
    }
    setCategoriesLoading(false);
  };
  useEffect(() => {
    getPaymentCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      // getPaymentMethodsFunc(selectedCategory)
      const data = selectedCategory?.bank_codes.map((item) => ({
        value: item?.id,
        label: item?.public_name?.en_public_name,
      }));
      data.unshift({
        value: "Select bank code",
        label: "Select bank code",
      });
      setPaymentMethods(data);
    }
  }, [selectedCategory]);

  const submitNewWallet = async () => {
    if (
      formData?.selectWallet &&
      formData?.selectWallet !== "Select bank code" &&
      formData?.accountNumber
    ) {
      setLoading(true);
      const res = await APIMakePaymentMethod(
        selectedCategory?.id,
        formData?.selectWallet,
        formData?.accountNumber
      );
      if (res.status === 200) {
        navigate("/withdraw");
      } else if (res?.response?.status === 400) {
        if (res?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        }
      } else if (res?.response?.status === 401) {
        logout();
      } else {
        if (res?.response?.status === 422) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            account_number:
              res?.response?.data?.message === "USER_ACCOUNT_ALREADY_EXISTS"
                ? `User account already exists!`
                : `Something went wrong!`,
          }));
          // setErrors(res?.response?.data?.message);
        }
      }

      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {categoriesLoading ? (
        <Loader />
      ) : !paymentCategories?.length ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <NoDataComp />
          {
            <div style={{ display: "flex", margin: "15px 0" }}>
              <ErrorsHandlingComp errors={errors} name="noCategory" />
            </div>
          }
        </div>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography.Text className={styles.selectCatText}>
              Select Category
            </Typography.Text>
          </div>
          <div className={styles.paymentModeContainer}>
            {categoriesLoading ? (
              <Loader />
            ) : (
              paymentCategories?.map((category) => {
                return (
                  <div
                    className={styles.categoryBox}
                    key={Number(category?.id)}
                    style={{
                      border:
                        Number(selectedCategory?.id) === Number(category?.id)
                          ? "2px solid #FFFFFF"
                          : "",
                    }}
                    onClick={() => {
                      setSelectedCategory(category);
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        selectWallet: "Select bank code",
                      }));
                      //   setPaymentMethods(null);
                    }}
                  >
                    {/* Number(category?.id) === 1 ? Ewallet : BankIcon */}
                    <div style={{ display: "flex", width: 34, height: 35 }}>
                      <img
                        src={category?.full_image?.en_icon}
                        className="imgStyle"
                        style={{ background: "transparent" }}
                        alt="category icon"
                      />
                    </div>
                    <Typography.Text className={styles.categoryBoxText}>
                      {category?.public_name?.en_public_name}
                    </Typography.Text>
                    {Number(selectedCategory?.id) === Number(category?.id) ? (
                      <img
                        src={seletedIcon}
                        className={styles.selectedIcon}
                        alt="selectedICon"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            )}
          </div>
          {selectedCategory && (
            <>
              <div className={styles.inputContainer}>
                <div style={{ width: "100%" }} className="add-payment-methods">
                  <Select
                    showSearch
                    onChange={handleSelectData}
                    style={{ width: "100%" }}
                    placeholder="Select bank code"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    options={paymentMethods}
                    value={formData?.selectWallet}
                  />
                </div>
                <div className={styles.accountHolderField}>
                  <Typography.Text className={styles.labelTextStyling}>
                    {userInfoReduxData?.name || ""}
                  </Typography.Text>
                  <div
                    style={{
                      display: "flex",
                      width: "20px",
                      height: "20px",
                      opacity: 1,
                    }}
                  >
                    <img src={lockIcon} className="imgStyle" alt="lockIcon" />
                  </div>
                </div>
                <div
                  style={{ display: "flex", width: "100%", height: "50px" }}
                  className="add-payment-phone"
                >
                  <Input
                    placeholder="Enter account number"
                    onChange={handleInputData}
                    required
                    value={formData?.accountNumber}
                  />
                </div>
                <ErrorsHandlingComp errors={errors} name="account_number" />
              </div>

              <div className={styles.buttonContainer}>
                <div style={{ display: "flex", width: "100%", gap: 15 }}>
                  {loading ? (
                    <Loader />
                  ) : (
                    <CustomButton
                      className="no-hover"
                      type={`${styles.addPayConfirmBtn} ${
                        !formData.selectWallet ||
                        formData.selectWallet === "Select bank code" ||
                        !formData.accountNumber
                          ? styles.addPayDisabled
                          : styles.addPayConfirmBg
                      }`}
                      text="Confirm"
                      onClick={submitNewWallet}
                      disabled={
                        formData.selectWallet === "Select bank code" ||
                        !formData.selectWallet ||
                        !formData.accountNumber
                      }
                    />
                  )}
                  <CustomButton
                    className="no-hover"
                    type={styles.addCancelPayBtn}
                    text="Cancel"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AddPaymentMethod;
