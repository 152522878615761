import React from "react";
import styles from "./WithdrawInput.module.css";
import { Flex, Input, Tooltip, Typography, Space } from "antd";
import InputField from "../HelperComponents/InputField";
import { currencyHelper } from "../Helper/currencyHelper";
import { CommaSeperator } from "../Helper/HelperFunction";

const WithdrawInput = (props) => {
  const {
    inputBox,
    amount,
    userInfoReduxData,
    withdrawamountReduxData,
    handleInputChange,
    handleKeyPress,
    withdrawMinAmount,
    withdrawMaxAmount,
  } = props;
  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        style={{
          width: "100%",
        }}
      >
        <div className={styles.cardWrapper}>
          <div className={styles.stepHolder}>
            <Typography.Text
              className={styles.stepText}
            >
              Step 1: Input number of withdrawal points
            </Typography.Text>
          </div>
          <div className={styles.withdrawInputWrapper}>
            <div style={{ display: "flex" }}>
              <Typography.Text className={styles.inputTitle}>
                Number of withdrawal points
              </Typography.Text>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <InputField
                placeHolder="0.00"
                extraStyle={inputBox}
                type="text"
                name="withdraw"
                prefix={
                  <Typography.Text
                    style={{
                      fontSize: "24px",
                      opacity: 1,
                      color: amount.length ? "#FFFFFF" : "#574F85",
                      marginRight: "10px",
                    }}
                  >
                    {currencyHelper(userInfoReduxData?.currency)}
                  </Typography.Text>
                }
                value={amount || withdrawamountReduxData}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            </div>

            <div className={styles.minMaxMainContainer}>
              <Typography.Text className={styles.minMaxTextStyle}>
                Min:
                <span style={{ color: "#FFFFFF" }}>
                  {CommaSeperator(withdrawMinAmount)}
                </span>
              </Typography.Text>
              <Typography.Text className={styles.minMaxTextStyle}>
                Max:{" "}
                <span style={{ color: "#FFFFFF" }}>
                  {CommaSeperator(withdrawMaxAmount)}
                </span>
              </Typography.Text>
            </div>
          </div>
        </div>
      </Space>
    </>
  );
};

export default WithdrawInput;
