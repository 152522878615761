export const CommaSeperator = (val) => {
    return Number(val)?.toLocaleString();
};

export const toFixedFunc = (value, digits=2) => {
    const numericValue = Number(value);
    const fixedValue = numericValue.toFixed(digits);

    // Check if the decimal part is all zeros
    const decimalPart = fixedValue.split(".")[1];
    if (decimalPart && decimalPart.match(/^0+$/)) {
        return fixedValue.split(".")[0]; // Return only the integer part
    }

    return fixedValue; // Return the full formatted number
};

export const checkUserNameValidation = (event) => {
    if (event.key === ' ') {
        event.preventDefault(); // Prevent space
    }
}
export function handleKeyPress(event) {
    // Regular expression to match numbers
    const regex = /^[0-9]+$/;
    if (!regex.test(event.key) && event.key !== "Backspace" && event.key !== "Tab") {
        // Prevent default if it's not a number, backspace, or tab
        event.preventDefault();
    }
}