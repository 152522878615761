import React, { useEffect, useState } from "react";
import styles from "./PaymentMethod.module.css";
import Loader from "../Loader/Loader";
import selectedIcon from "../../Assets/payment icons/Selected Method.png";
import NoDataComp from "../NoData/NoDataComp";
import { Typography, Card, Badge, Space } from "antd";

const PaymentMethod = (props) => {
  const {
    methodLoading,
    paymentMethods,
    // selectedMethod,
    selectedBank,
    handleSelectMethod,
    bankCodes,
  } = props;

  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        style={{
          width: "100%",
        }}
      >
        {/* <Badge.Ribbon text="Step 1 Choose Payment Method" color="#711ADE"> */}
        <div className={styles.cardWrapper}>
          <div className={styles.stepHolder}>
            <Typography.Text className={styles.stepText}>
              Step 1: Choose Payment Method
            </Typography.Text>
          </div>

          <div className={styles.paymentMethodWrapper}>
            <Typography.Text className={styles.paymentTextStyle}>
              Payment Method
            </Typography.Text>
            <div className={styles.paymentMainContainer}>
              <div className={styles.rowPaymentMethods}>
                {methodLoading ? (
                  <Loader />
                ) : bankCodes && bankCodes ? (
                  bankCodes?.map((method) => {
                    return (
                      <div
                        className={styles.goCashInactive}
                        style={{
                          border:
                            selectedBank?.id === method?.id
                              ? "2px solid #FFFFFF"
                              : "",
                        }}
                        key={method?.id}
                        onClick={(e) => handleSelectMethod(e, method)}
                      >
                        <div style={{ width: "25px", height: "25px" }}>
                          <img
                            src={method?.full_image}
                            className={styles.gcashtyling}
                            alt="iconImg"
                          />
                        </div>
                        <span
                          className={styles.paymentMethodsText}
                          style={{ color: "white", textTransform: "uppercase" }}
                        >
                          {method?.public_name?.en_public_name}
                          {/* {method?.bank_code?.code_name} */}
                        </span>
                        {selectedBank?.id === method?.id ? (
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              position: "absolute",
                              right: "0px",
                              bottom: "0px",
                              objectFit: "contain",
                            }}
                          >
                            <img
                              src={selectedIcon}
                              className="imgStyle"
                              alt="selectedIcon"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                ) : (
                  <NoDataComp />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* </Badge.Ribbon> */}
      </Space>
    </>
  );
};

export default PaymentMethod;
