import { api } from "./axios";
// Home providers List API
export const getGameProviders = async (gameCategoryId,currency_id) => {
  try {
    const res = await api.get(`providers/getbycategory/${gameCategoryId}?currency=${currency_id}`);
    if (res.data && res.data) {
      return res.data;
    }
  } catch (e) {
    if (e.response.status === 500) {
      return "NETWORK_ERROR";
    }
    return e;
  }
  return null;
};

// Home category List API
export const getGameCategories = async (id) => {

  let currency_id =
  localStorage.getItem("currency_id") ?? id;
  try {
    const res = await api.get(`player/game_categories?currency=${currency_id}`);
    if (res.data && res.data) {
      return res.data;
    }
  } catch (e) {
    if (e.response.status === 500) {
      return "NETWORK_ERROR";
    }
    return e;
  }
  return null;
};

export const getCatergoriesWithGames = async (
  gameCategoryId,
  currency,
  page,
  platformId,
  gameName
) => {
  const queryParams = {
    currency: currency,
    category_id: gameCategoryId,
    page: page,
  };

  if (platformId) {
    queryParams.platform_ids = platformId;
  }

  if (gameName) {
    queryParams.game_name = gameName;
  }

  try {
    const res = await api.get(`player/game_category_details?is_mobile=1`, {
      params: queryParams,
    });
    if (res.data) {
      return res.data;
    }
  } catch (e) {
    if (e.response && e.response.status === 500) {
      return "NETWORK_ERROR";
    }
    return e;
  }
  return null;
};

// Play Game Request API
export const APIPlayGame = async (id) => {
  try {
    const res = await api.post(
      "player/game/login",
      { game_id: id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );
    if (res.data) {
      return res.data;
    }
  } catch (e) {
    return e;
    // if (e.response.data.message == "INSUFFICIENT_BALANCE") {
    //     return "insufficientBalance";
    // }else if (e.response.data.message == "PENDING_TRANSACTION") {
    //     return "PENDING_TRANSACTION";
    // } else if (e.response.data.message == "BALANCE_NETWORK_ERROR") {
    //     return "BALANCE_NETWORK_ERROR";
    // }  else {
    //     console.log(e);
    // }
  }
  return null;
};

export const getGameItem = async (categoryId, platformId) => {
  try {
    const res = await api.post("player/gameItem", {
      categoryId,
      platformId,
    });
    return res.data;
  } catch (e) {
    console.log(e);
  }
  return null;
};
