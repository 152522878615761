import React, { useEffect, useState } from "react";
import styles from "./OtpPopupModal.module.css";
import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa";
import editPng from "../../Assets/otp-verifications/Edit@2x.png";
import InputField from "../HelperComponents/InputField";
import { GenerateOtpAPI, VerifyOtpAPI } from "../../Api/Apis";
import Modal from "antd/es/modal/Modal";
import Loader from "../HelperComponents/Loader";
import { Image, Typography } from "antd";
import PhoneIcon from "../../Assets/login-register/country code.png";
import BangladeshIcon from "../../Assets/Language/Bangladesh@2x.png";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useSelector } from "react-redux";
import clientConfig from "../../config.json";

const inputBox = {
  width: "250px",
  height: "50px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "1px solid #3C3562",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "left",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontSize: "18px",
  lineHeight: "19px",
  fontFamily: "Roboto",
  letterSpacing: 0,
  color: "#FFFFFF",
};

const otpInputStyle = {};
const flagIcons = {
  PHP: PhoneIcon,
  BDT: BangladeshIcon,
};
const OtpPopupModal = ({
  hideModal,
  phone,
  setPhone,
  setFinalSuccess,
  otpPopupShow,
  show,
}) => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const country_code =
    userInfoReduxData?.currency === "BDT"
      ? "+880"
      : userInfoReduxData?.currency === "PHP"
      ? "+63"
      : clientConfig?.countryCode;
  const [isEdit, setIsEdit] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(59);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [timerExpired, setTimerExpired] = useState(false);
  const areAllOtpValuesFilled = otpValues.every((value) => value !== "");

  const getFlagIcon = () => {
    if (userInfoReduxData?.currency === "BDT") {
      return flagIcons["BDT"];
    } else if (userInfoReduxData?.currency === "PHP") {
      return flagIcons["PHP"];
    }
    return null;
  };

  const handleInputChange = (name, value) => {
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  const handleGenerateOtp = async () => {
    setLoading(true);
    setTimer();
    setError("");
    let body = {
      phone_number: `${country_code.slice(1)}${phone}`,
    };

    try {
      const data = await GenerateOtpAPI({ body });

      setLoading(false);
      if (data.status) {
        setTimer(59);
        setOtpSent(true);
      } else {
        if (data?.response?.data?.message === "NUMBER_ALREADY_REGISTERED") {
          setError("Number is already registered!");
        } else {
          setError("Failed to send OTP, please try again.");
          setTimeout(() => {
            hideModal();
            setFinalSuccess(false);
            setOtpSent(false);
            setOtpValues(["", "", "", ""]);
            setTimer(59);
            setError("");
            setSuccessMessage("");
          }, 2000);
        }
      }
      // else {
      //   setError("Failed to send OTP, please try again.");

      //   setTimeout(() => {
      //     hideModal();
      //     setFinalSuccess(false);
      //     setOtpSent(false);
      //     setOtpValues(["", "", "", ""]);
      //     setTimer(59);
      //     setError("");
      //     setSuccessMessage("");
      //   }, 2000);
      // }
    } catch (error) {
      setLoading(false);
      // console.error("Error generating OTP", error);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    const otp = otpValues.join("");
    let body = {
      phone_number: `${country_code.slice(1)}${phone}`,
      otp: otp,
    };

    try {
      const data = await VerifyOtpAPI({ body });
      if (data.status) {
        setSuccessMessage("Verified successfully");
        setLoading(true);
        setTimeout(() => {
          hideModal();
          setFinalSuccess(true);
          setOtpSent(false);
          setOtpValues(["", "", "", ""]);
          setTimer(59);
          setError("");
          setSuccessMessage("");
        }, 2000);
      } else {
        setLoading(false);
        setError("Invalid OTP, please try again.");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error verifying OTP", error);
    }
  };

  const handleResendOtp = async () => {
    setTimerExpired(false);
    await handleGenerateOtp();
    setResendMessage("Code sent again");
    setOtpValues(["", "", "", ""]);
    setTimeout(() => {
      setResendMessage("");
    }, 2000);
  };

  const handleOtpChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);

      // Move to next input field if a digit was entered
      if (value && index < 3) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleOtpKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otpValues[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    let interval = null;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setError("OTP expired, please request a new code.");
      setTimerExpired(true);
      // setLoading(true);
      // setTimeout(() => {
      //   setOtpSent(false);
      //   setLoading(false);
      //   setOtpValues(["", "", "", ""]);
      // }, 1000);
    }

    return () => clearInterval(interval);
  }, [otpSent, timer]);

  return (
    <Modal
      open={show}
      // cancelButtonProps={{ style: { display: "none" } }}
      // okButtonProps={{ style: { display: "none" } }}
      footer={null}
      closable={false}
      className={styles.modalOverlay}
    >
      <div className={styles.errorContainer}>
        <div className={`${styles.modalContentWrapper}`}>
          <div className={styles.verificationText}>Verification</div>
          <FaTimes
            size={18}
            className={styles.crossIconStyle}
            onClick={() => {
              hideModal();
              setOtpSent(false);
              setError("");
              setOtpValues(["", "", "", ""]);
              setResendMessage("");
              setSuccessMessage("");
              setTimer(59);
            }}
          />

          {otpSent && (
            <MdOutlineKeyboardBackspace
              size={24}
              style={{
                position: "absolute",
                top: "30px",
                left: "34px",
                cursor: "pointer",
                color: "#ffffff",
              }}
              // className="absolute top-4 left-4 cursor-pointer text-white"
              onClick={() => {
                setOtpSent(false);
                setError("");
                setOtpValues(["", "", "", ""]);
                setResendMessage("");
                setTimerExpired(false);
                setSuccessMessage("");
                setTimer();
              }}
            />
          )}

          {otpSent ? (
            <>
              <div className={styles.verificationSmallText}>
                Please enter the verification code <br /> we sent to{" "}
                <span style={{ color: "#BE8AFF", textDecoration: "underline" }}>
                  {phone}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "content",
                  alignItems: "center",
                  margin: "auto",
                  gap: "5px",
                }}
              >
                {otpValues.map((value, index) => (
                  <input
                    className={styles.otpField}
                    key={index}
                    id={`otp-${index}`}
                    style={otpInputStyle}
                    type="number"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleOtpKeyDown(index, e)}
                  />
                ))}
              </div>
              <div style={{ color: "#ffffff" }}>
                {timer ? formatTime(timer) : ""}
              </div>
              {error && <div style={{ color: "red" }}>{error}</div>}

              {resendMessage && (
                <div style={{ color: "#ffffff" }}>{resendMessage}</div>
              )}
              {timerExpired && (
                <div style={{ color: "#ffffff" }}>
                  Didn't get the code?{" "}
                  <span
                    className={styles.getResendText}
                    onClick={handleResendOtp}
                  >
                    Resend Code
                  </span>
                </div>
              )}
              {/* {!error && (
                <div style={{ color: "#ffffff" }}>
                  {resendMessage || (
                    <>
                      Didn't get the code?{" "}
                      <span
                        className={styles.getResendText}
                        onClick={handleResendOtp}
                      >
                        Resend Code
                      </span>
                    </>
                  )}
                </div>
              )} */}

              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <button
                  disabled={
                    timerExpired ? true : areAllOtpValuesFilled ? false : true
                  }
                  onClick={() => handleVerifyOtp()}
                  // className={`${styles.okButton}`}
                  className={`${
                    timerExpired
                      ? styles.disabledButton
                      : areAllOtpValuesFilled
                      ? styles.okButton
                      : styles.disabledButton
                  } py-2 px-10 font-normal`}
                >
                  Confirm
                </button>
              )}
              {successMessage && (
                <div
                  style={{
                    color: "green",
                    fontWeight: "normal",
                    fontSize: "16px",
                    marginTop: "8px",
                  }}
                >
                  {successMessage}
                </div>
              )}
            </>
          ) : (
            <>
              <div className={styles.verificationSmallText}>
                To verify your phone number please
                <br /> click on{" "}
                <span className={styles.getCodeText}>"Get Code"</span>
              </div>
              {isEdit ? (
                <InputField
                  // placeHolder="0.00"
                  extraStyle={inputBox}
                  type="text"
                  name="number"
                  prefix={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div style={{ width: 15, height: 20 }}>
                        <Image
                          src={getFlagIcon()}
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 1,
                            objectFit: "contain",
                          }}
                          preview={false}
                        />
                      </div>
                      <Typography.Text className={styles.countryCodeText}>
                        {country_code}
                      </Typography.Text>
                      <Typography.Text
                        style={{
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "medium",
                          fontSize: "20px",
                          color: "#574E85",
                          opacity: 1,
                          // margin: "0px 5px 1px 0px",
                        }}
                      >
                        |
                      </Typography.Text>
                    </div>
                  }
                  suffix={
                    <div>
                      <FaCheck
                        className=""
                        onClick={() => {
                          if (phone !== "") {
                            setIsEdit(false);
                          }
                        }}
                        size={20}
                      />
                    </div>
                  }
                  required={true}
                  value={phone}
                  onChange={handleInputChange}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "30px",
                    padding: "12px 0px",
                    width: "250px",
                    justifyContent: "center",
                  }}
                >
                  <div className={styles.phoneNumberText}>
                    {`${country_code} ${phone}`}
                  </div>
                  <div>
                    <img
                      style={{ cursor: "pointer" }}
                      src={editPng}
                      width="19px"
                      height="19px"
                      onClick={() => setIsEdit(true)}
                      alt="edit"
                    />
                  </div>
                </div>
              )}

              {error && <div style={{ color: "red" }}>{error}</div>}
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <button
                  // disabled={isEdit}
                  disabled={phone === "" || isEdit ? true : false}
                  onClick={() => handleGenerateOtp()}
                  // className={styles.okButton}
                  // className={`${
                  //   phone == "" || isEdit
                  //     ? ${styles.disabledButton}
                  //     : ${styles.okButton}
                  // } py-2 px-10 font-normal`}
                  className={`${
                    phone === "" || isEdit
                      ? styles.disabledButton
                      : styles.okButton
                  }`}
                >
                  Get Code
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OtpPopupModal;
