import React from "react";

const CustomButtonsIconsWrapper = ({
  parentDivStyling,
  arrayData,
  imageStyeling,
  imageDivContainer,
}) => {
  return (
    <div className={parentDivStyling}>
      {arrayData &&
        arrayData.map((payImg, index) => (
          <div className={imageDivContainer} key={index + 1}>
            <a href={payImg.link} target="_blank">
              <img
                src={payImg.img}
                alt={`img${index + 1}`}
                className={imageStyeling}
                key={index}
              />
            </a>
          </div>
        ))}
    </div>
  );
};

export default CustomButtonsIconsWrapper;
