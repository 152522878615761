import { Button, Modal } from "antd";
import React from "react";
import styles from "./ErrorPopup.module.css";
import { useNavigate } from "react-router-dom";
import errorIcon from "../../../Assets/Error.png";

const ErrorPopup = ({ message, show, hideModal }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={show}
        // onCancel={hideModal}
        footer={null}
        className={styles.customModal}
        centered
        closable={false}
        // bodyStyle={{ padding: 0 }}
      >
        <div className={styles.modalOverlay}>
          <div className={styles.loadingSection}>
            <img src={errorIcon} className={styles.errorImg} alt="errorImg" />

            <div style={{ color: "white" }}>{message}</div>
            <div className={styles.rejectButtons}>
              <Button className={styles.okButton} onClick={() => navigate("/")}>
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorPopup;
