import { api } from "./axios";
// Account providers List API

export const getAllNotifications = async (id, page) => {
  try {
    const res = await api.get(`player/${id}/notifications?page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getNotificationCount = async (id) => {
  try {
    const res = await api.get(`player/${id}/notifications/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const putMarkAsReadNotifications = async (id) => {
  try {
    const authToken = localStorage.getItem("auth_token");

    const res = await api.put(
      `player/${id}/notifications/markAsRead`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
  // return null;
};
