import { useState, useEffect } from "react";
import styles from "./SingleTransaction.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { APIGetSingleTransaction } from "../../Api/TransactionApi";
import TimeConverter from "../Helper/TimeConverter";
import Loader from "../Loader/Loader";
import useLogout from "../Layout/Header/useLogout";
import GCashPage from "../Deposit/GCashPage";
import { CommaSeperator, toFixedFunc } from "../Helper/HelperFunction";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";

const SingleTransaction = () => {
  const { t } = useTranslation();
  const [tranData, setTranData] = useState();
  const [loading, setLoading] = useState(true);

  const transactionId = useParams();
  const navigate = useNavigate();
  const logout = useLogout();

  // get single transaction
  useEffect(() => {
    transactionCall();
  }, []);

  // Single transaction API
  const transactionCall = async () => {
    const res = await APIGetSingleTransaction(transactionId.id);
    if (res) {
      setTranData(res);
      setLoading(false);
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    }
  };

  // All items
  const items = [
    ...(tranData?.isDepositTransaction?.id
      ? [
          {
            label: t("depositBank"),
            value: tranData
              ? tranData?.paymentMethod?.bank_code?.code_name
              : "Loading..",
            copy: false,
          },
        ]
      : []),
    ...(tranData?.isDepositTransaction?.id
      ? []
      : [
          {
            label: "Recipient Account Name",
            value: tranData
              ? tranData.userPaymentMethod?.account_name
              : "Loading..",
            copy: false,
            fontSmall: true,
          },
        ]),
    ...(tranData?.isDepositTransaction?.id
      ? []
      : [
          {
            label: "Account Number",
            value: tranData
              ? tranData.userPaymentMethod?.account_number &&
                tranData.userPaymentMethod.account_number.replace(
                  /\d{4}(?=.)/g,
                  "$& "
                )
              : "Loading..",
            copy: true,
          },
        ]),
    {
      label: t("depositamt"),
      value: tranData
        ? CommaSeperator(toFixedFunc(tranData?.amount))
        : "Loading..",
      copy: false,
    },
    // ...(tranData?.isDepositTransaction?.id ? [] : [{ label: "Transfer Content", value: tranData ? tranData?.user?.phone : "Loading..", copy: true, redText: true }]),
    {
      label: t("date"),
      value: tranData
        ? TimeConverter(new Date(tranData?.created_at))
        : "Loading..",
      copy: false,
    },
    {
      label: t("status"),
      value: tranData ? tranData?.status_name : "Loading..",
      copy: false,
    },
  ];

  // add item if transaction status is 2 (Rejected)
  if (tranData && tranData.is_approved === 2) {
    items.push({
      label: t("reason"),
      value: tranData ? tranData.note : "Loading..",
      redText: true,
    });
  }
  return (
    // <div className={styles.mainClass}>
    //    <div className={styles.titleWrapper}>
    //         <IoArrowBack onClick={() => navigate("/account/transactions")} style={{ cursor: 'pointer' }} size={26} />
    //             <h3>Giao dịch</h3>
    //         </div>
    //     <div className={styles.singleTranWrapper}>

    //         {loading ? (
    //             <Loader />
    //         )
    //         : tranData?.status === "Waiting For Payment" ?
    //             <GCashPage data={tranData?.isDepositTransaction?.payment_link} transactionId={tranData?.id} result={tranData} />
    //         : (
    //             <div className={styles.singleTranContentOverlay}>
    //                 <div className={styles.singleTranContentWrapper}>
    //                     {items.map((item, index) => (
    //                         <CopyItemComponent key={index} item={item} />
    //                     ))}
    //                 </div>
    //             </div>
    //         )}
    //     </div>
    // </div>
    <div className={styles.singleTransMainContainer}>
      {/* <div className={styles.titleWrapper}>
                <IoArrowBack onClick={() => navigate("/account/transactions")} style={{ cursor: 'pointer' }} size={26} />
                <h3>Transactions</h3>
            </div> */}

      {loading ? (
        <Loader />
      ) : tranData?.status === "Waiting For Payment" ? (
        <GCashPage
          data={tranData?.isDepositTransaction?.payment_link}
          transactionId={tranData?.id}
          result={tranData}
        />
      ) : (
        <div className={styles.singleTranContentOverlay}>
          <div className={styles.singleTranContentWrapper}>
            {items.map((item, index) => (
              <CopyItemComponent key={index} item={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
//  : true ? (
//     <GCashPage data={tranData?.deposit?.deposit_QR} transactionId={tranData?.id} result={tranData} />
// )
export default SingleTransaction;

const CopyItemComponent = ({ item }) => {
  const { t } = useTranslation();
  const [copyText, setCopyText] = useState("Copy");

  const onCopyClicked = () => {
    setCopyText(t("copied"));
    // navigator.clipboard.writeText(item.value);\
    copy(item.value);

    setTimeout(() => {
      setCopyText(t("copy"));
    }, 1000);
  };
  return (
    <div className={styles.bankDetailItem}>
      <div style={{ textAlign: "left" }}>
        <span className={styles.copyLabel}>{item.label}</span>
        <br />
        <span className={`${styles.copyValue}`}>{item.value}</span>
      </div>
      {item.copy && (
        <span className={styles.copyButton} onClick={onCopyClicked}>
          <MdContentCopy size={18} color="#BE8AFF" />
          <span>{copyText}</span>
        </span>
      )}
    </div>
  );
};

// check Transaction Status
const checkStatus = (status) => {
  if (status === 0) {
    return "Đang chờ";
  } else if (status === 1) {
    return "Đã phê duyệt";
  } else if (status === 2) {
    return "Từ chối";
  } else if (status === 3) {
    return "Đang xử lý";
  } else if (status === 4) {
    return "Đang xử lý";
  } else {
    return "Từ chối";
  }
};
