import { Button, Select, DatePicker, Typography, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./BettingRecord.module.css";
import useLogout from "../Layout/Header/useLogout";
import { useNavigate } from "react-router-dom";
import BettingRecordCard from "./components/BetttingRecordCard/BettingRecordCard";
import {
  getBettingRecord,
  getBettingRecordProviders,
} from "../../Api/BettingRecordApi";
import { useSelector } from "react-redux";
import { currencyHelper } from "../Helper/currencyHelper";
import { CommaSeperator, toFixedFunc } from "../Helper/HelperFunction";
import Loader from "../Loader/Loader";
const { RangePicker } = DatePicker;

const customStyle = {
  backgroundColor: "#000326",
  border: "1px solid #3B3F7B",
  // color: "white",
  // width: "160px",
  width: "160px",
  height: "34px",
};

const endDateFormat = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = "23";
  const minutes = "59";
  const seconds = "59";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const startDateFormat = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = "00";
  const minutes = "00";
  const seconds = "00";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const BettingRecord = () => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const dateFormat = "DD-MM";
  const logout = useLogout();
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([]);
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
    providers: null,
  });
  const [bettingRecordData, setBettingRecordData] = useState(null);
  const [bettingPageData, setBettingPageData] = useState(null);
  const [bettingSummaryData, setBettingSummaryData] = useState(null);
  const [methodLoading, setMethodLoading] = useState(false);
  const [bettingProviders, setBettingProviders] = useState([]);
  const options = bettingProviders.map((provider) => ({
    value: provider.id,
    label: provider.name,
  }));
  const [page, setPage] = useState(1);
  const [showViewMore, setShowViewMore] = useState(true);

  const providersHandleChange = (value) => {
    setFilterData({
      ...filterData,
      providers: value,
    });
  };

  const dateHandleChange = (range) => {
    setDateRange(range);
    if (range && range.length === 2) {
      const startDate = range[0].format();
      const endDate = range[1].format();
      setFilterData({
        ...filterData,
        startDate: startDateFormat(startDate),
        endDate: endDateFormat(endDate),
      });
    }
  };

  const handleResetFilter = () => {
    setDateRange([]);
    setFilterData({
      startDate: "",
      endDate: "",
      providers: null,
    });
    BettingRecordDetailsFunc();
  };

  const BettingRecordProviderFunc = async () => {
    setMethodLoading(true);
    const res = await getBettingRecordProviders();
    if (res) {
      setBettingProviders(res);
      setMethodLoading(false);
    } else {
      setBettingProviders();
      setMethodLoading(false);
    }
  };

  useEffect(() => {
    BettingRecordProviderFunc();
  }, []);

  const BettingRecordDetailsFunc = async (filterData) => {
    setMethodLoading(true);
    const res = await getBettingRecord(
      userInfoReduxData?.id,
      filterData?.providers,
      filterData?.startDate,
      filterData?.endDate,
      page
    );
    if (res) {
      setBettingPageData(res?.betRounds);
      setBettingRecordData(res?.betRounds?.data);
      setBettingSummaryData(res?.summary);
      if (res?.data?.length < 10) {
        setShowViewMore(false);
      }
    } else {
      setBettingRecordData([]);
    }
    setMethodLoading(false);
  };

  useEffect(() => {
    BettingRecordDetailsFunc(filterData);
  }, [userInfoReduxData, page]);

  // const handleViewMore = () => {
  //   setPage((prevPage) => prevPage + 1);
  // };

  return (
    <div className={styles.container}>
      <div style={{ padding: "15px" }}>
        {/* Filter Search Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "3%",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text
              className={styles.dateNameStyle}
              style={{ gap: "2%" }}
            >
              Date
            </Typography.Text>
            <RangePicker
              style={{ ...customStyle }}
              format={dateFormat}
              value={dateRange}
              onChange={dateHandleChange}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text
              className={styles.dateNameStyle}
              style={{ gap: "2%" }}
            >
              Providers
            </Typography.Text>

            <Select
              className="add-payment-methods"
              placeholder="Select"
              style={{
                width: "160px",
                height: "33px",
                backgroundColor: "#000326 ",
                border: "1px solid #3B3F7B",
                borderRadius: "7px",
              }}
              onChange={providersHandleChange}
              value={filterData.providers}
              options={options}
            />
          </div>

          <div className={styles.filterButton}>
            <Button
              style={{
                background:
                  "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
                borderRadius: "5px",
                border: "1px solid #3B3F7B",
                opacity: "1",
                // flexBasis:'250px;'
              }}
              type="primary"
              onClick={() => BettingRecordDetailsFunc(filterData)}
            >
              Search
            </Button>
            <Button
              style={{
                color: "white",
                background: "#000326 0% 0% no-repeat padding-box",
                border: "1px solid #3B3F7B",
                borderRadius: "5px",
                opacity: "1",
              }}
              onClick={handleResetFilter}
            >
              Reset
            </Button>
          </div>
        </div>

        {/* Betting Record Card Details Sections */}
        <div className={`${styles.container}`}>
          <BettingRecordCard
            methodLoading={methodLoading}
            bettingRecordData={bettingRecordData}
          />
        </div>

        {methodLoading ? (
          <></>
        ) : (
          <>
            {showViewMore && bettingRecordData?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "20px 0",
                  marginBottom: "100px",
                }}
              >
                <Pagination
                  className="custom-pagination"
                  defaultCurrent={1}
                  current={page}
                  pageSize={10}
                  total={bettingPageData?.total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  style={{
                    // background:"#574f85",
                    background:
                      "transparent linear-gradient(180deg, #5158de 0%, #613ade 48%, #711ade 100%) 0% 0% no-repeat padding-box",
                    color: "#613ade",
                    borderRadius: "5px",
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.stickyBottomFixed}>
        <div className={styles.bottomCardWrapper}>
          {methodLoading ? (
            <div
              style={{
                paddingTop: "3%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className={styles.cardContainter}>
              <div className={styles.cardAmountsHolder}>
                <div className={styles.stepText}>
                  <Typography.Text className={styles.stepBetAmountText}>
                    Bet Amount:
                  </Typography.Text>
                  <Typography.Text className={styles.stepBetAmountPriceText}>
                    {currencyHelper(userInfoReduxData?.currency)}
                    {CommaSeperator(
                      toFixedFunc(bettingSummaryData?.total_turnovers)
                    )}
                  </Typography.Text>
                </div>
                <div className={styles.stepText}>
                  <Typography.Text className={styles.stepBetAmountText}>
                    Winnings:
                  </Typography.Text>
                  <Typography.Text className={styles.stepBetAmountPriceText}>
                    {currencyHelper(userInfoReduxData?.currency)}
                    {CommaSeperator(
                      toFixedFunc(bettingSummaryData?.total_win_amount)
                    )}
                  </Typography.Text>
                </div>
              </div>

              <div className={styles.cardAmountsHolder}>
                <div className={styles.stepText}>
                  <Typography.Text className={styles.stepBetAmountText}>
                    Valid Bet:
                  </Typography.Text>
                  <Typography.Text className={styles.stepBetAmountPriceText}>
                    {currencyHelper(userInfoReduxData?.currency)}
                    {CommaSeperator(
                      toFixedFunc(bettingSummaryData?.total_valid_bets)
                    )}
                  </Typography.Text>
                </div>

                <div className={styles.stepText}>
                  <Typography.Text className={styles.stepBetAmountText}>
                    Profit and Loss:
                  </Typography.Text>
                  <Typography.Text className={styles.stepBetAmountPriceText}>
                    {currencyHelper(userInfoReduxData?.currency)}
                    {CommaSeperator(
                      toFixedFunc(bettingSummaryData?.total_win_loss)
                    )}
                  </Typography.Text>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BettingRecord;
