import React, { useEffect, useState } from "react";
import styles from "./EwalletManagement.module.css";
import Glogo from "../../Assets/payment icons/G.png";
import Gcash from "../../Assets/payment icons/GCash.png";
import { Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { userPaymentMethodList } from "../../Api/BankApi";
import useLogout from "../Layout/Header/useLogout";
import Loader from "../HelperComponents/Loader";
import { useTranslation } from "react-i18next";
import SelectedIcon from "../../Assets/payment icons/Selected Method.png";
import addEWallet from "../../Assets/payment icons/Add E-Wallet.png";
import NoDataComp from "../NoData/NoDataComp";

const EwalletManagement = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loader, setLoader] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const logout = useLogout();
  const { t } = useTranslation();

  const addWallet = () => {
    paymentMethods?.length < 3 && !loader && navigate("/add-payment-method");
  };

  const getBankListFunc = async () => {
    setLoader(true);
    const res = await userPaymentMethodList();
    if (res?.data?.length === 0 || res?.data == null) {
      // setErrorMessage(t("bankErrMessage"));
      setPaymentMethods([]);
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      setPaymentMethods(res.data);
    }
    setLoader(false);
  };
  useEffect(() => {
    getBankListFunc();
  }, []);

  return (
    <div className={styles.ewalletContainer}>
      {loader ? (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      ) : paymentMethods?.length ? (
        paymentMethods?.map((item) => {
          return (
            <div className={styles.gCashContainer}>
              <div style={{ display: "flex", gap: "10px" }}>
                {/* <div style={{ width: 24, height: 20, }}>
                                <img src={Glogo} style={{ width: "100%", height: "100%", opacity: 1, objectFit: 'contain' }} alt='logo' />
                            </div> */}
                <div
                // style={{ width: 69, height: 16 }}
                >
                  <Typography.Text className={styles.holdersNameStyling}>
                    {item?.account_name}
                  </Typography.Text>
                  {/* <img src={Gcash} style={{ width: "100%", height: "100%", opacity: 1, objectFit: 'contain' }} /> */}
                </div>
              </div>
              <div className={styles.nameAccountContainer}>
                <Typography.Text className={styles.holdersNameStyling}>
                  {item?.bank_code?.public_name?.en_public_name}
                </Typography.Text>
                <Tooltip
                  title={
                    item?.account_number.length > 23 ? item?.account_number : ""
                  }
                >
                  <Typography.Text
                    className={`${styles.holdersNameStyling} ${styles.accountNumberStyling}`}
                    style={{ minWidth: "auto" }}
                  >
                    {item?.account_number}
                  </Typography.Text>
                </Tooltip>
              </div>
              {/* <div style={{ width: 40, height: 40, display: 'flex', position: 'absolute', right: "-2px", bottom: "-2px" }}>
                            <img src={SelectedIcon} style={{ width: "100%", height: '100%', opacity: 1, }} />
                        </div> */}
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <NoDataComp message={"No Payment Accounts Found. Please Add New."} />
          <div style={{ display: "flex", margin: "15px 0" }}>
            <Typography.Text className={styles.enterAmountText}>
              {errorMessage}
            </Typography.Text>
          </div>
        </div>
      )}

      <div
        className={`${styles.addWalletContainer} ${
          paymentMethods?.length < 3 || loader
            ? styles.buttonActive
            : styles.disabled
        }`}
        onClick={addWallet}
      >
        <div style={{ display: "flex", width: 28, height: 28 }}>
          <img src={addEWallet} className="imgStyle" alt="addEwalletIcon" />
        </div>
        <div style={{ display: "flex" }}>
          <Typography.Text className={styles.addeWalletTextStyling}>
            Add E-Wallet
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default EwalletManagement;
