import React from "react";
import styles from "./Categories.module.css";
import Loader from "../HelperComponents/Loader";
import { Typography } from "antd";

const Categories = (props) => {
  const {
    errorMsg,
    isLoading,
    gameCategories,
    activeCatId,
    setGames,
    setPlatforms,
    setActiveCatId,
    setPage,
    setSelectedPlatforms,
    setSearchData,
  } = props;
  return (
    <div className={styles.categories}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {gameCategories && !gameCategories?.length ? (
            <Typography.Text className="noDataFoundText">
              {errorMsg}
            </Typography.Text>
          ) : !isLoading ? (
            gameCategories?.map((category, index) => {
              const categoryName = JSON.parse(category?.name)?.en;
              const formattedCategoryName = categoryName
                ? categoryName.charAt(0).toUpperCase() +
                  categoryName.slice(1).toLowerCase()
                : "";
              return (
                <div
                  onClick={() => {
                    if (category?.id === activeCatId?.id) return;
                    setGames([]);
                    setSearchData("");
                    setPlatforms([]);
                    setActiveCatId(category);
                    setPage(1);
                    setSelectedPlatforms([]);
                    // setActiveCat(category);
                    // activeCategoryFunc(category);
                  }}
                  key={category?.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    cursor: "pointer",
                    padding: "2px 0px",
                  }}
                  className={
                    activeCatId?.id === category?.id
                      ? styles.selectedCategoryStyle
                      : ""
                  }
                >
                  <div key={category?.id}>
                    <img
                      src={
                        activeCatId?.id === category.id
                          ? `${category.icon_active}`
                          : `${category?.icon_image}`
                      }
                      className={`
          ${styles.singleCategory} ${styles.categoryImageStyle} 
          ${activeCatId?.id === category.id ? styles.activeSingleCategory : ""}
         `}
                      alt={`img${category?.id}`}
                    />
                  </div>
                  <div className={styles.catNameContainer}>
                    <span className={styles.categoryName}>
                      {formattedCategoryName}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Categories;
