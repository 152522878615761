import React from "react";
import { Button, Divider, Image, Modal } from "antd";
import styles from "./AgeVerificationsPopup.module.css";
import Logo from "../../../Assets/BW9@2x.png";
import responsibleGameimg from "../../../Assets/home/ResponsibleGamingNew.png";
import { Link } from "react-router-dom";

const AgeVerificationPopup = ({ show, hideModal }) => {
  return (
    <Modal className={styles.modalOverlay} visible={show} footer={null} closable={false}>
      <div>
        <div className={styles.loadingSection}>
          <div className={styles.imgContainer}>
          <img src={Logo} className='imgStyle' alt="errorImg" />
          </div>
          <div className={styles.headerTextStyling}>Age Verification</div>
          <div className={styles.paraTextStyling}>
            Please verify that you are 21 years old or older to enter this site
          </div>

          <div className={styles.paraTextStyling}>
            I agree to the BW9{" "}
            <Link to="terms-and-conditions">
              <span className={styles.termsTextStyling}>
                Terms and Conditions
              </span>
            </Link>
          </div>

          <div className={styles.rejectButtons}>
            <Button className={styles.okButton} onClick={hideModal}>
              I'm over 21 years old
            </Button>
            <Link to="https://www.google.com/">
              <Button className={styles.editBtn}> Exit</Button>
            </Link>
          </div>
          <Divider
            orientation="right"
            plain
            style={{
              borderColor: "#707070 ",
            }}
          />

          <div className={styles.responsibleImgStyling}>
            <Image
              preview={false}
              src={responsibleGameimg}
              alt="responsible gaming"
              // className="imgStyle"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AgeVerificationPopup;
