import React from "react";


const CustomMarquee = ({ cls, img, extraStyle, text, textStyle }) => {

  const imageStyle = {
    width: '50px',
    height: '50px',
  };

  return (
    <div className={cls !== "" ? cls : ""}>
      <div style={{ ...imageStyle, ...extraStyle }}>
        <img src={img} alt="img" className="imgStyle" />
      </div>
      <marquee style={{
        textAlign: 'left',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        fontFamily: 'Roboto',
        letterSpacing: '0px',
        color: '#FFFFFF',
        opacity: 1
      }}>
        {text ? text : "sample text"}
      </marquee>
    </div>
  )
};


export default CustomMarquee;