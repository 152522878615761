import React, { useEffect, useState, useRef } from "react";
import styles from "./Platforms.module.css";
import checkedIcon from "../../Assets/payment icons/Selected Method.png";
import AllProviders from "../../Assets/Providers/ALL.png";

const Platforms = ({
  platforms,
  setSelectedPlatforms,
  selectedPlatforms,
  setMulitpleSelectedPlatforms,
  setGames,
  setUpdateAllPlatForm,
  setPage,
  setSearchData,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const platformLength = platforms && platforms.length;
  //Scrollable Content
  const scrollableContainerRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [initialScrollX, setInitialScrollX] = useState(0);

  const handlePlatform = (platformId) => {
    setSearchData("");
    setPage(1);
    setGames([]);
    setMulitpleSelectedPlatforms([]);
    setSelectedPlatforms([platformId]);
  };

  const handlePlatformAll = () => {
    setSearchData("");
    setPage(1);
    setGames([]);
    setUpdateAllPlatForm(platforms);
    setSelectedPlatforms([]);
  };

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setInitialMouseX(event.clientX);
    setInitialScrollX(scrollableContainerRef.current.scrollLeft);
    scrollableContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    const deltaX = event.clientX - initialMouseX;
    scrollableContainerRef.current.scrollLeft = initialScrollX - deltaX;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    scrollableContainerRef.current.style.cursor = "grab";
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <div
      className={`${styles.platformWrapper} scrollable-container-Platform`}
      // style={{ overflowY: platformLength >= 4 ? true : false }}
      ref={scrollableContainerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div
        className={`${styles.singlePlatform} ${
          selectedPlatforms.length === 0 && styles.activePlatform
        } `}
        onClick={() => handlePlatformAll()}
      >
        <div className={styles.imgWrapper}>
          <img
            className={styles.imgStyle}
            alt={`platformImage`}
            src={AllProviders}
          />
        </div>
        <span className={styles.title}>ALL</span>
      </div>
      {platforms?.map((platform) => {
        return (
          <div
            className={`${styles.singlePlatform} ${
              selectedPlatforms.includes(platform.id) && styles.activePlatform
            } `}
            key={platform?.id}
            onClick={() => handlePlatform(platform.id)}
          >
            <div className={styles.imgWrapper}>
              <img
                className={styles.imgStyle}
                alt={`platformImage`}
                src={platform.icon_image}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Platforms;
