import React, { useEffect, useState } from "react";
import styles from "./Transaction.module.css";
import CustomButton from "../HelperComponents/CustomButton";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Pagination,
  Popover,
  Row,
  Select,
  Typography,
} from "antd";
import { APIGetAllTransactions } from "../../Api/TransactionApi";
import CopyIcon from "../../Assets/Copy.svg";
import useLogout from "../Layout/Header/useLogout";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import { CommaSeperator, toFixedFunc } from "../Helper/HelperFunction";
import NoDataComp from "../NoData/NoDataComp";
import { getTransitionName } from "antd/es/_util/motion";
import copy from "copy-to-clipboard";
const { RangePicker } = DatePicker;

const customStyle = {
  backgroundColor: "#000326",
  border: "1px solid #3B3F7B",
  color: "#574f85",
  // width: "180px",
  // width: "160px",
  height: "34px",
};

const endDateFormat = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = "23";
  const minutes = "59";
  const seconds = "59";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const startDateFormat = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = "00";
  const minutes = "00";
  const seconds = "00";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const Transactions = () => {
  const dateFormat = "DD-MM";
  const logout = useLogout();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const location = useLocation();
  const state = location.state;
  const [loading, setLoading] = useState(false);
  const [toggleButton, setToggleButon] = useState({
    deposit: state?.active === "withdraw" ? false : true,
    withdrawal: state?.active === "withdraw" ? true : false,
  });
  const [dateRange, setDateRange] = useState([]);
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
    status: null,
  });
  const [allTransactions, setAllTransactions] = useState([]);
  const [allRecords, setAllRecords] = useState(null);

  const statusHandleChange = (value) => {
    setFilterData({
      ...filterData,
      status: value,
    });
  };

  const dateHandleChange = (range) => {
    setDateRange(range);
    if (range && range.length === 2) {
      const startDate = range[0].format();
      const endDate = range[1].format();
      setFilterData({
        ...filterData,
        startDate: startDateFormat(startDate),
        endDate: endDateFormat(endDate),
      });
    }
  };
  const handleResetFilter = () => {
    // setDateRange([]);
    // setFilterData({
    //   startDate: "",
    //   endDate: "",
    //   status: null,
    // });
    // setPage(1);
    // setResetButton("sdfsdfsd");
    setDateRange([]);
    const resetFilterData = {
      startDate: "",
      endDate: "",
      status: null,
    };
    setFilterData(resetFilterData);
    getAllTransactionsFunc(1, resetFilterData);
    // getAllTransactionsFunc(1);
  };

  useEffect(() => {
    setPage(1);
    handleResetFilter();
  }, [toggleButton]);

  const handleToggleButn = (e) => {
    let buttonText = e.target.textContent;
    if (buttonText === "Deposit") {
      setToggleButon({
        deposit: true,
        withdrawal: false,
      });
    } else {
      setToggleButon({
        deposit: false,
        withdrawal: true,
      });
    }
  };
  const getAllTransactionsFunc = async (
    page = 1,
    filterDataParam = filterData
  ) => {
    setLoading(true);
    setPage(page);
    const res = await APIGetAllTransactions(
      filterDataParam,
      page,
      toggleButton?.withdrawal ? 1 : 0
    );

    if (res?.status === 200) {
      setAllRecords(res?.data);
      if (res?.data?.data?.length > 0) {
        setAllTransactions(res?.data?.data);
      } else {
        setAllTransactions([]);
      }
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      setAllTransactions([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllTransactionsFunc(page);
  }, [page]);

  const handleCopyClick = (value) => {
    const textToCopy = value;

    // navigator.clipboard.writeText(textToCopy);
    copy(textToCopy);
  };

  return (
    <div className={styles.container}>
      <div className={styles.toggleBtnContainer}>
        <div className={styles.togglebox}>
          <div
            className={`${styles.toggleBtn} ${
              toggleButton.deposit ? styles.toggleLeft : styles.toggleRight
            }`}
          ></div>
          <CustomButton
            className="no-hover"
            type={`${styles.transactionBtn} ${
              toggleButton.deposit
                ? styles.transactionBtnFontColorActive
                : styles.transactionBtnFontColorInactive
            }`}
            text="Deposit"
            onClick={handleToggleButn}
          />
          <CustomButton
            className="no-hover"
            type={`${styles.transactionBtn} ${
              toggleButton.withdrawal
                ? styles.transactionBtnFontColorActive
                : styles.transactionBtnFontColorInactive
            }`}
            text="Withdrawal"
            onClick={handleToggleButn}
          />
        </div>
      </div>

      <div className={styles.filterContainer}>
        <div
          className={styles.dateWrapper}
          // style={{
          //   display: "flex",
          //   flexDirection: "column",
          //   color: "#574f85",
          // }}
        >
          <Typography.Text
            className={styles.dateNameStyle}
            style={{ gap: "2%", width: "40px" }}
          >
            Date
          </Typography.Text>
          <RangePicker
            className={styles.DatepickerStyle}
            // style={{ ...customStyle }}
            format={dateFormat}
            value={dateRange}
            onChange={dateHandleChange}
          />
        </div>
        <div className={styles.dateWrapper}>
          <Typography.Text
            className={styles.dateNameStyle}
            style={{ gap: "2%", width: "43px" }}
          >
            Status
          </Typography.Text>

          <Select
            className={`${styles.selectStatusStyles} add-payment-methods`}
            placeholder="Select"
            onChange={statusHandleChange}
            value={filterData.status}
            options={[
              { value: "1", label: "Approved" },
              { value: "2", label: "Rejected" },
              { value: "3", label: "Pending" },
              { value: "5", label: "Processing" },
              // { value: "5", label: "Waiting For Payment" },
              { value: "6", label: "Failed" },
            ]}
          />
        </div>

        <div className={styles.filterButton}>
          <Button
            style={{
              background:
                "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              border: "1px solid #3B3F7B",
              opacity: "1",
              width: "105px",
              // flexBasis:'250px;'
            }}
            type="primary"
            onClick={() => getAllTransactionsFunc(1)}
          >
            Search
          </Button>
          <Button
            style={{
              color: "white",
              background: "#000326 0% 0% no-repeat padding-box",
              border: "1px solid #3B3F7B",
              borderRadius: "5px",
              opacity: "1",
              width: "105px",
            }}
            onClick={handleResetFilter}
          >
            Reset
          </Button>
        </div>
      </div>

      <div className={styles.transactionContainer}>
        <div className={`${styles.depositTransactionContainer} `}>
          <Row style={{ width: "100%" }}>
            <Col span={4} className={styles.transContainerHeader}>
              {/* Reference */}
              Ref ID
            </Col>
            <Col span={4} className={styles.transContainerHeader}>
              Amount
            </Col>
            <Col span={9} className={styles.transContainerHeader}>
              Time
            </Col>
            <Col span={7} className={styles.transContainerHeader}>
              Status
            </Col>
          </Row>
          {loading ? (
            <Loader />
          ) : allTransactions?.length ? (
            allTransactions?.map((item) => {
              let date = new Date(item.created_at);
              const formattedDate = date.toLocaleString();
              const [datePart, timePart] = formattedDate.split(", ");
              return (
                <Row
                  onClick={() => navigate("/transections/" + item?.id)}
                  className={styles.transDataContainer}
                  key={item.id}
                >
                  <Col
                    style={{ display: "flex", gap: "2px", justifyContent:"center" }}
                    span={4}
                    className={styles.transContainerRows}
                  >
                    {item.id}
                    <div onClick={(event) => event.stopPropagation()}>
                      <Popover
                        overlayStyle={{ padding: "0px", width: "60px" }}
                        content={"copied"}
                        trigger="click"
                      >
                        <img
                          onClick={() => handleCopyClick(item.id)}
                          className={styles.imgStyle}
                          style={{ cursor: "pointer", marginLeft: "2%" }}
                          src={CopyIcon}
                          alt="CopyIcon"
                        />
                      </Popover>
                    </div>
                  </Col>
                  <Col span={4} className={styles.transContainerRows}>
                    {CommaSeperator(toFixedFunc(item.amount))}
                  </Col>
                  <Col
                    span={9}
                    style={{ display: "flex", flexDirection: "column" }}
                    className={styles.transDateContainerRows}
                  >
                    <div>{datePart}</div>
                    <div>{timePart}</div>
                  </Col>
                  <Col span={7} className={styles.transContainerRows}>
                    <div
                      className={`${styles.statusContainer}  ${
                        item.status_name === "approved" ? styles.successful : ""
                      } ${
                        item.status_name === "rejected" ||
                        item.status_name === "Failed"
                          ? styles.rejected
                          : ""
                      } ${
                        item.status_name === "Waiting For Payment"
                          ? styles.processing
                          : ""
                      } ${
                        item.status_name === "pending" ? styles.pending : ""
                      }`}
                    >
                      <Typography.Text
                        className={styles.itemStatusText}
                        style={{
                          color:
                            item.status_name === "approved"
                              ? "#00FFB5"
                              : item.status_name === "rejected" ||
                                item.status_name === "Failed"
                              ? "#FF5757"
                              : item.status_name === "Waiting For Payment"
                              ? "#FFC36A"
                              : item.status_name === "pending"
                              ? "#BE8AFF"
                              : "white",
                        }}
                      >
                        {item.status_name === "Waiting For Payment"
                          ? "Processing"
                          : item.status_name}
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <NoDataComp />
          )}
        </div>
      </div>

      {loading ? (
        <></>
      ) : (
        <>
          {allTransactions?.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px 0",
                marginBottom: "100px",
              }}
            >
              <Pagination
                className="custom-pagination"
                defaultCurrent={1}
                current={page}
                pageSize={10}
                total={allRecords?.total}
                onChange={(value) => setPage(value)}
                showSizeChanger={false}
                style={{
                  // background:"#574f85",
                  background:
                    "transparent linear-gradient(180deg, #5158de 0%, #613ade 48%, #711ade 100%) 0% 0% no-repeat padding-box",
                  color: "#613ade",
                  borderRadius: "5px",
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Transactions;
