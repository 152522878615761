import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APIPlayGame } from "../../Api/GamesApi";
import LoginPopupModal from "../Login/LoginPopupModal";
import useLogout from "../Layout/Header/useLogout";
import Loader from "./Loader";
import NoImageIcon from "../../Assets/NoImageAvailabel/NoImage2.jpg";
import { useNavigate } from "react-router-dom";

const CustomGrid = ({
  games,
  imageStyling,
  parentDivStyling = "",
  imageContainer,
  imageOverlay,
  playButtonContainer,
  playIcon,
  loadingImageOverlay,
}) => {
  const userReduxData = useSelector((state) => state?.user?.user);
  const [popupError, setPopupError] = useState("");
  const logout = useLogout();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loadingGameId, setLoadingGameId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const APIPlayGameFunc = async (id) => {
    let res;
    if (userReduxData) {
      setLoadingGameId(id);
      let newWindow = window.open();
      res = await APIPlayGame(id);
      if (res.status && res.message === "SUCCESS") {
        if (res.result.is_url) {
          // var newWindow = window.open();
          newWindow.location = res?.result?.data;
        } else {
          const scriptString = res?.result?.data;
          // Separate the scripts
          const scripts = scriptString.split("</script>").filter(Boolean);

          scripts.forEach((scriptContent) => {
            const script = document.createElement("script");
            if (scriptContent.includes("src=")) {
              // External script
              const srcMatch = scriptContent.match(/src="([^"]+)"/);
              const src = srcMatch ? srcMatch[1] : null;
              if (src) {
                script.src = src;
                const integrityMatch =
                  scriptContent.match(/integrity="([^"]+)"/);
                if (integrityMatch) {
                  script.integrity = integrityMatch[1];
                }
                const crossoriginMatch = scriptContent.match(
                  /crossorigin="([^"]+)"/
                );
                if (crossoriginMatch) {
                  script.crossOrigin = crossoriginMatch[1];
                }
                document.head.appendChild(script);
              }
            } else {
              // Inline script
              const inlineScriptContent = scriptContent.replace(
                /<script type = "text\/javascript">/,
                ""
              );
              script.text = inlineScriptContent.trim();
              document.head.appendChild(script);
            }
          });
        }
      } else if (res.response?.status === 403) {
        newWindow.close();
        if (res.response.data.message === "PLAYER_FORBIDDEN") {
          setPopupError(
            "Player is not allowed to play games, Please contact the customer support to activate your account."
          );
        } else if (res.response.data.message === "REGISTRATION_NETWORK_ERROR") {
          setPopupError("REGISTRATION NETWORK ERROR");
        } else if (res.response.data.message === "CURRENCY_NOT_SUPPORTED") {
          setPopupError("CURRENCY NOT SUPPORTED");
        } else {
          setPopupError(
            "Player is not allowed to play games, Please contact the customer support to activate your account."
          );
        }
      } else if (res.response?.status === 401) {
        newWindow.close();
        logout();
      } else if (res?.response?.status === 400) {
        newWindow.close();
        if (res?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        }
      } else {
        newWindow.close();
        setPopupError("An unexpected error occurred. Please try again later.");
      }
      setLoadingGameId(null);
    } else {
      logout();
    }
  };

  return (
    <div className={`${parentDivStyling} category-grid`}>
      <Row gutter={games?.length < 3 ? 100 : 12}>
        {games?.map((game) => {
          return (
            <Col
              key={game.id}
              sm={games?.length >= 3 ? 8 : games?.length === 2 ? 12 : 24}
              span={screenWidth <= 576 ? (games.length >= 2 ? 8 : 24) : ""}
              style={{ padding: "6px" }}
            >
              <div className={imageContainer}>
                {game.game_item_id === loadingGameId ? (
                  <div
                    className={loadingImageOverlay}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div className={imageOverlay}>
                    <div className={playButtonContainer}>
                      <img
                        src={playIcon}
                        alt="playIcon"
                        className="imgStyle"
                        onClick={() => APIPlayGameFunc(game.game_item_id)}
                      />
                    </div>
                  </div>
                )}
                <img
                  style={{ cursor: "pointer" }}
                  loading="lazy"
                  src={game?.icon_square}
                  alt={`Img${game.game_item_id}`}
                  className={imageStyling}
                  onClick={() => APIPlayGameFunc(game.game_item_id)}
                  onError={(e) => (e.target.src = NoImageIcon)}
                />
              </div>
            </Col>
          );
        })}
      </Row>
      {popupError && (
        <LoginPopupModal
          message={popupError}
          show={popupError ? true : false}
          hideModal={() => {
            setPopupError("");
            if (
              popupError ===
              "Player is not allowed to play games, Please contact the customer support to activate your account."
            ) {
              navigate("/support");
            }
          }}
        />
      )}
    </div>
  );
};

export default CustomGrid;
