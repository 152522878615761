import { Image, Modal, Typography } from "antd";
import React from "react";
import styles from "./HomePageLoader.module.css";
import Logo from "../../../../Assets/BW9@2x.png";
import LoadingIcon from "../../../../Assets/LoadingIcon/Loading@2x.png";

const HomePageLoader = ({ show }) => {
  return (
    <Modal className={styles.modalOverlay} closable={false}  open={show} footer={null}>
      <div className={styles.wrapper}>
        <div className={styles.loadingSectionw}>
          <div className={styles.loadingSectionWrapper}>
            <div className={styles.loadingSection}>
              <div className={styles.logoSection}>
                <img src={Logo} className={styles.logoStyle} alt="errorImg" />
                <div className={styles.headerTextStyling}>BEST WAY TO WIN!</div>
              </div>

              <div className={styles.loadingSpinIcon}>
                <Image
                  className="animate-spin"
                  preview={false}
                  src={LoadingIcon}
                  alt="Loading"
                  width="25px"
                  height="25px"
                />
                <Typography.Text className={styles.LoadingText}>
                  Loading
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HomePageLoader;
