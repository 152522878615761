import { Typography } from "antd";
import styles from "./BettingRecordCard.module.css";
import React from "react";
import NoDataComp from "../../../NoData/NoDataComp";
import Loader from "../../../Loader/Loader";
import { currencyHelper } from "../../../Helper/currencyHelper";
import { CommaSeperator, toFixedFunc } from "../../../Helper/HelperFunction";
import { useSelector } from "react-redux";
import CopyIcon from "../../../../Assets/Copy.svg";
import copy from "copy-to-clipboard";

const BettingRecordCard = (props) => {
  const { methodLoading, bettingRecordData } = props;
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);

  function formatDateTime(timestamp) {
    const [datePart, timePart] = timestamp.split(" ");
    return `${timePart} ${datePart}`;
  }

  const handleCopyClick = (data) => {
    const textToCopy = data;

    // navigator.clipboard.writeText(textToCopy);
    copy(textToCopy);
  };

  return (
    <div>
      {methodLoading || !bettingRecordData ? (
        <Loader />
      ) : bettingRecordData?.length > 0 ? (
        <div>
          {bettingRecordData?.map((record, index) => {
            return (
              <div key={index} className={styles.cardWrapper}>
                <div className={styles.cardContainter}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "70%",
                    }}
                  >
                    <div
                      style={{ marginBottom: "1.5%" }}
                      className={styles.cardHolder}
                    >
                      <Typography.Text className={styles.stepLeftText}>
                        Reference ID:
                      </Typography.Text>

                      <div style={{ display: "flex", gap: "2px" }}>
                        <div>
                          <Typography.Text className={styles.stepRightText}>
                            {/* {record.round_reference} */}
                            {record.id}
                          </Typography.Text>
                        </div>
                        <div style={{ marginTop: "3px" }}>
                          <img
                            onClick={() => handleCopyClick(record.id)}
                            className={styles.imgStyle}
                            style={{ cursor: "pointer", marginLeft: "10%" }}
                            src={CopyIcon}
                            alt="CopyIcon"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ marginBottom: "2%" }}
                      className={styles.cardHolder}
                    >
                      <Typography.Text className={styles.stepLeftText}>
                        Bet Time:
                      </Typography.Text>

                      <Typography.Text className={styles.stepRightText}>
                        {formatDateTime(record.started_on)}
                      </Typography.Text>
                    </div>

                    <div className={styles.stepText}>
                      <Typography.Text className={styles.stepBetAmountText}>
                        Valid Bet:
                      </Typography.Text>

                      <Typography.Text
                        className={styles.stepBetAmountPriceText}
                      >
                        {currencyHelper(userInfoReduxData?.currency)}
                        {CommaSeperator(toFixedFunc(record.total_valid_bets))}
                      </Typography.Text>
                    </div>
                  </div>

                  <div className={styles.cardAmountsHolder}>
                    <div
                      style={{ marginBottom: "11%" }}
                      className={styles.cardHolder}
                    >
                      <Typography.Text className={styles.stepLeftText}>
                        Game Name:
                      </Typography.Text>

                      <Typography.Text className={styles.stepRightText}>
                        {JSON.parse(record.name)?.en}
                      </Typography.Text>
                    </div>

                    <div
                      style={{ marginBottom: "8%" }}
                      className={styles.stepText}
                    >
                      <Typography.Text className={styles.stepBetAmountText}>
                        Bet Amount:
                      </Typography.Text>

                      <Typography.Text
                        className={styles.stepBetAmountPriceText}
                      >
                        {currencyHelper(userInfoReduxData?.currency)}
                        {CommaSeperator(toFixedFunc(record.total_turnovers))}
                      </Typography.Text>
                    </div>

                    <div className={styles.stepText}>
                      <Typography.Text className={styles.stepBetAmountText}>
                        Profit/Loss:
                      </Typography.Text>

                      <Typography.Text
                        style={{
                          color: record.win_loss >= 0 ? "white" : " red",
                        }}
                        className={styles.profitLossText}
                      >
                        {CommaSeperator(toFixedFunc(record.win_loss))}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoDataComp />
      )}
    </div>
  );
};

export default BettingRecordCard;
