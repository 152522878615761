import React, { useEffect, useState } from 'react';
import styles from "./Record.module.css";
import { Button, DatePicker, Pagination, Typography } from 'antd';
import RecordsCard from './RecordsCard';
import { getAffiliateRecord } from '../../Api/AffiliateApi';
import Loader from '../HelperComponents/Loader';
const { RangePicker } = DatePicker;


const endDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = "23";
    const minutes = "59";
    const seconds = "59";

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const startDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = "00";
    const minutes = "00";
    const seconds = "00";

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const Record = () => {

    const dateFormat = "DD-MM";
    const [dateRange, setDateRange] = useState([]);
    const [filterData, setFilterData] = useState({
        startDate: "",
        endDate: "",
    });
    const [affiliateRecordData, setAffiliateRecordData] = useState(null);
    const [affiliatePageData, setAffiliatePageData] = useState(null);
    const [methodLoading, setMethodLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [showViewMore, setShowViewMore] = useState(true);

    const dateHandleChange = (range) => {
        setDateRange(range);
        if (range && range.length === 2) {
            const startDate = range[0].format();
            const endDate = range[1].format();
            setFilterData({
                startDate: startDateFormat(startDate),
                endDate: endDateFormat(endDate),
            });
        }
    };

    const handleResetFilter = () => {
        setDateRange([]);
        setPage(1);
        setFilterData({
            startDate: "",
            endDate: "",
        });
        AffiliateRecordDetailsFunc();
    };

    const AffiliateRecordDetailsFunc = async (filterData) => {
        setMethodLoading(true);
        const res = await getAffiliateRecord(
            filterData?.startDate,
            filterData?.endDate,
            page
        );
        if (res && res?.data) {
            setAffiliatePageData(res);
            setAffiliateRecordData(res?.data);
            if (res?.data?.length < 10) {
                setShowViewMore(false);
            }else{
                setShowViewMore(true);
            }
        } else {
            setAffiliateRecordData([]);
        }
        setMethodLoading(false);
    };

    useEffect(() => {
        AffiliateRecordDetailsFunc(filterData);
    }, [page]);

    const customStyle = {
        backgroundColor: "#000326",
        border: "1px solid #3B3F7B",
        // color: "white",
        // width: "160px",
        width: "160px",
        height: "32px",
    };

    
    return (
        <>
        { methodLoading?<Loader/> :
            <div className={styles.container}>

        {/* Filter Search Section */}
        <div
            style={{
                display: 'flex',
                gap: 10,
                alignItems: 'end',
                marginBottom: 20,
                flexWrap: 'wrap'
            }}
        >
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text
                    className={styles.dateNameStyle}
                    style={{ gap: "2%" }}
                >
                    Date
                </Typography.Text>
                <RangePicker
                    style={{ ...customStyle }}
                    format={dateFormat}
                    value={dateRange}
                    onChange={dateHandleChange}
                />
            </div>

            <div className={styles.filterButton}>
                <Button
                    style={{
                        background:
                            "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "5px",
                        border: "1px solid #3B3F7B",
                        opacity: "1",
                        // flexBasis:'250px;'
                    }}
                    type="primary"
                    onClick={() => {setPage(1);AffiliateRecordDetailsFunc(filterData)}}
                >
                    Search
                </Button>
                <Button
                    style={{
                        color: "white",
                        background: "#000326 0% 0% no-repeat padding-box",
                        border: "1px solid #3B3F7B",
                        borderRadius: "5px",
                        opacity: "1",
                    }}
                    onClick={handleResetFilter}
                >
                    Reset
                </Button>
            </div>
        </div>

        <RecordsCard
            methodLoading={methodLoading}
            affiliateRecordData={affiliateRecordData}
        />

        {methodLoading ? (
            <></>
        ) : (
            <>
                {showViewMore && affiliateRecordData?.length > 0 && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "20px 0",
                            marginBottom: "100px",
                        }}
                    >
                        <Pagination
                            className="custom-pagination"
                            defaultCurrent={1}
                            current={page}
                            pageSize={10}
                            total={affiliatePageData?.total}
                            onChange={(value) => setPage(value)}
                            showSizeChanger={false}
                            style={{
                                // background:"#574f85",
                                background:
                                    "transparent linear-gradient(180deg, #5158de 0%, #613ade 48%, #711ade 100%) 0% 0% no-repeat padding-box",
                                color: "#613ade",
                                borderRadius: "5px",
                            }}
                        />
                    </div>
                )} 
            </>
        )}

            </div>
            }
        </>
    )
}

export default Record
