import React from 'react';
import styles from './Record.module.css';
import { Badge, Typography } from 'antd';
import { CommaSeperator, toFixedFunc } from '../Helper/HelperFunction';
import NoDataComp from '../NoData/NoDataComp';

const RecordsCard = ({ affiliateRecordData }) => {

    return (
        <>
            {affiliateRecordData?.length > 0 ? (
                <>
                    {affiliateRecordData?.map((record, index) => {
                        let date = new Date(record.created_at);
                        const formattedDate = date.toLocaleString();
                        const [datePart, timePart] = formattedDate.split(", ");
                        return (
                            <div className={styles.cardContainer}>
                                <div className={styles.cardDataWrapper}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography.Text className={styles.cardLabelStyle}>Registration Date:</Typography.Text>
                                        <Typography.Text className={styles.cardLabelDetail}>{datePart}</Typography.Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography.Text className={styles.cardLabelStyle}>Amount:</Typography.Text>
                                        <Typography.Text className={styles.cardLabelDetail}>₱ {CommaSeperator(toFixedFunc(record?.amount))}</Typography.Text>
                                    </div>

                                </div>

                                <div className={styles.cardDataWrapper}>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography.Text className={styles.cardLabelStyle}>Username:</Typography.Text>
                                        <Typography.Text className={styles.cardLabelDetail}>{record?.user_name}</Typography.Text>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography.Text className={styles.cardLabelStyle}>Invite Status:</Typography.Text>
                                        <div className={styles.badgeStyle} style={{ color: record?.bonus_status ? "#00FFB5" : "#BE8AFF", background: record?.bonus_status ? "#004430 0% 0% no-repeat padding-box" : "#14132D 0% 0% no-repeat padding-box", border: record?.bonus_status ? "1px solid #00FFB5" : "1px solid #BE8AFF" }}>{record?.bonus_status ? "Approved" : "Pending"}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            ) :
                <NoDataComp />
            }
        </>
    )
}

export default RecordsCard
