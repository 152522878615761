import { Typography } from "antd";
import LeftIcon from "../../Assets/Left.svg";
import RightIcon from "../../Assets/Right.svg";
import React from "react";

const CustomTitleTab = ({
  segmentId,
  parentDivStyle,
  leftContentStyle,
  rightContentStyle,
  image,
  imgStyle,
  leftText,
  leftTextStyle,
}) => {
  const handleLeftClick = () => {
    const scrollableContainerRef = document.getElementById(
      `scrollable-container-${segmentId}`
    );
    scrollableContainerRef.scrollTo({
      left: scrollableContainerRef.scrollLeft - 250,
      behavior: "smooth",
    });
  };

  const handleRightClick = () => {
    const scrollableContainerRef = document.getElementById(
      `scrollable-container-${segmentId}`
    );

    scrollableContainerRef.scrollTo({
      left: scrollableContainerRef.scrollLeft + 250,
      behavior: "smooth",
    });
  };
  return (
    <div className={parentDivStyle}>
      <div className={leftContentStyle}>
        <img src={image} className={imgStyle} alt="img" />
        <Typography.Text className={leftTextStyle}>{leftText}</Typography.Text>
      </div>
      <div className={rightContentStyle}>
        <img
          src={LeftIcon}
          className={imgStyle}
          alt="LeftIcon"
          onClick={handleLeftClick}
        />
        <img
          src={RightIcon}
          className={imgStyle}
          alt="RightIcon"
          onClick={handleRightClick}
        />
      </div>
    </div>
  );
};

export default CustomTitleTab;
