import React, { useEffect, useState } from "react";
import styles from "./Notificatoins.module.css";
import NotificationIcon from "../../Assets/NoNotification.svg";
import { Button, Divider, Typography } from "antd";
import { useSelector } from "react-redux";
import {
  getAllNotifications,
  putMarkAsReadNotifications,
} from "../../Api/NotificationsApi";
import Loader from "../Loader/Loader";

const Notifications = () => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const [loading, setLoading] = useState(false);
  const [notificationsInfo, setNotificationsInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [showViewMore, setShowViewMore] = useState(true);

  const putMarkAsReadNotificationsFunc = async (id) => {
    setLoading(true);
    const res = await putMarkAsReadNotifications(id);
    // if (res.true) {
    //   return res;
    // }
    setLoading(false);
  };

  const GetNotificationsFunc = async (id) => {
    setLoading(true);
    const res = await getAllNotifications(id, page);
    if (res?.data) {
      if (page === 1) {
        setNotificationsInfo(res.data.player_notifications);
      } else {
        setNotificationsInfo((prevNotifications) => [
          ...prevNotifications,
          ...res.data.player_notifications,
        ]);
      }
      if (res?.data?.player_notifications?.length < 10) {
        setShowViewMore(false);
      }
    } else {
      setNotificationsInfo([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetNotificationsFunc(userInfoReduxData?.id);
    putMarkAsReadNotificationsFunc(userInfoReduxData?.id);
  }, [page]);

  const handleViewMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className={styles.notificationCardContainer}>
          {notificationsInfo?.length > 0 ? (
            <>
              {notificationsInfo.map((item) => (
                <div key={item.id} className={styles.notificationCard}>
                  <div className={styles.titleWithTime}>
                    <Typography.Text className={styles.notificationTitleStyle}>
                      {item?.notification?.title}
                    </Typography.Text>
                    <Typography.Text className={styles.notificationTimeStyle}>
                      {timeAgoFromNow(item.notification?.created_at)}
                    </Typography.Text>
                  </div>

                  <Typography.Text className={styles.notificationDescStyle}>
                    {item?.notification?.description}
                  </Typography.Text>
                  <Divider
                    plain
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      borderColor: "#51497B ",
                    }}
                  />
                </div>
              ))}
              {showViewMore && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "20px 0",
                  }}
                >
                  <Button
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                      border: "1px solid #3B3F7B",
                      opacity: "1",
                    }}
                    type="primary"
                    onClick={handleViewMore}
                  >
                    View More
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className={styles.imgContainer}>
              <div className={styles.noImgStyle}>
                <img
                  className={styles.imgStyle}
                  src={NotificationIcon}
                  alt="notificationIcon"
                />
                <Typography.Text className={styles.noNotificationTitleStyle}>
                  No Notification
                </Typography.Text>
              </div>

              <Typography.Text className={styles.noNotificationDescStyle}>
                There are currently no new system messages.
              </Typography.Text>
              <Typography.Text className={styles.noNotificationDescStyle}>
                Please come back later!
              </Typography.Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;

function timeAgoFromNow(timestamp) {
  const time = new Date(timestamp);
  const timeDifference = new Date() - time;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Format the time difference
  if (days > 0) {
    return days === 1 ? "1 day ago" : days + " days ago";
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : hours + " hours ago";
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : minutes + " minutes ago";
  } else {
    return "Just now";
  }
}
