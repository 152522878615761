import { Modal, Typography } from 'antd';
import styles from './SocialShare.module.css';
import WhatsappIcon from '../../Assets/social share/logos_whatsapp-icon.png';
import FbIcon from '../../Assets/social share/logos_facebook.png';
import TelegramIcon from '../../Assets/social share/logos_telegram.png';
import InstaIcon from '../../Assets/social share/skill-icons_instagram.png';

const modalStyles = {
    mask: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
};


const SocialShare = ({ open = false, handleClose, link }) => {

    const shareOnWhatsApp = () => {
        const message = encodeURIComponent(`Check out this link: ${link}`);
        const whatsappUrl = `https://api.whatsapp.com/send?text=${message}`;
        window.open(whatsappUrl, '_blank');
    };

    const shareOnFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;
        window.open(facebookUrl, '_blank');
    };

    const shareOnInstagram = () => {
        // Instagram does not support direct link sharing through URL scheme.
        alert('Instagram does not support direct link sharing.');
    };

    const shareOnTelegram = () => {
        const message = encodeURIComponent(`Check out this link: ${link}`);
        const telegramUrl = `https://telegram.me/share/url?url=${encodeURIComponent(link)}&text=${message}`;
        window.open(telegramUrl, '_blank');
    };

    return (

        <Modal
            centered
            open={open}
            closable={false}
            footer={false}
            mask={true}
            styles={modalStyles}
        >
            <div className={styles.container}>
                <div className={styles.titleWrapper}>
                    <div><Typography.Text className={styles.titleText}>Share Via</Typography.Text></div>
                    <div style={{ width: 15, height: 15, cursor: 'pointer' }} onClick={() => handleClose(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                            <path d="M9.6211 8L14.5605 3.06055C14.8438 2.77733 15 2.4004 15 2C15 1.5996 14.8438 1.22267 14.5605 0.939453C14.2773 0.65624 13.9004 0.5 13.5 0.5C13.0996 0.5 12.7227 0.65624 12.4395 0.939453L7.5 5.87891L2.56055 0.939453C2.27733 0.65624 1.9004 0.5 1.5 0.5C1.0996 0.5 0.722666 0.65624 0.439453 0.939453C0.15624 1.22267 0 1.5996 0 2C0 2.4004 0.15624 2.77733 0.439453 3.06055L5.37891 8L0.439453 12.9395C0.15624 13.2226 2.23517e-07 13.5996 2.23517e-07 14C2.23517e-07 14.4004 0.15624 14.7773 0.439453 15.0605C0.723135 15.3438 1.0996 15.5 1.5 15.5C1.9004 15.5 2.27687 15.3438 2.56055 15.0605L7.5 10.1211L12.4395 15.0605C12.7231 15.3438 13.0996 15.5 13.5 15.5C13.9004 15.5 14.2769 15.3438 14.5605 15.0605C14.8438 14.7774 15 14.4004 15 14C15 13.5996 14.8438 13.2227 14.5605 12.9395L9.6211 8Z" fill="white" />
                        </svg>
                    </div>
                </div>
                <div className={styles.shareBody}>
                    <div className={styles.iconWrapper} onClick={shareOnTelegram}><img className={styles.iconStyle} src={TelegramIcon} alt='telegram-icon' /><Typography.Text className={styles.iconLabel}>Telegram</Typography.Text></div>
                    <div className={styles.iconWrapper} onClick={shareOnFacebook}><img className={styles.iconStyle} src={FbIcon} alt='fb-icon' /><Typography.Text className={styles.iconLabel}>Facebook</Typography.Text></div>
                    <div className={styles.iconWrapper} onClick={shareOnInstagram}><img className={styles.iconStyle} src={InstaIcon} alt='insta-icon' /><Typography.Text className={styles.iconLabel}>Instagram</Typography.Text></div>
                    <div className={styles.iconWrapper} onClick={shareOnWhatsApp}><img className={styles.iconStyle} src={WhatsappIcon} alt='whatsapp-icon' /><Typography.Text className={styles.iconLabel}>Whatsapp</Typography.Text></div>
                </div>

            </div>
        </Modal>
    )
}

export default SocialShare
