import React from "react";
import styles from "./PaymentMethod.module.css";
import { Flex, Input, Tooltip, Typography, Space } from "antd";
import Loader from "../HelperComponents/Loader";

const PaymentMethod = (props) => {
  const {
    userBankMethods,
    handleSelectCategory,
    selectedMethod,
    selectedIcon,
    methodLoading,
    loading,
    addWallet,
    addEWallet,
  } = props;
  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        style={{
          width: "100%",
        }}
      >
        <div className={styles.cardWrapper}>
          <div className={styles.stepHolder}>
            <Typography.Text className={styles.stepText}>
              Step 2: Choose Payment Method
            </Typography.Text>
          </div>
          <div className={styles.paymentMethodWrapper}>
            <div className={styles.bankmethodMainContainer}>
              <Typography.Text className={styles.paymentTextStyle}>
                Payment Account
              </Typography.Text>
              <div className={styles.bankMethodsContainer}>
                {userBankMethods && userBankMethods.length ? (
                  userBankMethods?.map((method) => {
                    return (
                      <div
                        onClick={(e) => handleSelectCategory(e, method)}
                        className={styles.gCashModal}
                        style={{
                          border:
                            selectedMethod.id === method.id
                              ? "2px solid #FFFFFF"
                              : "",
                        }}
                        key={method?.id}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography.Text className={styles.holderNameStyle}>
                            {method?.bank_code?.public_name?.en_public_name}
                          </Typography.Text>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Typography.Text
                            className={styles.holderNameStyle}
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {method?.account_name}
                          </Typography.Text>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Tooltip
                            title={
                              method?.account_number.length > 22
                                ? method?.account_number
                                : ""
                            }
                          >
                            <Typography.Text
                              className={styles.holderNameStyle}
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {method?.account_number}
                            </Typography.Text>
                          </Tooltip>
                        </div>
                        {selectedMethod?.id === method?.id ? (
                          <div
                            style={{
                              width: "40px",
                              height: "40px",
                              position: "absolute",
                              right: "0px",
                              bottom: "0px",
                            }}
                          >
                            <img
                              src={selectedIcon}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              alt="selectedIcon"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
                {methodLoading ? (
                  <Loader />
                ) : (
                  <div
                    className={`${styles.addWalletContainer} ${
                      userBankMethods && userBankMethods?.length < 3 && !loading
                        ? styles.buttonActive
                        : styles.disabled
                    }`}
                    onClick={addWallet}
                  >
                    <div
                      style={{
                        display: "flex",
                        maxWidth: 20,
                        maxHeight: 20,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={addEWallet}
                        className="imgStyle"
                        alt="addEwalletIcon"
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <Typography.Text className={styles.addeWalletTextStyling}>
                        Add Payment Method
                      </Typography.Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Space>
    </>
  );
};

export default PaymentMethod;
