import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import Banner1 from "../../Assets/home/carousel/Banner 1.webp";
import Banner2 from "../../Assets/home/carousel/Banner 2.webp";
import PageBottom from "../Layout/PageBottom/PageBottom";
import { useQuery } from "react-query";
import {
  getGameCategories,
  getGameProviders,
  getCatergoriesWithGames,
} from "../../Api/GamesApi";
import "../../Fonts/fonts.css";
import Categories from "../HomeComponents/Categories";
import Carousel from "../HomeComponents/Carousel";
import MarqueeText from "../HomeComponents/MarqueeText";
import Content from "../HomeComponents/Content";
import AgeVerificationPopup from "../HomeComponents/components/AgeVerificationPopup";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import clientConfig from "../../config.json";

const Home = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [activeCatId, setActiveCatId] = useState(null);
  const [activeCat, setActiveCat] = useState(0);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [multipleSelectedPlatforms, setMulitpleSelectedPlatforms] = useState(
    []
  );
  const [allAvailableGames, setAllAvailableGames] = useState([]);
  const [games, setGames] = useState(null);
  const [platforms, setPlatforms] = useState(null);
  const [platformLoading, setPlatformLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [homeAllSegmentData, setHomeAllSegmentData] = useState([]);
  const [gameCategories, setGameCategories] = useState();
  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const banners = [Banner1, Banner2];
  const [updateAllPlatForm, setUpdateAllPlatForm] = useState();
  const [updateSearchData, setUpdateSearchData] = useState();
  // const clientConfig = useSelector((state) => state.client.clientConfig);
  const token = localStorage.getItem("auth_token");
  const { data, isLoading, error } = useQuery(["categories", token], () =>
    getGameCategories(clientConfig.currencyBackendCode)
  );
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("AgeVerification");
    const timer = setTimeout(() => {
      if (!hasSeenPopup) {
        setShowPopup(true);
      }
    }, 3005);

    return () => clearTimeout(timer);
  }, []);

  const hideModal = () => {
    setShowPopup(false);
    localStorage.setItem("AgeVerification", "true");
  };

  useEffect(() => {
    if (!token) {
      let currency_id =
        localStorage.getItem("currency_id") ?? clientConfig.currencyBackendCode;
      getGameCategories(currency_id);
    }
  }, [token]);

  useEffect(() => {
    setShowLoader(true);
    if (data?.length > 0) {
      setActiveCatId(data[0]);
      setGameCategories([...data]);
      setShowLoader(false);
      setPlatformLoading(true);
    } else {
      setGameCategories([]);
      setErrorMsg("No Category Found !");
    }
  }, [data]);

  const GameProvidersFunc = async (
    activeCategoryId,
    selectedSubCat,
    searchGames
  ) => {
    setPlatformLoading(true);
    let currency_id =
      localStorage.getItem("currency_id") ?? clientConfig.currencyBackendCode;
    const activeCatGames = await getCatergoriesWithGames(
      activeCategoryId,
      currency_id,
      page,
      selectedSubCat,
      searchGames
    );

    setActiveCat(activeCatGames?.category);
    setAllAvailableGames(activeCatGames?.gameItems);

    if (games) {
      setGames(activeCatGames?.gameItems?.data);
    }

    if (activeCatGames?.category?.child_categories?.length > 0) {
      setPlatforms();
    } else if (activeCatGames?.category?.is_lobby) {
      setPlatforms();
      setSelectedPlatforms([]);
    }

    setPlatformLoading(false);
  };

  useEffect(() => {
    if (activeCatId) {
      if (!activeCatId?.is_lobby) {
        GameSubProvidersFunc(activeCatId?.id);
      }
    }
  }, [activeCatId, updateAllPlatForm]);

  const GameSubProvidersFunc = async (activeCategoryId) => {
    // setShowLoader(true);
    let currency_id =
      localStorage.getItem("currency_id") ?? clientConfig.currencyBackendCode;
    const platformResponse = await getGameProviders(
      activeCategoryId,
      currency_id
    );

    if (platformResponse.length) {
      setPlatforms(platformResponse);
    } else {
      setPlatforms();
    }
    // setShowLoader(false);
  };

  // This Function for Used Child Category(Popular sections Games)
  useEffect(() => {
    if (activeCat) {
      if (activeCat?.child_categories?.length) {
        const mappedData = activeCat?.child_categories.map((category) => ({
          id: category.id,
          segmentTitle: JSON.parse(category?.name)?.en,
          segmentIcon: category.icon_active,
          games: category.game_items,
        }));
        setHomeAllSegmentData(mappedData);
      }
    }
  }, [activeCat]);

  // This Function is used for Game Provider and Game Category Details api Call
  useEffect(() => {
    if (activeCatId) {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    }
  }, [activeCatId]);

  useEffect(() => {
    if (activeCatId && selectedPlatforms?.length > 0) {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    } else {
      // GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    }
  }, [selectedPlatforms]);

  useEffect(() => {
    if (activeCatId && searchData !== "") {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    } else if (activeCatId && searchData === "") {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    }
  }, [updateSearchData]);

  useEffect(() => {
    if (activeCatId) {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    }
  }, [updateAllPlatForm, page]);

  const HandleSearchButton = async () => {
    setGames([]);
    setPage(1);
    setUpdateSearchData(searchData);
  };

  return (
    <>
      <div className={styles.container}>
        {showPopup && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex",
            }}
          >
            <AgeVerificationPopup
              message="Hello"
              show={showPopup}
              hideModal={hideModal}
            />
          </div>
        )}
        <Carousel banners={banners} />
        <MarqueeText />
        <Categories
          errorMsg={errorMsg}
          isLoading={isLoading}
          gameCategories={gameCategories}
          activeCatId={activeCatId}
          setGames={setGames}
          setPlatforms={setPlatforms}
          setActiveCatId={setActiveCatId}
          setPage={setPage}
          setSelectedPlatforms={setSelectedPlatforms}
          setSearchData={setSearchData}
        />
        <Content
          platformLoading={platformLoading}
          platforms={platforms}
          selectedPlatforms={selectedPlatforms}
          setSelectedPlatforms={setSelectedPlatforms}
          activeCat={activeCat}
          homeAllSegmentData={homeAllSegmentData}
          games={games}
          allAvailableGames={allAvailableGames}
          setGames={setGames}
          showLoader={showLoader}
          multipleSelectedPlatforms={multipleSelectedPlatforms}
          setMulitpleSelectedPlatforms={setMulitpleSelectedPlatforms}
          page={page}
          setPage={setPage}
          setSearchData={setSearchData}
          setUpdateAllPlatForm={setUpdateAllPlatForm}
          HandleSearchButton={HandleSearchButton}
        />
        <PageBottom />
      </div>
    </>
  );
};

export default Home;
