import React, { useEffect, useState } from "react";
import styles from "./Withdraw.module.css";
import { Flex, Input, Table, Tooltip, Typography } from "antd";
import gCashlogo from "../../Assets/payment icons/G.png";
import gcash from "../../Assets/payment icons/GCash.png";
import balanceIcon from "../../Assets/payment icons/Balance.png";
import InputField from "../HelperComponents/InputField";
import CustomButton from "../HelperComponents/CustomButton";
import selectedIcon from "../../Assets/payment icons/Selected Method.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  WithdrawAPI,
  getCheckTurnover,
  getWithdrawPaymentMethodApi,
  withdrawAllowed,
} from "../../Api/TransactionApi";
import useLogout from "../Layout/Header/useLogout";
import { bankListAPI } from "../../Api/BankApi";
import { setActiveBanks } from "../../Redux/Slice/BankSlice";
import { useTranslation } from "react-i18next";
import { setWithdrawAmount } from "../../Redux/Slice/WithdrawSlice";
import {
  CommaSeperator,
  handleKeyPress,
  toFixedFunc,
} from "../Helper/HelperFunction";
import LoginPopupModal from "../Login/LoginPopupModal";
import addEWallet from "../../Assets/payment icons/Add E-Wallet.png";
import Loader from "../HelperComponents/Loader";
import NoDataFoundIcon from "../../Assets/No Data Icon.png";
import { MdHorizontalRule } from "react-icons/md";
import NoDataComp from "../NoData/NoDataComp";
import { currencyHelper } from "../Helper/currencyHelper";
import WithdrawInput from "../WithdawComponents/WithdrawInput";
import PaymentMethod from "../WithdawComponents/PaymentMethod";
import { WithdrawStatusAPI } from "../../Api/Apis";
import OtpPopupModal from "../OtpVerificationPopup/OtpPopupModal";

const Withdraw = () => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const withdrawamountReduxData = useSelector(
    (state) => state?.withdraw?.withdrawamount
  );
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [userBankMethods, setUserBankMethods] = useState(null);
  const [withdrawMinAmount, setWithdrawMinAmount] = useState(100);
  const [withdrawMaxAmount, setWithdrawMaxAmount] = useState(50000);
  const [amount, setAmount] = useState(withdrawamountReduxData || "");
  // const [isWithdrawLoader, setIsWithdrawLoader] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(true);
  const [loading, setLoading] = useState(false);
  const [methodLoading, setMethodLoading] = useState(false);

  const [popupError, setPopupError] = useState("");
  const [turnOverData, setTurnOverData] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = useLogout();
  const [checkAvailableWallet, setCheckAvailableWallet] = useState(false);
  const [isFirstWithdraw, setIsFirstWithdraw] = useState(false);
  const [noAmountError, setNoAmountError] = useState(false);
  const [finalSucess, setFinalSuccess] = useState(false);
  const [phone, setPhone] = useState();
  const [otpPopupShow, setOtpPopupShow] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);

  const inputBox = {
    maxWidth: "521px",
    width: "100%",
    height: "50px",
    background: "#000326 0% 0% no-repeat padding-box",
    border: "1px solid #3C3562",
    borderRadius: "7px",
    opacity: 1,
    textAlign: "left",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: "Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
  };

  const handleInputChange = (name, value) => {
    if (userBankMethods?.length === 0) {
      setCheckAvailableWallet(true);
    }
    setAmount(value);
    dispatch(setWithdrawAmount(value));
  };

  // check withdraw Allow API
  const checkWithdrawAllow = async () => {
    // setIsWithdrawLoader(true);
    const res = await withdrawAllowed();
    if (res?.status === 200) {
      if (res?.data?.message === "PLAYER_NOT_ALLOWED_TO_WITHDRAW") {
        setPopupError("withdrawFeatureLock");
      } else {
        setIsWithdraw(res.data.status);
      }
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else if (res?.response?.status === 401) {
      logout();
    }

    // setIsWithdrawLoader(false);
  };
  const getWithdrawPaymentMethodFunc = async () => {
    setMethodLoading(true);
    const res = await getWithdrawPaymentMethodApi();
    if (res.status === 200) {
      setUserBankMethods(res.data);
      setSelectedMethod(res.data[0]);
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    }
    setMethodLoading(false);
  };

  // check withdraw allowed or not, active step check
  useEffect(() => {
    // bankList();
    getWithdrawPaymentMethodFunc();
    checkWithdrawAllow();

    return () => {
      // setActiveStep(0);
      // dispatch(setWithdrawAmount(""));
    };
  }, []);
  useEffect(() => {
    if (selectedMethod) {
      selectedMethod?.min_deposit_amount &&
        setWithdrawMinAmount(selectedMethod?.min_deposit_amount);
      selectedMethod?.max_deposit_amount &&
        setWithdrawMaxAmount(selectedMethod?.max_deposit_amount);
    }
  }, [selectedMethod]);

  const addWallet = () => {
    userBankMethods?.length < 3 && !loading && navigate("/add-payment-method");
  };

  const submitWithdraw = async () => {
    if (!checkValidation()) {
      setLoading(true);
      const res = await WithdrawAPI(
        selectedMethod?.id,
        amount || withdrawamountReduxData
      );
      if (res.status === 200) {
        setLoading(false);
        // dispatch(setWithdrawAmount(""));
        navigate("/account/transactions", { state: { active: "withdraw" } });
      } else if (res?.response?.status === 401) {
        logout();
      } else if (res?.response?.data?.message === "NOT ELIGIBLE FOR WITHDRAW") {
        setPopupError(t("notEligibleWithdraw"));
      } else if (res?.response?.data?.message === "INVALID_AMOUNT") {
        setPopupError(
          `Enter a valid amount between ${CommaSeperator(
            toFixedFunc(withdrawMinAmount)
          )} - ${CommaSeperator(
            toFixedFunc(withdrawMaxAmount)
          )} to make withdraw request.`
        );
      } else if (res?.response?.status === 400) {
        if (res?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        }
      } else if (
        res?.response?.data?.message === "FINANCE_VERIFICATION_FAILED"
      ) {
        navigate("/account/transactions", { state: { active: "withdraw" } });
      } else if (
        res?.response?.data?.message === "PLAYER_NOT_ALLOWED_TO_WITHDRAW"
      ) {
        // setErrorModal(true);
        setPopupError("temporaryLockWithdraw");
      } else if (res?.response?.data?.message === "INSUFFICIENT_BALANCE") {
        // setErrorModal(true);
        setPopupError(t("notEnoughBalanceErr"));
      } else if (res?.response?.status === 403) {
        if (res?.response?.data?.message === "NOT ELIGIBLE FOR WITHDRAW") {
          setPopupError(t("notEligibleWithdraw"));
        } else {
          setPopupError("pendingreqerror");
          // setErrorModal(true);
        }
      } else if (res?.response?.data?.message === "Account Not Activated") {
        setPopupError(t("depositFirstErrMessage"));
      } else if (
        res?.response?.data?.message === "WITHDRAW_AMOUNT_BELOW_MINIMUM"
      ) {
        // setErrorModal(true);
        setPopupError(
          `${t("minWidthdrawAmmErrPart1")} ${withdrawMinAmount} ${t(
            "minWidthdrawAmmErrPart1"
          )}`
        );
      } else if (res?.response?.status === 422) {
        setPopupError("temporaryLockWithdraw");
      } else {
        setPopupError(t("errormessage"));
      }
      dispatch(setWithdrawAmount(""));
    }
  };
  const handleSelectCategory = (e, method) => {
    setSelectedMethod(method);
  };
  const checkValidation = () => {
    if (
      !(Number(amount) || Number(withdrawamountReduxData)) ||
      (Number(amount) || Number(withdrawamountReduxData)) >
        userInfoReduxData?.balance ||
      (Number(amount) || Number(withdrawamountReduxData)) < withdrawMinAmount ||
      (Number(amount) || Number(withdrawamountReduxData)) > withdrawMaxAmount ||
      amount.includes(".") ||
      !isWithdraw ||
      !selectedMethod ||
      turnOverData?.remainingTurnOver > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const closePopup = () => {
    // navigate("/");
    setPopupError("");
    setLoading(false); // Reset loading state when closing the popup
    navigate("/", { state: { active: "withdraw" } });
  };

  const closeOtpPopup = () => {
    setOtpPopupShow(false);
  };

  const checkStatus = async () => {
    setLoading(true);
    const res = await WithdrawStatusAPI();
    // console.log(res);
    setLoading(false);
    if (res.status === 200) {
      setIsFirstWithdraw(res?.data?.is_first_withdraw);
      setIsUserVerified(res?.data?.is_contact_verified);
    }
  };

  useEffect(() => {
    if (userInfoReduxData) {
      checkStatus();
      // setPhone(userInfoReduxData?.phone);
      const phoneNo = userInfoReduxData?.phone;
      const removeCountryCode = phoneNo
        ? phoneNo?.startsWith("+")
          ? phoneNo?.slice(3)
          : phoneNo
        : "";
      setPhone(removeCountryCode);
    }
  }, [userInfoReduxData]);

  useEffect(() => {
    if (finalSucess) {
      submitWithdraw();
      setNoAmountError(!amount ? true : false);
      setFinalSuccess(false);
      checkStatus();
    }
  }, [finalSucess]);

  useEffect(() => {
    const getCheckTurnoverDataFunc = async () => {
      try {
        const res = await getCheckTurnover();
        if (res?.data) {
          setTurnOverData(res?.data);
        }
      } catch (error) {
        console.error("Error fetching turnover data:", error);
      }
    };

    getCheckTurnoverDataFunc();
  }, []);

  const columns = [
    {
      title: "Total Turnover Required",
      dataIndex: "totalDepositSum",
      width: "50%",
      render: (text, record) => (
        <div className={styles.tableBodyData} style={{ color: "#FFFFFF" }}>
          {CommaSeperator(text)}
        </div>
      ),
    },
    {
      title: "Remaining Turnover Required",
      dataIndex: "remainingTurnOver",
      width: "50%",
      render: (text, record) => (
        <div
          style={{
            color:
              record?.totalDepositSum !== record?.totalTurnoverSum
                ? "red"
                : "white",
          }}
          className={styles.tableBodyData}
        >
          {CommaSeperator(text)}
          {/* {} */}
        </div>
      ),
    },
  ];

  return (
    <div className={`${styles.wrapper} withdraw-wrapper`}>
      <div className={styles.container}>
        <div className={styles.flexDiv}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography.Text className={styles.balanceTextStyling}>
              Balance:
            </Typography.Text>
            <div className={styles.balanceIconStyle}>
              <img src={balanceIcon} className="imgStyle" alt="balanceIcon" />
            </div>
            <div className={styles.balanceContainer}>
              <Typography.Text className={styles.balanceStyling}>
                {currencyHelper(userInfoReduxData?.currency)}
                {userInfoReduxData?.balance &&
                  CommaSeperator(toFixedFunc(userInfoReduxData?.balance))}
              </Typography.Text>
            </div>
          </div>
        </div>
        {turnOverData && turnOverData.remainingTurnOver !== 0 && (
          <div className={styles.turnoverContainer}>
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
              <div className={styles.turnoverTitle}>
                Withdrawal turnover requirements
              </div>
              {turnOverData && turnOverData.remainingTurnOver > 0 && (
                <div className={styles.turnoverError}>
                  Please complete the required turnover for withdrawal
                </div>
              )}
            </div>

            <div className={`${styles.turnovertable} withdraw-turnover-table`}>
              <Table
                columns={columns}
                dataSource={
                  Array.isArray(turnOverData) ? turnOverData : [turnOverData]
                }
                size="middle"
                pagination={false}
              />
            </div>
          </div>
        )}
        <div className={styles.columnDiv}>
          <WithdrawInput
            inputBox={inputBox}
            amount={amount}
            userInfoReduxData={userInfoReduxData}
            withdrawamountReduxData={withdrawamountReduxData}
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            withdrawMinAmount={withdrawMinAmount}
            withdrawMaxAmount={withdrawMaxAmount}
          />
          <PaymentMethod
            userBankMethods={userBankMethods}
            handleSelectCategory={handleSelectCategory}
            selectedMethod={selectedMethod}
            selectedIcon={selectedIcon}
            methodLoading={methodLoading}
            loading={loading}
            addWallet={addWallet}
            addEWallet={addEWallet}
          />

          <div>
            {checkAvailableWallet && userBankMethods?.length === 0 && (
              <p
                style={{
                  color: "#ff5746",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No wallet selected. Please add a wallet to continue.
              </p>
            )}
          </div>

          <div style={{ display: "flex" }}>
            {loading ? (
              <Loader />
            ) : (
              <CustomButton
                text="Withdraw"
                className={`${styles.nextBtn} 
            ${
              checkValidation() ? styles.disabled : styles.withdrawButtonActive
            } 
                ${styles.withdrawButtonStyle}
                `}
                // onClick={submitWithdraw}
                // onClick={() => {
                //   if (isFirstWithdraw) {
                //     setOtpPopupShow(true);
                //     const phoneNo = userInfoReduxData?.phone;
                //     const removeCountryCode = phoneNo
                //       ? phoneNo?.startsWith("+")
                //         ? phoneNo?.slice(3)
                //         : phoneNo
                //       : "";
                //     setPhone(removeCountryCode);
                //   } else {
                //     submitWithdraw();
                //     setNoAmountError(!amount ? true : false);
                //   }
                // }}
                onClick={(e) => {
                  submitWithdraw();
                  setNoAmountError(!amount ? true : false);
                  // if (isFirstWithdraw) {
                  //   if (!isUserVerified) {
                  //     setOtpPopupShow(true);
                  //     const phoneNo = userInfoReduxData?.phone;
                  //     const removeCountryCode = phoneNo
                  //       ? phoneNo?.startsWith("+")
                  //         ? phoneNo?.slice(3)
                  //         : phoneNo
                  //       : "";
                  //     setPhone(removeCountryCode);
                  //   } else {
                  //     submitWithdraw();
                  //     setNoAmountError(!amount ? true : false);
                  //   }
                  // } else {
                  //   submitWithdraw();
                  //   setNoAmountError(!amount ? true : false);
                  // }
                }}
                disabled={checkValidation()}
              />
            )}
          </div>
        </div>
      </div>
      {popupError && (
        <LoginPopupModal
          message={t(popupError)}
          show={popupError ? true : false}
          errorIcon={popupError ? true : false}
          hideModal={() => {
            setPopupError("");
            setLoading(false);
            if (popupError === "withdrawFeatureLock") {
              navigate("/support");
            } else if (popupError === "temporaryLockWithdraw") {
              navigate("/support");
            } else if (popupError === "pendingreqerror") {
              navigate("/account/transactions", {
                state: { active: "withdraw" },
              });
            } else {
              navigate("/");
            }
          }}
        />
      )}
      {otpPopupShow && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OtpPopupModal
            otpPopupShow={otpPopupShow}
            show={otpPopupShow ? true : false}
            hideModal={closeOtpPopup}
            phone={phone}
            setPhone={setPhone}
            setFinalSuccess={setFinalSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default Withdraw;
