import { api } from "./axios";

export const promotionsAPI = async (country) => {
    try {
        const res = await api.get(`/player/promotions?country=${country}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`
            }
        })
if (res?.data) {
    return res.data
}
return res
    } catch (error) {
    console.log(error);
}
return null
  }