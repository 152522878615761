import React from "react";
import styles from "./PaymentCategories.module.css";
import Loader from "../Loader/Loader";
import selectedIcon from "../../Assets/payment icons/Selected Method.png";
import NoDataComp from "../NoData/NoDataComp";
import { Typography, Card, Badge, Space } from "antd";

const PaymentMethod = (props) => {
  const {
    methodLoading,
    // paymentMethods,
    selectedBank,
    bankChannels,
    // paymentChannels,
    selectedMethod,
    handleSelectMethod,
  } = props;
  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        style={{
          width: "100%",
        }}
      >
        <div className={styles.cardWrapper} size={"small"}>
          <div className={styles.stepHolder}>
            <Typography.Text className={styles.stepText}>
              Step 2: Choose Payment Channel
            </Typography.Text>
          </div>

          <div className={styles.paymentMainContainer}>
            <Typography.Text className={styles.paymentTextStyle}>
              Payment Channel
            </Typography.Text>
            <div className={styles.rowPaymentMethods}>
              {methodLoading ? (
                <Loader />
              ) : bankChannels?.length > 0 && bankChannels ? (
                bankChannels?.map((method) => {
                  return (
                    <>
                      {method?.bank_code_id === selectedBank?.id && (
                        <div
                          className={styles.goCashInactive}
                          style={{
                            border:
                              selectedMethod?.id === method?.id
                                ? "2px solid #FFFFFF"
                                : "",
                          }}
                          key={method?.id}
                          onClick={(e) => handleSelectMethod(e, method)}
                        >
                          {/* <div style={{ width: "36px", height: "30px" }}>
                            <img
                              src={method.bank_code?.full_image}
                              className={styles.gcashtyling}
                              alt="iconImg"
                            />
                          </div> */}
                          <span
                            className={styles.paymentMethodsText}
                            style={{
                              color: "white",
                              textTransform: "uppercase",
                            }}
                          >
                            {method?.public_name?.en_public_name}
                          </span>

                          {selectedMethod?.id === method?.id ? (
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                position: "absolute",
                                right: "0px",
                                bottom: "0px",
                                objectFit: "contain",
                              }}
                            >
                              <img
                                src={selectedIcon}
                                className="imgStyle"
                                alt="selectedIcon"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </>
                  );
                })
              ) : (
                <NoDataComp />
              )}
            </div>
          </div>
        </div>
      </Space>
    </>
  );
};

export default PaymentMethod;
