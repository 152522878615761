// import { Spin } from 'antd'
// import React from 'react'

// const Loader = () => {
//   return (
//     <div style={{width:'100%',
//     display:'flex',
//     justifyContent:"center",
//     alignItems:"center" ,
//     padding:"2px 0"}}>
//           <Spin size="large" />
//     </div>
//   )
// }

// export default Loader

import React from "react";

const Loader = ({ width = "30px", height = "30px" }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{ width: width, height: height }}
        className="animate-spin"
        src="https://www.svgrepo.com/show/199956/loading-loader.svg"
        alt="Loading icon"
      />
    </div>
  );
};

export default Loader;
