import React, { useEffect, useState } from "react";
import CustomButton from "../../HelperComponents/CustomButton";
import styles from "./AppHeader.module.css";
// import Logo from "../../../Assets/BESTWAY9 Logo.png";
import Logo from "../../../Assets/bw9Logo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { use } from "i18next";
import { useSelector } from "react-redux";
import LogoutConfirmationModal from "./LogoutConfirmationModal";
import sideMenuIcon from "../../../Assets/Side Menu Icon.png";
import ActiveAccountIcon from "../../../Assets/footer/Active Account.png";
import MenuLogo from "../../SideMenu/MenuLogo";
import BackButton from "../../../Assets/Back.png";
import GameLogo from "../../../Assets/Game Logo.png";
import { CommaSeperator, toFixedFunc } from "../../Helper/HelperFunction";
import balanceIcon from "../../../Assets/payment icons/Balance.png";
import exitIcon from "../../../Assets/Exit.png";
import NotificationIcon from "../../../Assets/NoNotificationIcon.svg";
import { Badge, Typography, notification } from "antd";
import { getNotificationCount } from "../../../Api/NotificationsApi";
import { currencyHelper } from "../../Helper/currencyHelper";
import config from "../../../config.json";

const AppHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const userToken = useSelector((state) => state.user.user);
  const [logoutModal, setLogoutModal] = useState(false);
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [notificationCount, setNotificationsCount] = useState(0);
  const segments = pathname.split("/");
  const lastSegment = segments[segments.length - 1];
  const title = lastSegment
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const promotionTitle = location.state?.promotionTitle;

  const GetNotificationsCountFunc = async (id) => {
    const res = await getNotificationCount(id);
    if (res) {
      setNotificationsCount(res?.count);
    } else {
      setNotificationsCount(0);
    }
  };
  useEffect(() => {
    if (userInfoReduxData) {
      GetNotificationsCountFunc(userInfoReduxData.id);
    }
  }, [userInfoReduxData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div
      style={{
        height: "63px",
        maxWidth: "561px",
        width: "100%",
        flexShrink: 0,
        background:
          pathname === "/login" || pathname === "/register"
            ? "transparent"
            : "#211D39",
        top: 0,
        zIndex: 1000,
        position: "fixed",
        display: "flex",
      }}
      className="main-header"
    >
      {pathname.includes("games") ||
      pathname === "/login" ||
      pathname === "/register" ||
      pathname === "/promotions" ||
      pathname === "/account/ewallet" ||
      pathname === "/account/transactions" ||
      pathname === "/account/betting-records" ||
      pathname === "/add-payment-method" ||
      pathname === "/support" ||
      pathname === "/deposit" ||
      pathname === "/withdraw" ||
      pathname === "/account" ||
      pathname === "/notifications" ||
      pathname === "/about-us" ||
      pathname === "/responsible-gaming" ||
      pathname === "/privacy-policy" ||
      pathname === "/terms-and-conditions" ||
      pathname.includes("/promotions/" + title) ||
      pathname.includes("/transections/" + title) ? (
        <>
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              height: "100%",
              padding: "0 20px",
            }}
          >
            <div style={{ width: "16px", height: "26px" }}>
              <img
                src={BackButton}
                style={{ cursor: "pointer" }}
                className="imgStyle"
                onClick={() => (userToken ? navigate(-1) : navigate("/"))}
                alt="back button"
              />
            </div>
            <div
              style={{
                margin: "auto",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fonSeight: "normal",
                  fontSize: "24px",
                  lineHeight: "29px",
                  fontFamily: "Roboto",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                }}
              >
                {pathname.includes("/promotions/" + title)
                  ? promotionTitle
                  : pathname.includes("/transections/" + title)
                  ? "Transaction"
                  : pathname.includes("/login") ||
                    pathname.includes("/register")
                  ? ""
                  : title}
              </span>
              {/* <span style={{
                color: 'white'
              }}>{pathname === '/login' || pathname === '/register' ? '' : title}</span> */}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.balanceMainHeader}>
          <div className={styles.imagesWrapper}>
            <MenuLogo
              drawerOpened={drawerOpened}
              setDrawerOpened={setDrawerOpened}
            />
            <img
              src={sideMenuIcon}
              className={styles.sideMenuIconStyle}
              alt="sideMenu"
              onClick={() => setDrawerOpened(true)}
            />
            <img
              onClick={() => navigate("/")}
              src={Logo}
              alt="logo"
              className={styles.logo}
            />
          </div>
          {userToken ? (
            <div className={styles.balanceExitContainer}>
              <div
                className={styles.notificationStyle}
                style={{ marginTop: 5 }}
              >
                <Badge
                  count={notificationCount}
                  style={{
                    backgroundColor: "#5158DE",
                    color: "white",
                    boxShadow: "0 0 0 1px #5158DE inset",
                  }}
                >
                  <img
                    src={NotificationIcon}
                    style={{ cursor: "pointer" }}
                    className="imgStyle"
                    alt="notification"
                    onClick={() => navigate("/notifications")}
                  />
                </Badge>
              </div>
              <Link style={{ textDecoration: "none" }} to="/account">
                <div className={styles.balanceCoinsContainer}>
                  <div className={styles.coinsStyle}>
                    <img src={balanceIcon} className="imgStyle" alt="balance" />
                  </div>
                  <div className={styles.balanceTextContainer}>
                    <Typography.Text className={styles.balanceText}>
                      {currencyHelper(userInfoReduxData?.currency)}
                    </Typography.Text>
                    <Typography.Text className={styles.balanceText}>
                      {userInfoReduxData?.balance &&
                        CommaSeperator(toFixedFunc(userInfoReduxData?.balance))}
                    </Typography.Text>
                  </div>
                </div>
              </Link>
              <div className={styles.exitIconStyle} style={{ marginTop: 5 }}>
                <img
                  src={exitIcon}
                  style={{ cursor: "pointer" }}
                  className="imgStyle"
                  alt="exit"
                  onClick={() => setLogoutModal(true)}
                />
              </div>
            </div>
          ) : (
            <div className={styles.buttonsContainer}>
              <Link to="/login">
                <CustomButton text="Login" type={styles.appHeaderLoginBtn} />
              </Link>
              <Link to="/register">
                <CustomButton text="Register" type={styles.appHeaderSinupBtn} />
              </Link>
            </div>
          )}
        </div>
      )}
      {logoutModal && (
        <LogoutConfirmationModal
          logoutModal={logoutModal}
          closeLogoutModal={() => setLogoutModal(false)}
        />
      )}
    </div>
  );
};

export default AppHeader;
