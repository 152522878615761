import React from "react";
import styles from "./CustomSearchDropDown.module.css";
import { useState, useEffect } from "react";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Card, Checkbox } from "antd";
import FilterIcon from "../../../Assets/Filter-Assets/Filter.svg";

const CheckboxGroup = Checkbox.Group;

const CustomSearchDropDown = (props) => {
  const {
    platforms,
    selectedPlatforms,
    setSelectedPlatforms,
    setSearchData,
    setGames,
    setUpdateAllPlatForm,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [platformList, setPlatformList] = useState([]);
  const [checkList, setCheckList] = useState([]);

  const handleCheckAll = () => {
    const allPlatformId = platforms.map((platform) => platform.id);
    setCheckList(allPlatformId);
    setSelectedPlatforms([...allPlatformId]);
  };
  const handleclearAll = () => {
    setCheckList([]);
    setSelectedPlatforms([]);
    setUpdateAllPlatForm(platforms);
  };

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOnChange = (list) => {
    setGames([]);
    setSearchData("");
    setSelectedPlatforms([...list]);
    // setCheckList(list);
    if (list?.length === 0) {
      handleclearAll();
    }
  };

  useEffect(() => {
    setPlatformList(
      platforms.map((platform) => ({
        value: platform.id,
        label: (
          <div className={styles.platformList}>
            <img
              src={platform.icon_image}
              alt={platform.platform_code}
              className={styles.platformIcon}
            />
            {platform.name}
          </div>
        ),
      }))
    );
    setCheckList(selectedPlatforms);
  }, []);

  useEffect(() => {
    setCheckList(selectedPlatforms);
  }, [selectedPlatforms]);

  const menu = (
    <Card size={"small"} className={styles.cardWrapper}>
      <div className={styles.platformControllersHolder}>
        <span>Game Providers ({platforms.length})</span>
        <div className={styles.platformControllers}>
          <span onClick={handleCheckAll}>All</span>
          <span onClick={handleclearAll}>Clear</span>
        </div>
      </div>
      <div className={styles.platformContainer}>
        {/* {platforms.map((platform) => (
          <Checkbox>
            <div className={styles.platformList}>
              <img
                src={platform.icon_image}
                alt={platform.platform_code}
                className={styles.platformIcon}
              />
              {platform.name}
            </div>
          </Checkbox>
        ))} */}
        <CheckboxGroup
          options={platformList}
          value={checkList}
          onChange={handleOnChange}
        />
      </div>
    </Card>
  );

  return (
    <>
      <Dropdown
        dropdownRender={() => menu}
        placement="bottomRight"
        // open={isOpen}
      >
        <Button className={styles.dropDownButton}>
          <img src={FilterIcon} alt={`FilterIcon`} />
        </Button>
      </Dropdown>
    </>
  );
};

export default CustomSearchDropDown;
