import { createSlice } from "@reduxjs/toolkit";
export const BankSlice = createSlice({
    name: "bank",
    initialState: {
        activeBanks: null,
    },
    reducers: {
        setActiveBanks: (state, action) => {
            state.activeBanks = action.payload;
        },
    },
});

export const { setActiveBanks } = BankSlice.actions;

export default BankSlice.reducer;
