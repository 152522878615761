import React, { useEffect, useState } from "react";
import styles from "./InviteFriend.module.css";
import CustomButton from "../HelperComponents/CustomButton";
import { useLocation } from "react-router-dom";
import Overview from "./Overview";
import Record from "./Record";
import { getAffiliate } from "../../Api/AffiliateApi";
import Loader from "../HelperComponents/Loader";
import { CommaSeperator, toFixedFunc } from "../Helper/HelperFunction";

const InviteFriend = () => {
  const location = useLocation();
  const state = location.state;
  const [toggleButton, setToggleButon] = useState({
    overview: state?.active === "record" ? false : true,
    record: state?.active === "record" ? true : false,
  });
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverViewData] = useState([]);
  const [overviewRules, setOverviewRules] = useState(null);

  const GetAffiliateFunc = async () => {
    setLoading(true);
    const res = await getAffiliate();
    if (res?.data) {
      setOverViewData(res?.data);
      setOverviewRules(res?.data?.rules);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetAffiliateFunc();
  }, []);

  const handleToggleButn = (e) => {
    let buttonText = e.target.textContent;
    if (buttonText === "Overview") {
      setToggleButon({
        overview: true,
        record: false,
      });
    } else {
      setToggleButon({
        overview: false,
        record: true,
      });
    }
  };

  const incomeTabs = [
    {
      id: "todaysIncome",
      label: "Today's Income",
      data: CommaSeperator(toFixedFunc(overviewData?.today_income)) ?? 0,
      icon: "₱",
    },
    {
      id: "yesterdaysIncome",
      label: "Yesterday's Income",
      data: CommaSeperator(toFixedFunc(overviewData?.yesterday_income)) ?? 0,
      icon: "₱",
    },
    {
      id: "register",
      label: "Registers",
      data: overviewData?.register ?? "-",
      icon: "",
    },
    {
      id: "monthIncome",
      label: "This Month Income",
      data:
        CommaSeperator(toFixedFunc(overviewData?.current_month_income)) ?? 0,
      icon: "₱",
    },
    {
      id: "lastMonthIncome",
      label: "Last Month Income",
      data: CommaSeperator(toFixedFunc(overviewData?.last_month_income)) ?? 0,
      icon: "₱",
    },
    {
      id: "totalIncome",
      label: "Total's Income",
      data: CommaSeperator(toFixedFunc(overviewData?.total_income)) ?? 0,
      icon: "₱",
    },
  ];

  return (
    <div className={styles.inviteFriendContainer}>
      <div className={styles.toggleBtnContainer}>
        <div className={styles.togglebox}>
          <div
            className={`${styles.toggleBtn} ${
              toggleButton.overview ? styles.toggleLeft : styles.toggleRight
            }`}
          ></div>
          <CustomButton
            className="no-hover"
            type={`${styles.transactionBtn} ${
              toggleButton.overview
                ? styles.transactionBtnFontColorActive
                : styles.transactionBtnFontColorInactive
            }`}
            text="Overview"
            onClick={handleToggleButn}
          />
          <CustomButton
            className="no-hover"
            type={`${styles.transactionBtn} ${
              toggleButton.record
                ? styles.transactionBtnFontColorActive
                : styles.transactionBtnFontColorInactive
            }`}
            text="Records"
            onClick={handleToggleButn}
          />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : toggleButton.overview ? (
        <Overview
          incomeTabs={incomeTabs}
          rules={overviewRules}
          referralLink={overviewData?.url}
        />
      ) : (
        <Record />
      )}
    </div>
  );
};

export default InviteFriend;
