import React from "react";
import { Input } from "antd";

const InputField = ({
  placeHolder,
  type,
  cls,
  name,
  value,
  onChange,
  extraStyle,
  suffix,
  prefix,
  required,
  onBlur,
  onKeyPress,
}) => {
  const handleFieldChange = (event) => {
    const { value } = event.target;
    onChange(name, value, event.target);
  };
  const inputStyle = {
    width: "190px",
    height: "35px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D9D9D9",
    borderRadius: "3px",
    ...extraStyle,
  };
  return (
    <Input
      style={{ ...inputStyle }}
      maxLength={name === "email" ? 200 : ""}
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      required={required}
      className={`customInput ${cls}`}
      type={type}
      placeholder={placeHolder}
      name={name}
      value={value}
      onChange={handleFieldChange}
      suffix={suffix !== "" ? suffix : ""}
      prefix={prefix !== "" ? prefix : ""}
    />
  );
};

export default InputField;
