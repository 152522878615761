import React, { useEffect, useRef, useState } from "react";
import styles from "./HomePageAllGames.module.css";
import CustomGrid from "../HelperComponents/CustomGrid";
import { Typography, Button, Pagination } from "antd";
import playIcon from "../../Assets/home/Play Icon.png";
import NoDataComp from "../NoData/NoDataComp";

const HomePageAllGames = (props) => {
  const { games, page, setPage, allAvailableGames } = props;
  const [displayedGames, setDisplayedGames] = useState([]);
  const gameContainerRef = useRef(null);

  useEffect(() => {
    games && setDisplayedGames(games);
  }, [games]);

  return (
    <div
      className={`${styles.container} all-games-container`}
      ref={gameContainerRef}
    >
      {games?.length > 0 ? (
        <>
          <CustomGrid
            games={displayedGames}
            imageStyling={styles.newArrImage}
            imageContainer={styles.imageContainer}
            parentDivStyling={styles.gridParentStyling}
            imageOverlay={styles.imageOverlay}
            playIcon={playIcon}
            playButtonContainer={styles.playButtonContainer}
            loadingImageOverlay={styles.loadingImageOverlay}
          />

          {games && games?.length > 0 && (
            <div className={styles.gameItemControllers}>
              <Pagination
                className="custom-pagination"
                defaultCurrent={1}
                current={page}
                pageSize={15}
                total={allAvailableGames?.total}
                onChange={(value) => setPage(value)}
                showSizeChanger={false}
                showLessItems={true}
                style={{
                  background:
                    "transparent linear-gradient(180deg, #5158de 0%, #613ade 48%, #711ade 100%) 0% 0% no-repeat padding-box",
                  color: "#613ade",
                  borderRadius: "5px",
                }}
              />
            </div>
          )}
        </>
      ) : (
        games && <NoDataComp />
      )}
    </div>
  );
};

export default HomePageAllGames;
