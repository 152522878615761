import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./GCashPage.module.css";
import Loader from "../Loader/Loader";

const GCashPage = ({ data = "", transactionId, result = {} }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div className={styles.iframeContainer}>
      {loading && <Loader />}
      <iframe
        src={data}
        title="Embedded Page"
        frameBorder="0"
        allowFullScreen
        className={styles.responsiveIframe}
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  );
};

export default GCashPage;
