import { Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { APIPlayGame } from "../../Api/GamesApi";
import LoginPopupModal from "../Login/LoginPopupModal";
import useLogout from "../Layout/Header/useLogout";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

const HorizentalGrid = ({
  games,
  imageStyling,
  parentDivStyling = "",
  imageContainer,
  imageOverlay,
  playButtonContainer,
  playIcon,
  segmentId,
  loadingImageOverlay,
}) => {
  const userReduxData = useSelector((state) => state?.user?.user);
  const [popupError, setPopupError] = useState("");
  const logout = useLogout();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loadingGameId, setLoadingGameId] = useState(null);
  const scrollableContainerRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [initialScrollX, setInitialScrollX] = useState(0);
  const navigate = useNavigate();

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setInitialMouseX(event.clientX);
    setInitialScrollX(scrollableContainerRef.current.scrollLeft);
    scrollableContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    const deltaX = event.clientX - initialMouseX;
    scrollableContainerRef.current.scrollLeft = initialScrollX - deltaX;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    scrollableContainerRef.current.style.cursor = "grab";
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const APIPlayGameFunc = async (id) => {
    let res;
    if (userReduxData) {
      setLoadingGameId(id);
      let newWindow = window?.open();

      res = await APIPlayGame(id);

      if (res.status && res.message === "SUCCESS") {
        if (res.result.is_url) {
          newWindow.location = res?.result?.data;
          // setTimeout(() => {
          //   window.open(res?.result?.data, "_blank");
          // });
        } else {
          const scriptString = res?.result?.data;
          // Separate the scripts
          const scripts = scriptString.split("</script>").filter(Boolean);

          scripts.forEach((scriptContent) => {
            const script = document.createElement("script");
            if (scriptContent.includes("src=")) {
              // External script
              const srcMatch = scriptContent.match(/src="([^"]+)"/);
              const src = srcMatch ? srcMatch[1] : null;
              if (src) {
                script.src = src;
                const integrityMatch =
                  scriptContent.match(/integrity="([^"]+)"/);
                if (integrityMatch) {
                  script.integrity = integrityMatch[1];
                }
                const crossoriginMatch = scriptContent.match(
                  /crossorigin="([^"]+)"/
                );
                if (crossoriginMatch) {
                  script.crossOrigin = crossoriginMatch[1];
                }
                document.head.appendChild(script);
              }
            } else {
              // Inline script
              const inlineScriptContent = scriptContent.replace(
                /<script type = "text\/javascript">/,
                ""
              );
              script.text = inlineScriptContent.trim();
              document.head.appendChild(script);
            }
          });
        }
      } else if (res.response?.status === 403) {
        newWindow.close();
        if (res.response.data.message === "PLAYER_FORBIDDEN") {
          setPopupError(
            "Player is not allowed to play games, Please contact the customer support to activate your account."
          );
        } else if (res.response.data.message === "REGISTRATION_NETWORK_ERROR") {
          setPopupError("REGISTRATION NETWORK ERROR");
        } else if (res.response.data.message === "CURRENCY_NOT_SUPPORTED") {
          setPopupError("CURRENCY NOT SUPPORTED");
        } else {
          setPopupError(
            "Player is not allowed to play games, Please contact the customer support to activate your account."
          );
        }
      } else if (res.response?.status === 401) {
        logout();
      } else if (res?.response?.status === 400) {
        newWindow.close();
        if (res?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        }
      } else {
        newWindow.close();
        setPopupError("An unexpected error occurred. Please try again later.");
      }
      setLoadingGameId(null);
    } else {
      logout();
    }
  };

  return (
    <div className={`${parentDivStyling} category-grid`}>
      <div
        className="scrollable-container"
        id={`scrollable-container-${segmentId}`}
        ref={scrollableContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Row gutter={games?.length < 3 ? 12 : 12} wrap={false}>
          {games?.map((game) => (
            <Col
              key={game.id}
              sm={games?.length >= 3 ? 7 : games?.length === 2 ? 10 : 24}
              span={screenWidth <= 576 ? (games.length >= 2 ? 8 : 24) : ""}
              style={{ padding: "6px" }}
            >
              <div className={imageContainer}>
                {game.id === loadingGameId ? (
                  <div
                    className={loadingImageOverlay}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div className={imageOverlay}>
                    <div className={playButtonContainer}>
                      <img
                        src={playIcon}
                        alt="playIcon"
                        className="imgStyle"
                        onClick={() => APIPlayGameFunc(game.id)}
                      />
                    </div>
                  </div>
                )}
                <img
                  style={{ cursor: "pointer" }}
                  loading="lazy"
                  src={game?.icon_square}
                  alt={`Img${game.id}`}
                  className={imageStyling}
                  onClick={() => APIPlayGameFunc(game.id)}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
      {popupError && (
        <LoginPopupModal
          message={popupError}
          show={popupError ? true : false}
          hideModal={() => {
            setPopupError("");
            if (
              popupError ===
              "Player is not allowed to play games, Please contact the customer support to activate your account."
            ) {
              navigate("/support");
            }
          }}
        />
      )}
    </div>
  );
};

export default HorizentalGrid;
