import React, { useEffect, useState } from "react";
import styles from "./Account.module.css";
import { Avatar, Divider, Progress, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CommaSeperator, toFixedFunc } from "../Helper/HelperFunction";
import { useNavigate } from "react-router-dom";
import LogoutConfirmationModal from "../Layout/Header/LogoutConfirmationModal";
import CopyIcon from "../../Assets/Copy.svg";
import CrownIcon from "../../Assets/Crown.svg";
import ProfileIcon from "../../Assets/ProfileIMG.png";
import RefreshIcon from "../../Assets/Refresh.svg";
import DepositIcon from "../../Assets/Deposit.svg";
import WithdrawalIcon from "../../Assets/Withdrawal.svg";
import BettingRecordIcon from "../../Assets/BettingRecord.svg";
import NotificationsIcon from "../../Assets/Notifications.svg";
import LogoutIcon from "../../Assets/login-register/Logout.png";
import EWalletIcon from "../../Assets/Ewallet.svg";
import DepositRecordIcon from "../../Assets/DepositRecord.svg";
import WithdrawalRecord from "../../Assets/WithdrawalRecord.svg";
import InviteFriend from "../../Assets/Invite Friends.svg";
import { currencyHelper } from "../Helper/currencyHelper";
import { getCheckLevelBasedOnPoints } from "../../Api/AccountApi";
import useLogout from "../Layout/Header/useLogout";
import Loader from "../Loader/Loader";
import { setUserInfo } from "../../Redux/Slice/UserSlice";
import copy from 'copy-to-clipboard';
import { APIUser } from "../../Api/Apis";

const Account = () => {
  const dispatch = useDispatch();
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const navigate = useNavigate();
  const logout = useLogout();
  const [logoutModal, setLogoutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [levelData, setLevelData] = useState();
  const current = levelData?.userCurrentPoints;
  const total = levelData?.max;
  const percent = (current / total) * 100;
  const [refreshLoader, setRefreshLoader] = useState(false);

  const handleRefreshClick = async () => {
    setRefreshLoader(true);
    const userInfoApiRes = await APIUser();
    if (userInfoApiRes?.response?.status === 401) {
      logout();
      setRefreshLoader(false);
    } else if (userInfoApiRes?.response?.status === 400) {
      if (userInfoApiRes?.response?.data?.message === "INVALID_ACCESS") {
        logout();
        setRefreshLoader(false);
      }
    } else {
      dispatch(setUserInfo(userInfoApiRes));
      setRefreshLoader(false);
    }
  };

  const accountCategoryData = [
    { id: "deposit", label: "Deposit", link: "/deposit", icon: DepositIcon },
    {
      id: "withdrawal",
      label: "Withdrawal",
      link: "/withdraw",
      icon: WithdrawalIcon,
    },
    {
      id: "bettingRecord",
      label: "Betting Record",
      link: "betting-records",
      icon: BettingRecordIcon,
    },
    {
      id: "notifications",
      label: "Notifications",
      link: "/notifications",
      icon: NotificationsIcon,
    },
    {
      id: "depositRecord",
      label: "Deposit Record",
      link: "transactions",
      icon: DepositRecordIcon,
    },
    {
      id: "withdrawalRecord",
      label: "Withdrawal Record",
      link: "transactions",
      icon: WithdrawalRecord,
    },
    {
      id: "ewallet",
      label: "E-Wallet Management",
      link: "ewallet",
      icon: EWalletIcon,
    },
    {
      id: "inviteFriend",
      label: "Invite Friend",
      link: "invite-friend",
      icon: InviteFriend,
    },
    { id: "logout", label: "Logout", link: "", icon: LogoutIcon },
  ];

  const AccountLevelFunc = async () => {
    setLoading(true);
    const res = await getCheckLevelBasedOnPoints();
    if (res?.data) {
      setLevelData(res?.data);
    } else {
      setLevelData();
    }
    setLoading(false);
  };

  useEffect(() => {
    AccountLevelFunc();
  }, []);

  const handleCopyClick = () => {
    const textToCopy = userInfoReduxData?.user_name;

    copy(textToCopy);
  };

  return (
    <div className={styles.container}>
      <div className={styles.accountProfileBlur}>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.accountProfile}>
            <div
            // style={{ border: "3px solid #be8aff", borderRadius: "50%" }}
            >
              <Avatar
                size={60}
                src={<img src={ProfileIcon} alt="avatar" />}
                // src={<img src={userInfoReduxData?.profile_pic} alt="avatar" />}
              />
            </div>

            <div className={styles.profileDetails}>
              <div className={styles.userTextContainer}>
                <div>
                  <Typography.Text className={styles.userFullNameStyle}>
                    {userInfoReduxData?.user_name}
                  </Typography.Text>
                </div>

                <div>
                  <img
                    onClick={handleCopyClick}
                    className={styles.imgStyle}
                    style={{ cursor: "pointer", marginLeft: "2%" }}
                    src={CopyIcon}
                    alt="CopyIcon"
                  />
                </div>
              </div>

              <div className={styles.balanceTextContainer}>
                {refreshLoader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "34px",
                      width: "30px",
                    }}
                  >
                    <Loader width="20px" height="20px" />
                  </div>
                ) : (
                  <>
                    <Tooltip
                      title={
                        CommaSeperator(toFixedFunc(userInfoReduxData?.balance))
                          .length > 10
                          ? CommaSeperator(
                              toFixedFunc(userInfoReduxData?.balance)
                            )
                          : ""
                      }
                    >
                      <Typography.Text className={styles.profileBalanceAmount}>
                        {currencyHelper(userInfoReduxData?.currency)}
                        {CommaSeperator(
                          toFixedFunc(userInfoReduxData?.balance)
                        )}
                      </Typography.Text>
                    </Tooltip>

                    <img
                      onClick={handleRefreshClick}
                      className={styles.imgStyle}
                      style={{ cursor: "pointer", marginLeft: "4%" }}
                      src={RefreshIcon}
                      alt="RefreshIcon"
                    />
                  </>
                )}
              </div>
            </div>

            {/* Progress Bar */}
            <div style={{ width: "100%" }}>
              <div className={styles.levelStyle}>
                <img style={{ width: "5%" }} src={CrownIcon} alt="CrownIcon" />
                <Typography.Text className={styles.levelNameStyle}>
                  {levelData?.level_name}
                </Typography.Text>
              </div>

              <div style={{ color: "white", display: "flex", gap: "1%" }}>
                <div style={{ width: "100%" }}>
                  <Progress
                    percent={percent}
                    showInfo={false}
                    status="active"
                    size="small"
                    strokeColor={{
                      from: "#108ee9",
                      to: "#000000d6",
                    }}
                    trailColor="white"
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <span>{current}</span>
                  <span>/</span>
                  <span>{total}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "1%" }}>
        <div
          style={{
            padding: "0 3%",
            background: "#232754 0% 0% no-repeat padding-box",
            color: "white",
            borderRadius: "8px",
          }}
        >
          <Typography.Text
            className={styles.userNameStyle}
            style={{ textAlign: "center" }}
          >
            Personal Center
          </Typography.Text>
        </div>
        <div style={{ flex: "1" }}>
          <Divider
            orientation="right"
            plain
            style={{
              borderColor: "#232754 ",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", // Adjust the column width as needed
          gap: "20px",
        }}
      >
        {accountCategoryData.map((item) => (
          <div
            key={item.id}
            onClick={() => {
              if (item.id === "logout") {
                setLogoutModal(true);
              } else if (item.id === "depositRecord") {
                navigate("/account/transactions", {
                  state: { active: "deposit" },
                });
              } else if (item.id === "withdrawalRecord") {
                navigate("/account/transactions", {
                  state: { active: "withdraw" },
                });
              } else {
                navigate(item.link);
              }
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              cursor: "pointer",
              margin: "auto",
            }}
          >
            <div
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                overflow: "hidden",
                border: "2px solid #3E337A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                  "transparent linear-gradient(180deg, #2C2165 0%, #3E337A 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <img
                src={item.icon}
                alt={item.label}
                style={{
                  maxWidth: "50%",
                  maxHeight: "50%",
                }}
              />
            </div>
            <Typography.Text
              className={styles.userNameStyle}
              style={{
                textAlign: "center",
                margin: "5% auto",
              }}
            >
              {item.label}
            </Typography.Text>
            {item?.id ==='inviteFriend' &&
            <Typography.Text
              className={styles.newtextStyle}
              style={{
                textAlign:'center',
                margin:'auto'
              }}
            >
              NEW
            </Typography.Text>
            }
          </div>
        ))}
      </div>

      {logoutModal && (
        <LogoutConfirmationModal
          logoutModal={logoutModal}
          closeLogoutModal={() => setLogoutModal(false)}
        />
      )}
    </div>
  );
};

export default Account;
