import React from "react";
import styles from "./Filter.module.css";
import CustomSearch from "../../HelperComponents/CustomSearchDropDown/CustomSearch";
import CustomSearchDropDown from "../../HelperComponents/CustomSearchDropDown/CustomSearchDropDown";

const Filter = (props) => {
  const {
    games,
    setGames,
    allAvailableGames,
    multipleSelectedPlatforms,
    setSelectedPlatforms,
    platforms,
    setMulitpleSelectedPlatforms,
    selectedPlatforms,
    setSearchData,
    searchData,
    HandleSearchButton,
    setUpdateAllPlatForm,
  } = props;
  return (
    <div className={styles.gameFilter}>
      <CustomSearch
        games={games}
        setGames={setGames}
        allAvailableGames={allAvailableGames}
        multipleSelectedPlatforms={multipleSelectedPlatforms}
        setSelectedPlatforms={setSelectedPlatforms}
        setSearchData={setSearchData}
        searchData={searchData}
        HandleSearchButton={HandleSearchButton}
      />
      {platforms.length > 0 && (
        <CustomSearchDropDown
          title={"Provider"}
          platforms={platforms}
          multipleSelectedPlatforms={multipleSelectedPlatforms}
          setMulitpleSelectedPlatforms={setMulitpleSelectedPlatforms}
          selectedPlatforms={selectedPlatforms}
          setSelectedPlatforms={setSelectedPlatforms}
          setSearchData={setSearchData}
          setGames={setGames}
          setUpdateAllPlatForm={setUpdateAllPlatForm}
        />
      )}
    </div>
  );
};

export default Filter;
