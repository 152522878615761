import React from "react";
import { useState } from "react";
import styles from "./CustomSearch.module.css";
import { Input, Button, ConfigProvider } from "antd";
import SearchIcon from "../../../Assets/Filter-Assets/Search Icon.png";
const { Search } = Input;

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const CustomSearch = (props) => {
  const { searchData, setSearchData, HandleSearchButton } = props;

  const debouncedSetUpdateSearchData = debounce((data) => {
    setSearchData(data);
    HandleSearchButton();
  }, 500);

  const HandleSearch = (value) => {
    if (value === "") {
      debouncedSetUpdateSearchData(value);
    } else {
      debouncedSetUpdateSearchData(value);
    }

    // if (value !== "") {
    //   setSearchData(value);
    // } else {
    //   setSearchData(value);
    //   HandleSearchButton();
    // }
  };

  return (
    <>
      <div style={{ width: "100%", marginRight: "4%" }}>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#9C9C9C",
              },
            },
          }}
        >
          <Input.Group className={styles.customSearchWrapper}>
            <Input
              prefix={<img src={SearchIcon} alt={`SearchIcon`} />}
              classNames={styles.inputSearch}
              value={searchData}
              onChange={(e) => HandleSearch(e.target.value)}
              onPressEnter={HandleSearchButton}
              allowClear
              style={{
                borderRadius: "5px",
                backgroundColor: "#000326",
                color: "#FFFFFF",
                width: "100%",
                height: "40px",
                fontSize: "15px",
                border: "0.5px solid #3B3F7B",
              }}
              placeholder={"Search Game..."}
            />
          </Input.Group>
        </ConfigProvider>
      </div>
    </>
  );
};

export default CustomSearch;
