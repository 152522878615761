import axios from "axios";
import clientConfig from "../config.json";

export const api = axios.create({
  // baseURL: "https://wmapis-staging.iegaming.io/api",
  // baseURL: 'https://wm-master.iegaming.io/api',
  // baseURL: "https://api.bestway9.ph/api",
  // baseURL: "https://api.bestway9.ph/api",
  baseURL: clientConfig?.baseUrl
    ? clientConfig?.baseUrl
    : "https://wmapis-staging.iegaming.io/api",
});
