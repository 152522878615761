import React, { useEffect, useState } from "react";
import styles from "./AppFooter.module.css";
import InactiveHomeIcon from "../../../Assets/footer/Inactive Home.png";
import InactiveBonusIcon from "../../../Assets/footer/Inactive Bonus.png";
import InactiveDepositIcon from "../../../Assets/footer/Inactive Deposit.png";
import InactiveChatIcon from "../../../Assets/footer/Inactive Chat.png";
import InactiveAccountIcon from "../../../Assets/footer/Inactive Account.png";
import ActiveHomeIcon from "../../../Assets/footer/Active Home.png";
import ActiveBonusIcon from "../../../Assets/footer/Active Bonus.png";
import ActiveDepositIcon from "../../../Assets/footer/Active Deposit.png";
import ActiveChatIcon from "../../../Assets/footer/Active Chat.png";
import ActiveAccountIcon from "../../../Assets/footer/Active Account.png";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import PageBottom from "../PageBottom/PageBottom";
import DepositWithdrawButtons from "../../DepositWithdrawButtons/DepositWithdrawButtons";
import Transactions from "../../../Assets/footer/Transaction.png";
import { Modal } from "antd";
import Deposit from "../../../Assets/footer/Deposit.png";
import Withdraw from "../../../Assets/footer/Withdraw.png";
import { useTranslation } from "react-i18next";

const AppFooter = () => {
  const {t} = useTranslation();
  const urlLocation = useLocation();
  const [buttonSelect, setButtonSelect] = useState(urlLocation.pathname);
  const [moneyButtonsModal, setMoneyButtonsModal] = useState(false);

  const footerIcon = [
    {
      id: "/",
      label: "Home",
      inactive_icon: InactiveHomeIcon,
      active_icon: ActiveHomeIcon,
    },
    {
      id: "/promotions",
      label: "Bonus",
      inactive_icon: InactiveBonusIcon,
      active_icon: ActiveBonusIcon,
    },
    {
      id: "/support",
      label: "Chat",
      inactive_icon: InactiveChatIcon,
      active_icon: ActiveChatIcon,
    },
    {
      id: "/account",
      label: "Account",
      inactive_icon: InactiveAccountIcon,
      active_icon: ActiveAccountIcon,
    },
  ];
  const moneyButtonsArray = [
    { image: Deposit, name: t('deposit'), link: "/deposit" },
    { image: Withdraw, name: t('withdrawmoney'), link: "/withdraw" },
  ];

  const navigate = useNavigate();

  const modalClose = (e) => {
    if (e.target.className === "ant-modal-wrap") {
      setMoneyButtonsModal(false);
    } else {
      setMoneyButtonsModal(true);
    }
  };
  useEffect(() => {
    if (
      urlLocation.pathname !== "/login" &&
      urlLocation.pathname !== "/register"
    ) {
      setButtonSelect(urlLocation.pathname);
    }
    if (
      urlLocation.pathname === "/login" ||
      urlLocation.pathname === "/register"
    ) {
      setButtonSelect("");
      // if(buttonSelect === 'deposit'){
      //   setButtonSelect('/deposit')
      // }else if(buttonSelect === '/account'){
      //   setButtonSelect('/account')
      // }
    }
  }, [urlLocation]);
  return (
    <>
      <div className={styles.appFooter}>
        <div className={styles.iconsContainer}>
          {/* {footerIcon.map((item) =>
            <Link to={item.id} key={item.id} style={{textDecoration:'none'}}>
              <div key={item.id} className={styles.footerIconSelected} style={{ background: item.id === buttonSelect ? "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 48%, #711ADE 100%) 0% 0% no-repeat padding-box" : "" }} onClick={() => setButtonSelect(item.id)}>
                <div className={styles.iconDiv}>
                  <img alt={`img${item.id}`} src={item.id === buttonSelect ? item.active_icon : item.inactive_icon} style={{ width: "100%", height: "auto", opacity: 1, cursor: "pointer", objectFit: 'contain' }} />
                </div>
                <span className={styles.labelStyling}>{item.label}</span>
              </div>
            </Link>
          )} */}
          {footerIcon.slice(0, 2).map((item) => (
            <Link to={item.id} key={item.id} style={{ textDecoration: "none" }}>
              <div
                className={styles.footerIconSelected}
                style={{
                  background:
                    item.id === buttonSelect
                      ? "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 48%, #711ADE 100%) 0% 0% no-repeat padding-box"
                      : "",
                }}
                onClick={() => setButtonSelect(item.id)}
              >
                <div className={styles.iconDiv}>
                  <img
                    alt={`img${item.id}`}
                    src={
                      item.id === buttonSelect
                        ? item.active_icon
                        : item.inactive_icon
                    }
                    className="imgStyle"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <span className={styles.labelStyling}>{item.label}</span>
              </div>
            </Link>
          ))}

          <div style={{ textDecoration: "none" }} onClick={modalClose}>
            <div className={styles.footerIconSelected}>
              <div className={styles.iconDiv} style={{ position: "relative" }}>
                <img
                  src={Transactions}
                  alt="transaction_img"
                  className="imgStyle"
                  style={{
                    cursor: "pointer",
                    width: "75px",
                    height: "51px",
                    left: "50%",
                    position: "absolute",
                    transform: "translate(-50%, -30%)",
                  }}
                />
              </div>
              <span className={styles.labelStyling}>{t('transaction')}</span>
            </div>
            {moneyButtonsModal && (
              <div style={{ display: "flex", width: "100%" }}>
                <Modal
                  open={moneyButtonsModal}
                  getContainer={() =>
                    document.querySelector(`.${styles.moneyButton}`)
                  }
                  className={`${styles.modalOverlay} parentClass`}
                  closeIcon={false}
                  footer={null}
                >
                  <div
                    className={styles.modalWrapper}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {moneyButtonsArray.map((item) => (
                      <div
                        className={styles.depositWithdrawfloatingBtns}
                        key={item.name}
                        onClick={() => {
                          navigate(item.link);
                          setMoneyButtonsModal(false);
                        }}
                      >
                        {" "}
                        <div style={{ display: "flex", width: 50, height: 50 }}>
                          <img
                            src={item.image}
                            alt={item.name}
                            className="imgStyle"
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <span className={styles.despositWithdrawBtnText}>
                            {item.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal>
              </div>
            )}
          </div>

          {footerIcon.slice(2).map((item) => (
            <Link to={item.id} key={item.id} style={{ textDecoration: "none" }}>
              <div
                key={item.id}
                className={styles.footerIconSelected}
                style={{
                  background:
                    item.id === buttonSelect
                      ? "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 48%, #711ADE 100%) 0% 0% no-repeat padding-box"
                      : "",
                }}
                onClick={() => setButtonSelect(item.id)}
              >
                <div className={styles.iconDiv}>
                  <img
                    alt={`img${item.id}`}
                    src={
                      item.id === buttonSelect
                        ? item.active_icon
                        : item.inactive_icon
                    }
                    style={{ cursor: "pointer" }}
                    className="imgStyle"
                  />
                </div>
                <span className={styles.labelStyling}>{item.label}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppFooter;
