import React from "react";
import styles from "./DepositInput.module.css";
import selectedIcon from "../../Assets/payment icons/Selected Method.png";
import InputField from "../HelperComponents/InputField";
import {
  CommaSeperator,
  handleKeyPress,
  toFixedFunc,
} from "../Helper/HelperFunction";
import { Typography, Card, Button, Badge, Space } from "antd";
import { currencyHelper } from "../Helper/currencyHelper";

const inputBox = {
  width: "100%",
  height: "45px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "1px solid #3C3562",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "left",
  fontFamily: "Roboto",
  fontWeight: "normal",
  lineHeight: "19px",
  fontSize: "18px",
  letterSpacing: 0,
  color: "#FFFFFF",
  // margin: "30px auto 0px auto",
};

const DepositInput = (props) => {
  const {
    depositMinAmount,
    depositMaxAmount,
    amount,
    userInfoReduxData,
    handleInputChange,
    sugestedAmount,
    handleSelectAmount,
  } = props;
  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        style={{
          width: "100%",
        }}
      >
        <div className={styles.cardWrapper}>
          <div className={styles.stepHolder}>
            <Typography.Text className={styles.stepText}>
              Step 3: Input amount{" "}
              {CommaSeperator(toFixedFunc(depositMinAmount))} ~{" "}
              {CommaSeperator(toFixedFunc(depositMaxAmount))}
            </Typography.Text>
          </div>
          <div className={styles.paymentMethodWrapper}>
            <div className={styles.amountContainer}>
              <Typography.Text className={styles.amountTextStyle}>
                Amount
              </Typography.Text>
              <Typography.Text className={styles.amountNumbersStyle}>
                ({CommaSeperator(toFixedFunc(depositMinAmount))} ~{" "}
                {CommaSeperator(toFixedFunc(depositMaxAmount))})
              </Typography.Text>
            </div>
            <div className={styles.amountBtnWrapper}>
              <div style={{ display: "flex", width: "100%", height: "auto" }}>
                <InputField
                  placeHolder={`${CommaSeperator(
                    toFixedFunc(depositMinAmount)
                  )} ~ ${CommaSeperator(toFixedFunc(depositMaxAmount))}`}
                  extraStyle={inputBox}
                  type="text"
                  name="amount"
                  prefix={
                    <Typography.Text
                      style={{
                        fontSize: "20px",
                        opacity: 1,
                        color: amount === "" ? "#574F85" : "#FFFFFF",
                      }}
                    >
                      {currencyHelper(userInfoReduxData?.currency)}
                    </Typography.Text>
                  }
                  value={amount}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className={styles.buttonContainer}>
                {sugestedAmount?.map((btn, index) => (
                  <div className={styles.subContainer} key={index}>
                    <Button
                      style={{
                        border: amount === btn.value ? "2px solid #FFFFFF" : "",
                      }}
                      className={styles.amountBtn}
                      key={btn.id}
                      onClick={(e) => handleSelectAmount(e, btn)}
                    >
                      {btn.label}
                      {amount === btn.value ? (
                        <span>
                          <img
                            src={selectedIcon}
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "absolute",
                              right: "0",
                              bottom: "0",
                              objectFit: "contain",
                            }}
                            alt="selectedIcon"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Space>
    </>
  );
};

export default DepositInput;
