import React from "react";
import styles from "./NoDataComp.module.css";
import noDataFoundIcon from "../../Assets/No Data Icon.png";
import { MdHorizontalRule } from "react-icons/md";
import { Typography } from "antd";

const NoDataComp = (props) => {
  const { message, icon, height } = props;
  return (
    <div className={styles.noDataIconContainer} style={{height: height ? height : ''}}>
      <div className={styles.noDataFoundContainer}>
        <div className={styles.imageContainer}>
          <img src={icon ? icon : noDataFoundIcon} alt="no-data-found" className="imgStyle" />
        </div>
        <Typography.Text className="noDataFoundText">
          {message ? message : "No Data Found !"}
        </Typography.Text>
      </div>
    </div>
  );
};

export default NoDataComp;
