import styles from "./Overview.module.css";
import ShareYourFriendIcon from "../../Assets/Share to your friends.svg";
import CopyIcon from "../../Assets/Copy link.svg";
import CustomDivider from "../HelperComponents/CustomDivider";
import { TfiMenuAlt } from "react-icons/tfi";
import copy from "copy-to-clipboard";
import { useState } from "react";
import NoDataComp from "../NoData/NoDataComp";
import Loader from "../HelperComponents/Loader";
import SocialShare from "../HelperComponents/SocialShare";
import clientConfig from "../../config.json";

const Overview = ({ incomeTabs, rules, referralLink }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const referralURL = `${window.location.origin}${clientConfig.homepage}/register?${referralLink}`;

  const handleCopyClick = (data) => {
    const textToCopy = data;
    copy(textToCopy);
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 300); // duration should match the animation duration
  };
  return (
    <>
      <div className={styles.filterContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 14,
          }}
        >
          {incomeTabs &&
            incomeTabs.slice(0, 3).map((tab) => (
              <div className={styles.tabsStyle} key={tab?.id}>
                <div className={styles.tabLabelStyle}>{tab.label}</div>
                <div>
                  <span className={styles.incomeIcon}>{tab.icon}</span>{" "}
                  <span className={styles.tabData}>{tab.data}</span>
                </div>
              </div>
            ))}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 14,
          }}
        >
          {incomeTabs &&
            incomeTabs.slice(3).map((tab) => (
              <div className={styles.tabsStyle} key={tab?.id}>
                <div className={styles.tabLabelStyle}>{tab.label}</div>
                <div>
                  <span className={styles.incomeIcon}>{tab.icon}</span>{" "}
                  <span className={styles.tabData}>{tab.data}</span>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.referalLinkContainer}>
        <div className={styles.referalTitle}>Share to your friends</div>
        <div className={styles.referalLinkInput}>{referralURL}</div>
        <div className={styles.buttonsContainer}>
          <div
            className={styles.btnStyle}
            onClick={() => setIsShareModalOpen(true)}
          >
            <img src={ShareYourFriendIcon} />
            <span className={styles.btnText}>Share to your friends</span>
          </div>
          <div
            className={styles.btnStyle}
            onClick={() => handleCopyClick(referralURL)}
          >
            <img src={CopyIcon} className={isClicked ? styles.clicked : ""} />
            <span className={styles.btnText}>Copy link</span>
          </div>
        </div>
      </div>

      <CustomDivider color="#51497B" />

      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div className={styles.rulesTitle}>
          <TfiMenuAlt /> Rules
        </div>
        <div className={styles.rulesDetails}>
          {rules ? (
            rules.map((rule) => <p className={styles.rulesStyle}>* {rule}</p>)
          ) : (
            <NoDataComp />
          )}
        </div>
      </div>

      <div className="social-share">
        {isShareModalOpen && (
          <SocialShare
            open={isShareModalOpen}
            handleClose={setIsShareModalOpen}
            link={referralURL}
          />
        )}
      </div>
    </>
  );
};

export default Overview;
