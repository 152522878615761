import React, { useEffect, useRef, useState } from "react";
import styles from "../Login/Login.module.css";
import { Image, Typography } from "antd";
import CustomButton from "../HelperComponents/CustomButton";
import InputField from "../HelperComponents/InputField";
import UsernameIcon from "../../Assets/login-register/Username.png";
import NameIcon from "../../Assets/login-register/Name.png";
import PasswordIcon from "../../Assets/login-register/Password.png";
import Eye from "../../Assets/login-register/Eye.png";
import EyeOpen from "../../Assets/login-register/Eye Open.png";
import PhoneIcon from "../../Assets/login-register/country code.png";
import BangladeshIcon from "../../Assets/Language/Bangladesh@2x.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "antd";
import {
  APICheckPhoneExist,
  APICheckUsernameExists,
  APIRegisterUser,
} from "../../Api/Apis";
import ErrorsHandlingComp from "../Helper/Validator/ErrorsHandlingComp";
import { setUser } from "../../Redux/Slice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { checkUserNameValidation } from "../Helper/HelperFunction";
import ReCAPTCHA from "react-google-recaptcha";
import { getWebglFp } from "../../Assets/FingerPrint/main";
import { DeviceAndBrowserInfo } from "../../Components/shared-components/DeviceAndBrowserInfo/DeviceAndBrowserInfo";
import clientConfig from "../../config.json";

const flagIcons = {
  PHP: PhoneIcon,
  BDT: BangladeshIcon,
};

const Register = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const ag_id = localStorage.getItem("ag_id");
  const browser_url = window.location.href;
  // const clientConfig = useSelector((state) => state.client.clientConfig);
  const [singupFormData, setSignupFormData] = useState({
    user_name: "",
    name: "",
    password: "",
    phone: "",
    currency: clientConfig.currencyBackendCode,
    agent_id: params?.ag_id || ag_id || "",
    countryCode: clientConfig?.countryCode,
  });

  const [singupErrors, setSignupErrors] = useState({
    user_name: "",
    password: "",
    name: "",
    phone: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const urlLocation = useLocation();
  const [signupBtnDisabled, setSignupBtnDisabled] = useState(false);
  const phoneInputRef = useRef(null);
  const captchaKey = clientConfig?.captchaKey;

  // const captchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [singupFormDefaultMsg, setSingupFormDefaultMsg] = useState({
    defaultUserNameMsg: t("defaultUserNameMessage"),
    defaultPasswordMsg: t("defaultPasswordMessage"),
    defaultFullNameMsg: t("defaultFullNameMessage"),
    defaultPhoneMsg: t("defaultPhoneMessage"),
  });
  const reCaptchaRef = useRef(null);
  const [webGlResult, setWebGlResult] = useState(null);
  const containerRef = useRef(null);

  const getFlagIcon = () => {
    if (clientConfig?.currencyCode === "BDT") {
      return flagIcons["BDT"];
    } else if (clientConfig?.currencyCode === "PHP") {
      return flagIcons["PHP"];
    }
    return null;
  };

  useEffect(() => {
    setWebGlResult(getWebglFp());
  }, []);

  const handleCaptchaError = () => {
    setErrorMessage(
      t(
        "User could not be verified. Please contact Customer Support. Error: 10001"
      )
    );
  };

  useEffect(() => {
    if (params && params?.ag_id) {
      localStorage.setItem("ag_id", params?.ag_id);
    }
  }, []);

  useEffect(() => {
    if (
      singupFormData?.user_name !== "" &&
      singupFormData?.name !== "" &&
      singupFormData?.password !== "" &&
      singupFormData?.phone !== ""
    ) {
      setSignupBtnDisabled(false);
    } else {
      setSignupBtnDisabled(true);
    }
  }, [singupFormData, singupErrors]);

  useEffect(() => {
    if (singupFormData?.user_name === "") {
      setSignupErrors((prevErrors) => {
        const { user_name, ...rest } = prevErrors;
        return rest;
      });
    }
    if (singupFormData?.phone === "") {
      setSignupErrors((prevErrors) => {
        const { phone, ...rest } = prevErrors;
        return rest;
      });
    }
    if (singupFormData?.password === "") {
      setSignupErrors((prevErrors) => {
        const { password, ...rest } = prevErrors;
        return rest;
      });
    }
    if (singupFormData?.name === "") {
      setSignupErrors((prevErrors) => {
        const { name, ...rest } = prevErrors;
        return rest;
      });
    }
  }, [singupFormData]);

  const debounceAPICheckPhoneExist = async (phoneWithCountryCode, phone) => {
    if (phone === phoneInputRef.current?.value.substring(0, 10)) {
      const checkPhoneRes = await APICheckPhoneExist(phoneWithCountryCode);
      if (checkPhoneRes && checkPhoneRes.exists) {
        // setErrors(prevErrors => ({ ...prevErrors, phone: t("phoneerror") }));
        return t("phoneerror");
      } else {
        // setErrors(prevErrors => ({ ...prevErrors, phone: ""}));
        return "";
      }
    }
    return "";
  };

  const userNameExistCheck = async (value) => {
    const userNameRes = await APICheckUsernameExists(value);
    if (userNameRes && userNameRes.exists) {
      return "Username Already Taken";
    } else {
      return "";
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const addClickListener = () => {
    document.addEventListener("click", handleClickOutside, true);
  };

  const removeClickListener = () => {
    document.removeEventListener("click", handleClickOutside, true);
  };

  const validateUserName = async (value) => {
    const checkUserNameValidation = checkUserNameRegex(value.toLowerCase());
    if (!checkUserNameValidation) {
      return t("userNameError1");
    } else {
      const usernameExistMessage = await userNameExistCheck(
        value.toLowerCase()
      );
      return usernameExistMessage || "";
    }
  };

  const validateName = (value) => {
    const pattern = /^[A-Za-z]+(?:\s[A-Za-z]+)+$/;

    if (value && value?.length < 3) {
      return t("nameMinLengthError");
    } else if (value?.length > 255) {
      return t("nameMaxLengthError");
    } else if (!pattern.test(value)) {
      return t("nameValidationError");
    } else {
      return "";
    }
  };

  const validatePassword = (value) => {
    const checkPasswordValidation = checkPassaowrdRegex(value);
    if (!checkPasswordValidation) {
      return t("passwordError");
    } else {
      return "";
    }
  };

  const validatePhone = async (phone) => {
    const checkPhoneValidation = checkPhoneLength(phone);
    if (!checkPhoneValidation) {
      return t("phoneError1");
    }
    const phoneWithCountryCode = singupFormData.countryCode.concat(phone);
    const phoneExistMessage = await debounceAPICheckPhoneExist(
      phoneWithCountryCode,
      phone
    );
    return phoneExistMessage || "";
  };

  const onFinish = async () => {
    setLoading(true);
    const info = DeviceAndBrowserInfo();
    if (
      singupFormData.user_name &&
      singupFormData.name &&
      singupFormData.password &&
      singupFormData.phone
    ) {
      const user_name_error = await validateUserName(singupFormData.user_name);
      const name_error = validateName(singupFormData.name);
      const password_error = validatePassword(singupFormData.password);
      const phone_error = await validatePhone(singupFormData.phone);
      setErrors({
        user_name: user_name_error,
        name: name_error,
        password: password_error,
        phone: phone_error,
      });

      if (!user_name_error && !name_error && !password_error && !phone_error) {
        addClickListener();
        let captcha_token = await reCaptchaRef.current.executeAsync();
        reCaptchaRef.current.reset();
        removeClickListener();
        let referKey;
        if (params && Object.keys(params).length > 0) {
          let key = Object.keys(params)[0];
          referKey = { [key]: Number(params[key]) ?? `` };
        }

        if (!captchaKey || !captcha_token) {
          setErrorMessage(
            "User could not be verified. Please contact Customer Support. Error: 10001"
          );
          setLoading(false);
          return;
        } else if (!webGlResult) {
          setErrorMessage(
            "Account could not be created because verification failed. Please contact Customer Support. Error: 10002"
          );
        }
        const res = await APIRegisterUser(
          singupFormData,
          captcha_token,
          webGlResult,
          info.deviceType,
          info.browserName,
          browser_url,
          referKey
        );
        if (res?.status === 200) {
          localStorage.setItem("auth_token", res?.data?.token);
          localStorage.removeItem("ag_id");
          dispatch(setUser(res?.data?.token));
          navigate("/");
          localStorage.removeItem("_grecaptcha");
        } else if (res?.status === 500) {
          setErrorMessage("Network Error");
        } else if (res?.status === 404) {
          if (res?.data?.message === "AGENT_NOT_FOUND") {
            setErrorMessage("Agent Not Found");
          }
        } else {
          if (res?.status === 422) {
            if (
              res?.data?.message ===
              "User could not be verified. Please contact Customer Support. Error: 10001"
            ) {
              setErrorMessage(res?.data?.message);
            } else if (
              res?.data?.message ===
              "Account could not be created because verification failed. Please contact Customer Support. Error: 10002"
            ) {
              setErrorMessage(res?.data?.message);
            }
            // setErrors(Object.values(res?.data?.message));
            setErrors(res?.data?.message);
          }
        }

        setSignupFormData({
          user_name: "",
          name: "",
          password: "",
          phone: "",
          currency: clientConfig.currencyBackendCode,
          agent_id: params?.ag_id || ag_id || "",
          countryCode: clientConfig?.countryCode,
        });
      }
      setLoading(false);
    }
  };

  //check name regex must be lowercase or numbers or both and the length between 8 and 12
  const checkUserNameRegex = (value) => {
    return /^(?:[a-z]|[0-9]){8,12}$/.test(value);
  };

  //check phone Number regex  must match this format (+)(number) and the number length between 11 and 14
  const checkPhoneLength = (value) => {
    return /^[0-9]{10}$/.test(value);
  };

  //The password can't have special characters and must contain lowercase, numbers and the length between 6 and 13
  const checkPassaowrdRegex = (value) => {
    // return /^(?=.*[a-z])(?=.*\d)[a-z\d]{6,16}$/.test(value);
    return /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};'"\\|,.<>\/?]{6,16}$/.test(value);
  };
  // /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};\'":\\|,.<>\/?]{6,16}$/

  const inputBox = {
    maxWidth: "521px",
    width: "100%",
    height: "45px",
    background: "#000326 0% 0% no-repeat padding-box",
    border: "1px solid #3C3562",
    borderRadius: "7px",
    opacity: 1,
    textAlign: "left",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: "Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
  };
  return (
    <div
      className={`${styles.registerContainer} ${
        urlLocation.pathname === "/login" ? styles.addMarginToRegister : ""
      } login-container`}
    >
      <div className={styles.agentCodeWrapper}>
        {singupFormData?.agent_id && (
          <span>
            {" "}
            Agent Id: <strong>{singupFormData?.agent_id}</strong>
          </span>
        )}
      </div>
      <div ref={containerRef}>
        <Form
          onFinish={onFinish}
          style={{ width: "100%" }}
          className="register-form-container"
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "user name is required",
              },
            ]}
          >
            <InputField
              placeHolder="USERNAME"
              extraStyle={{
                ...inputBox,
              }}
              type="text"
              name="user_name"
              prefix={
                <div style={{ width: 20, height: 20 }}>
                  <Image
                    src={UsernameIcon}
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: 1,
                      marginRight: "5px",
                    }}
                    preview={false}
                  />
                </div>
              }
              onChange={(name, value) =>
                setSignupFormData({
                  ...singupFormData,
                  user_name: value.toLowerCase(),
                })
              }
              onKeyPress={checkUserNameValidation}
              value={singupFormData.user_name}
              required={true}
              // cls={"userNameSpclClass"}
            />
            {/* <ErrorsHandlingComp errors={errors} name='user_name' /> */}
            {/* <div style={{ color:singupErrors?.user_name && singupErrors?.user_name.length ? "#FF5746" :"#A499E8", width: '100%' }}>{singupErrors?.user_name && singupErrors?.user_name.length ? singupErrors?.user_name : singupFormDefaultMsg.defaultUserNameMsg}</div> */}
            {Object.keys(errors).length === 0 ? (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultUserNameMsg}
              </span>
            ) : errors.user_name && errors.user_name.length ? (
              ""
            ) : (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultUserNameMsg}
              </span>
            )}
            {errors.user_name && (
              <span style={{ color: "#FF5746", fontSize: "12px" }}>
                {errors.user_name}
              </span>
            )}
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "password is required",
              },
            ]}
          >
            <InputField
              placeHolder="PASSWORD"
              extraStyle={inputBox}
              type={showPassword ? "text" : "password"}
              name="password"
              // onBlur={checkPassword}
              prefix={
                <div style={{ width: 20, height: 20 }}>
                  <Image
                    src={PasswordIcon}
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: 1,
                      marginRight: "5px",
                    }}
                    preview={false}
                  />
                </div>
              }
              suffix={
                showPassword ? (
                  <Image
                    src={EyeOpen}
                    style={{ width: 25, height: 25, opacity: 1 }}
                    onClick={() => setShowPassword(!showPassword)}
                    preview={false}
                  />
                ) : (
                  <Image
                    src={Eye}
                    style={{ width: 25, height: 25, opacity: 1 }}
                    onClick={() => setShowPassword(!showPassword)}
                    preview={false}
                  />
                )
              }
              onChange={(name, value) =>
                setSignupFormData({ ...singupFormData, password: value })
              }
              value={singupFormData.password}
              required={true}
            />
            {/* <ErrorsHandlingComp errors={errors} name='password' /> */}
            {/* <div style={{ color:singupErrors?.password && singupErrors?.password.length ? "#FF5746" :"#A499E8", width: '100%' }}>{singupErrors?.password && singupErrors?.password.length ? singupErrors?.password : singupFormDefaultMsg.defaultPasswordMsg}</div> */}
            {Object.keys(errors).length === 0 ? (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultPasswordMsg}
              </span>
            ) : errors.password && errors.password.length ? (
              ""
            ) : (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultPasswordMsg}
              </span>
            )}
            {errors.password && (
              <span style={{ color: "#FF5746", fontSize: "12px" }}>
                {errors.password}
              </span>
            )}
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: " name is required",
              },
            ]}
          >
            <InputField
              placeHolder="FULL NAME"
              extraStyle={inputBox}
              type="text"
              name="name"
              prefix={
                <div style={{ width: 20, height: 20 }}>
                  <Image
                    src={NameIcon}
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: 1,
                      marginRight: "5px",
                    }}
                    preview={false}
                  />
                </div>
              }
              onChange={(name, value) =>
                setSignupFormData({ ...singupFormData, name: value })
              }
              value={singupFormData.name}
              required={true}
            />
            {/* <ErrorsHandlingComp errors={errors} name='name' /> */}
            {/* <div style={{ color:singupErrors?.name && singupErrors?.name.length ? "#FF5746" :"#A499E8", width: '100%' }}>{singupErrors?.name && singupErrors?.name.length ? singupErrors?.name : singupFormDefaultMsg.defaultFullNameMsg}</div> */}
            {Object.keys(errors).length === 0 ? (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultFullNameMsg}
              </span>
            ) : errors.name && errors.name.length ? (
              ""
            ) : (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultFullNameMsg}
              </span>
            )}
            {errors.name && (
              <span style={{ color: "#FF5746", fontSize: "12px" }}>
                {errors.name}
              </span>
            )}
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Phone is required",
              },
            ]}
          >
            <InputField
              placeHolder="PHONE"
              extraStyle={inputBox}
              type="number"
              name="phone"
              onKeyPress={checkUserNameValidation}
              prefix={
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div style={{ width: 20, height: 20 }}>
                    <Image
                      src={getFlagIcon()}
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: 1,
                        objectFit: "contain",
                      }}
                      preview={false}
                    />
                  </div>
                  <Typography.Text className={styles.countryCodeText}>
                    {singupFormData?.countryCode}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontStyle: "normal",
                      fontVariant: "normal",
                      fontWeight: "medium",
                      fontSize: "20px",
                      color: "#574E85",
                      opacity: 1,
                      margin: "0px 5px 1px 0px",
                    }}
                  >
                    |
                  </Typography.Text>
                </div>
              }
              // suffix={<CustomButton text="Get Code" type={styles.getCodeBtn} />}
              onChange={(name, value, target) => {
                phoneInputRef.current = target;
                value.length <= 10
                  ? setSignupFormData({ ...singupFormData, phone: value })
                  : setSignupFormData({
                      ...singupFormData,
                      phone: value.substring(0, 10),
                    });
              }}
              value={singupFormData.phone}
              required={true}
            />
            {/* <ErrorsHandlingComp errors={errors} name='phone' /> */}
            {Object.keys(errors).length === 0 ? (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultPhoneMsg}
              </span>
            ) : errors.phone && errors.phone.length ? (
              ""
            ) : (
              <span style={{ color: "#A499E8", fontSize: "12px" }}>
                {singupFormDefaultMsg.defaultPhoneMsg}
              </span>
            )}
            {errors.phone && (
              <span style={{ color: "#FF5746", fontSize: "12px" }}>
                {errors.phone}
              </span>
            )}
          </Form.Item>
          {errorMessage && (
            <span
              style={{
                color: "#FF5746",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {errorMessage}
            </span>
          )}

          {/* <Image src={PromocodeIcon} style={{ width: 20, height: 20, opacity: 1, marginRight: "5px" }} preview={false} /> */}
          {/* <Form.Item>
          <InputField
            placeHolder="Agent Code"
            extraStyle={inputBox}
            type="text"
            name="agent_id"
            prefix={<FaCodeFork color="#3c3562" />}
            onChange={handleSignupInputChange}
            value={singupFormData.agent_id}
          />
          <ErrorsHandlingComp errors={errors} name='agent_id' />

        </Form.Item> */}
          {/* <Checkbox style={{ color: "gray" }}>
          I confirm the I am 18 years old and I have read the{" "}
          <a style={{ textDecoration: "underline" }}>Terms and service</a>
        </Checkbox> */}
          <div className="custom-recaptcha-container">
            <ReCAPTCHA
              className="g-recaptcha"
              sitekey={captchaKey}
              size="invisible"
              ref={reCaptchaRef}
              onErrored={handleCaptchaError}
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <CustomButton
              text="Sign up"
              type={`${styles.signupBtn} ${
                signupBtnDisabled ? styles.signupDisabled : styles.signupEnabled
              }`}
              disabled={signupBtnDisabled}
              // htmlType="submit"
            />
          )}
          {/* <Paragraph className={styles.loginParagrap}>
          <p>By accessing you confirm the you are at least 18 years old and
            agree to the{" "}
            <Link style={{
              textAlign: "right",
              textDecoration: "underline",
              font: "normal normal medium 16px/19px Roboto",
              letterSpacing: "0px",
              color: "#BE8AFF",
              opacity: 1,
            }}>Terms and service</Link></p>
        </Paragraph> */}
        </Form>
      </div>
    </div>
  );
};

export default Register;
