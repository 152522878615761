import { createSlice } from "@reduxjs/toolkit";
export const LanguageSlice = createSlice({
    name: "language",
    initialState: {
        language: null,
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
    },
});

export const { setLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
