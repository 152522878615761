import "./App.css";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./Routing/PrivateRoute";
import { privateRoutes } from "./Routing/PrivateRoutes";
import Home from "./Components/Home/Home";
import Login from "./Components/Login/Login";
import Layout from "./Components/Layout/Layout";
import "./CssOverride.scss";
import { persistStore } from "redux-persist";
import store from "./Redux/store";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import Games from "./Components/Games/Games";
import Bonus from "./Components/Bonus/Bonus";
// import AffiliateProgram from "./Components/AffiliateProgram/AffiliateProgram";
import Support from "./Components/Support/Support";
import SinglePromotionDetails from "./Components/Bonus/SinglePromotionDetails";
import AboutUs from "./Components/Layout/PageBottom/components/AboutUs/AboutUs";
import PrivacyPolicy from "./Components/Layout/PageBottom/components/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./Components/Layout/PageBottom/components/TermsAndConditions/TermsAndCondition";
import ResponsibleGaming from "./Components/Layout/PageBottom/components/ResponsibleGaming/ResponsibleGaming";
import { setClientConfig } from "./Redux/Slice/ClientConfigSlice";
import getClient from "./utils/getClient";
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});
function App() {
  const clientConfig = getClient();
  store.dispatch(setClientConfig(clientConfig));
  
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
        
          <Layout>
            <Routes >
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Login />} />
              <Route path="/support" element={<Support />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/games/:name" element={<Games />} />
              <Route path="/promotions" element={<Bonus />} />
              {/* <Route path="/affiliate-program" element={<AffiliateProgram />} /> */}

              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndCondition />}
              />
              <Route
                path="/responsible-gaming"
                element={<ResponsibleGaming />}
              />

              <Route
                path="/promotions/:id"
                element={<SinglePromotionDetails />}
              />
              {privateRoutes.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<PrivateRoute component={route.component} />}
                />
              ))}
            </Routes>
          </Layout>
        </Provider>
      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default App;
