export const getWebglCanvas = function () {
    var canvas = document.createElement("canvas"),
      context = null;
    try {
      context =
        canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    } catch (e) {}
    return context || null;
  };
  
  export const getWebglFp = function () {
    var gl;
  
    function formatRange(range) {
      return `[${range[0]}, ${range[1]}]`;
    }
  
    if (!(gl = getWebglCanvas())) return null;
  
    var attributes = [],
      buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  
    var vertices = new Float32Array([
      -0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.732134444, 0,
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
  
    buffer.itemSize = 3;
    buffer.numItems = 3;
  
    var program = gl.createProgram(),
      vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(
      vertexShader,
      `
          attribute vec2 attrVertex;
          varying vec2 varyinTexCoordinate;
          uniform vec2 uniformOffset;
          void main() {
              varyinTexCoordinate = attrVertex + uniformOffset;
              gl_Position = vec4(attrVertex, 0, 1);
          }
      `
    );
    gl.compileShader(vertexShader);
  
    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(
      fragmentShader,
      `
          precision mediump float;
          varying vec2 varyinTexCoordinate;
          void main() {
              gl_FragColor = vec4(varyinTexCoordinate, 0, 1);
          }
      `
    );
    gl.compileShader(fragmentShader);
  
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.useProgram(program);
  
    program.vertexPosAttrib = gl.getAttribLocation(program, "attrVertex");
    program.offsetUniform = gl.getUniformLocation(program, "uniformOffset");
  
    gl.enableVertexAttribArray(program.vertexPosAttrib);
    gl.vertexAttribPointer(
      program.vertexPosAttrib,
      buffer.itemSize,
      gl.FLOAT,
      false,
      0,
      0
    );
  
    gl.uniform2f(program.offsetUniform, 1, 1);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, buffer.numItems);
  
    attributes.push("extensions:" + gl.getSupportedExtensions().join(";"));
    attributes.push(
      "webgl aliased line width range:" +
        formatRange(gl.getParameter(gl.ALIASED_LINE_WIDTH_RANGE))
    );
    attributes.push(
      "webgl aliased point size range:" +
        formatRange(gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE))
    );
    attributes.push("webgl alpha bits:" + gl.getParameter(gl.ALPHA_BITS));
    attributes.push(
      "webgl antialiasing:" + (gl.getContextAttributes().antialias ? "yes" : "no")
    );
    attributes.push("webgl blue bits:" + gl.getParameter(gl.BLUE_BITS));
    attributes.push("webgl depth bits:" + gl.getParameter(gl.DEPTH_BITS));
    attributes.push("webgl green bits:" + gl.getParameter(gl.GREEN_BITS));
  
    attributes.push(
      "webgl max anisotropy:" +
        (function (gl) {
          var ext =
            gl.getExtension("EXT_texture_filter_anisotropic") ||
            gl.getExtension("WEBKIT_EXT_texture_filter_anisotropic") ||
            gl.getExtension("MOZ_EXT_texture_filter_anisotropic");
          if (!ext) return null;
          var maxAnisotropy = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
          return maxAnisotropy === 0 ? 2 : maxAnisotropy;
        })(gl)
    );
  
    attributes.push(
      "webgl max combined texture image units:" +
        gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS)
    );
    attributes.push(
      "webgl max cube map texture size:" +
        gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE)
    );
    attributes.push(
      "webgl max fragment uniform vectors:" +
        gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS)
    );
    attributes.push(
      "webgl max render buffer size:" + gl.getParameter(gl.MAX_RENDERBUFFER_SIZE)
    );
    attributes.push(
      "webgl max texture image units:" +
        gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS)
    );
    attributes.push(
      "webgl max texture size:" + gl.getParameter(gl.MAX_TEXTURE_SIZE)
    );
    attributes.push(
      "webgl max varying vectors:" + gl.getParameter(gl.MAX_VARYING_VECTORS)
    );
    attributes.push(
      "webgl max vertex attribs:" + gl.getParameter(gl.MAX_VERTEX_ATTRIBS)
    );
    attributes.push(
      "webgl max vertex texture image units:" +
        gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS)
    );
    attributes.push(
      "webgl max vertex uniform vectors:" +
        gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS)
    );
    attributes.push(
      "webgl max viewport dims:" +
        formatRange(gl.getParameter(gl.MAX_VIEWPORT_DIMS))
    );
    attributes.push("webgl red bits:" + gl.getParameter(gl.RED_BITS));
    attributes.push("webgl renderer:" + gl.getParameter(gl.RENDERER));
    attributes.push(
      "webgl shading language version:" +
        gl.getParameter(gl.SHADING_LANGUAGE_VERSION)
    );
    attributes.push("webgl stencil bits:" + gl.getParameter(gl.STENCIL_BITS));
    attributes.push("webgl vendor:" + gl.getParameter(gl.VENDOR));
    attributes.push("webgl version:" + gl.getParameter(gl.VERSION));
  
    try {
      var debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
      if (debugInfo) {
        attributes.push(
          "webgl unmasked vendor:" +
            gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
        );
        attributes.push(
          "webgl unmasked renderer:" +
            gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
        );
      }
    } catch (e) {}
  
    if (gl.getShaderPrecisionFormat) {
      attributes.push(
        "webgl vertex shader high float precision:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).precision
      );
      attributes.push(
        "webgl vertex shader high float precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMin
      );
      attributes.push(
        "webgl vertex shader high float precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMax
      );
      attributes.push(
        "webgl vertex shader medium float precision:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).precision
      );
      attributes.push(
        "webgl vertex shader medium float precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMin
      );
      attributes.push(
        "webgl vertex shader medium float precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMax
      );
      attributes.push(
        "webgl vertex shader low float precision:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).precision
      );
      attributes.push(
        "webgl vertex shader low float precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMin
      );
      attributes.push(
        "webgl vertex shader low float precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMax
      );
  
      attributes.push(
        "webgl fragment shader high float precision:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).precision
      );
      attributes.push(
        "webgl fragment shader high float precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMin
      );
      attributes.push(
        "webgl fragment shader high float precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMax
      );
      attributes.push(
        "webgl fragment shader medium float precision:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
            .precision
      );
      attributes.push(
        "webgl fragment shader medium float precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
            .rangeMin
      );
      attributes.push(
        "webgl fragment shader medium float precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
            .rangeMax
      );
      attributes.push(
        "webgl fragment shader low float precision:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).precision
      );
      attributes.push(
        "webgl fragment shader low float precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMin
      );
      attributes.push(
        "webgl fragment shader low float precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMax
      );
  
      attributes.push(
        "webgl vertex shader high int precision:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).precision
      );
      attributes.push(
        "webgl vertex shader high int precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMin
      );
      attributes.push(
        "webgl vertex shader high int precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMax
      );
      attributes.push(
        "webgl vertex shader medium int precision:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).precision
      );
      attributes.push(
        "webgl vertex shader medium int precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMin
      );
      attributes.push(
        "webgl vertex shader medium int precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMax
      );
      attributes.push(
        "webgl vertex shader low int precision:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).precision
      );
      attributes.push(
        "webgl vertex shader low int precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMin
      );
      attributes.push(
        "webgl vertex shader low int precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMax
      );
  
      attributes.push(
        "webgl fragment shader high int precision:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).precision
      );
      attributes.push(
        "webgl fragment shader high int precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMin
      );
      attributes.push(
        "webgl fragment shader high int precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMax
      );
      attributes.push(
        "webgl fragment shader medium int precision:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).precision
      );
      attributes.push(
        "webgl fragment shader medium int precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMin
      );
      attributes.push(
        "webgl fragment shader medium int precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMax
      );
      attributes.push(
        "webgl fragment shader low int precision:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).precision
      );
      attributes.push(
        "webgl fragment shader low int precision rangeMin:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMin
      );
      attributes.push(
        "webgl fragment shader low int precision rangeMax:" +
          gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMax
      );
    }
  
    return attributes.join("~");
  };
  