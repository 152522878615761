import React, { useEffect, useState } from "react";
import styles from "./Lobby.module.css";

import { useSelector } from "react-redux";
import { APIPlayGame } from "../../../Api/GamesApi";
import useLogout from "../../Layout/Header/useLogout";
import LoginPopupModal from "../../Login/LoginPopupModal";
import playIcon from "../../../Assets/home/Play Icon.png";

import Loader from "../../HelperComponents/Loader";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
const Lobby = (props) => {
  const { games, categoryType } = props;

  const [loadingGameId, setLoadingGameId] = useState(null);
  const userReduxData = useSelector((state) => state?.user?.user);
  const [popupError, setPopupError] = useState("");
  const navigate = useNavigate();
  const logout = useLogout();

  const APIPlayGameFunc = async (id) => {
    let res;
    if (userReduxData) {
      setLoadingGameId(id);
      let newWindow = window.open();

      res = await APIPlayGame(id);
      if (res.status && res.message === "SUCCESS") {
        if (res.result.is_url) {
          newWindow.location = res?.result?.data;
        } else {
          const scriptString = res?.result?.data;
          // Separate the scripts
          const scripts = scriptString.split("</script>").filter(Boolean);

          scripts.forEach((scriptContent) => {
            const script = document.createElement("script");
            if (scriptContent.includes("src=")) {
              // External script
              const srcMatch = scriptContent.match(/src="([^"]+)"/);
              const src = srcMatch ? srcMatch[1] : null;
              if (src) {
                script.src = src;
                const integrityMatch =
                  scriptContent.match(/integrity="([^"]+)"/);
                if (integrityMatch) {
                  script.integrity = integrityMatch[1];
                }
                const crossoriginMatch = scriptContent.match(
                  /crossorigin="([^"]+)"/
                );
                if (crossoriginMatch) {
                  script.crossOrigin = crossoriginMatch[1];
                }
                document.head.appendChild(script);
              }
            } else {
              // Inline script
              const inlineScriptContent = scriptContent.replace(
                /<script type = "text\/javascript">/,
                ""
              );
              script.text = inlineScriptContent.trim();
              document.head.appendChild(script);
            }
          });
        }
      } else if (res.response?.status === 403) {
        newWindow.close();
        if (res.response.data.message === "PLAYER_FORBIDDEN") {
          setPopupError(
            "Player is not allowed to play games, Please contact the customer support to activate your account."
          );
        } else if (res.response.data.message === "REGISTRATION_NETWORK_ERROR") {
          setPopupError("REGISTRATION NETWORK ERROR");
        } else if (res.response.data.message === "CURRENCY_NOT_SUPPORTED") {
          setPopupError("CURRENCY NOT SUPPORTED");
        } else if (res.response.data.message === "LOGIN_NETWORK_ERROR") {
          setPopupError("LOGIN NETWORK ERROR");
        } else {
          setPopupError(
            "Player is not allowed to play games, Please contact the customer support to activate your account."
          );
        }
      } else if (res.response?.status === 401) {
        newWindow.close();
        logout();
      } else if (res?.response?.status === 400) {
        newWindow.close();
        if (res?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        }
      } else {
        newWindow.close();
        setPopupError("An unexpected error occurred. Please try again later.");
      }
      setLoadingGameId(null);
    } else {
      logout();
    }
  };
  return (
    <>
      <div className={styles.lobbyWrapper}>
        {loadingGameId ? (
          <Loader />
        ) : (
          <div className={styles.gameList}>
            {games.map((game) => (
              <div
                key={game.id}
                className={styles.game}
                // onClick={() => APIPlayGameFunc(game.game_item_id)}
              >
                <div
                  className={`${
                    categoryType == "CockFight" ? "" : styles.imageContainer
                  }`}
                >
                  {categoryType != "CockFight" && (
                    <div className={styles.imageOverlay}>
                      <div className={styles.playButtonContainer}>
                        <img
                          src={playIcon}
                          alt="playIcon"
                          className="imgStyle"
                          onClick={() => APIPlayGameFunc(game.game_item_id)}
                        />
                      </div>
                    </div>
                  )}

                  <img
                    src={game.icon_rectangle}
                    alt={game.game_id}
                    className={styles.gameImage}
                  />
                </div>
              </div>
            ))}
            {categoryType == "CockFight" && (
              <div className={styles.playNowBtn}>
                <button
                  className={styles.btnStyle}
                  onClick={() => APIPlayGameFunc(games[0].game_item_id)}
                >
                  Play Now
                </button>
              </div>
            )}
          </div>
        )}

        {popupError && (
          <LoginPopupModal
            message={popupError}
            show={popupError ? true : false}
            hideModal={() => {
              setPopupError("");
              if (
                popupError ===
                "Player is not allowed to play games, Please contact the customer support to activate your account."
              ) {
                navigate("/support");
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default Lobby;
