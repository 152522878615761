export default function TimeConverter(serverdate) {
    const date = serverdate?.toISOString([])
    const utcDateWithoutMillis = date?.slice(0, -5) + "Z";
    const utcDate = new Date(utcDateWithoutMillis);

    // Step 2:
    const offsetMinutes = utcDate?.getTimezoneOffset();

    // Step 3:
    const localTime = new Date(utcDate?.getTime() - offsetMinutes * 60 * 1000);

    // Display Local Time
    const localTimeString = localTime?.toLocaleString();

    return localTimeString;
}