import { Typography } from "antd";
import React from "react";


const CustomText = ({text, styling}) =>{

    return(
        <Typography.Text className={styling}>{text}</Typography.Text>
    );
};

export default CustomText;