import {
  isMobileOnly,
  isTablet,
  isDesktop,
  browserName,
} from "react-device-detect";

export function DeviceAndBrowserInfo() {
  let deviceType = "";

  if (isMobileOnly) {
    deviceType = "Mobile";
  } else if (isTablet) {
    deviceType = "Tablet";
  } else if (isDesktop) {
    deviceType = "Desktop";
  }

  return {
    browserName: browserName,
    deviceType: deviceType,
  };
}
