import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
// import CustomGrid from '../HelperComponents/CustomGrid';
// import styles from "../Home/Home.module.css";
import HomePageAllGames from '../Home/HomePageAllGames';

const Games = () => {
  const { name } = useParams();
  const location = useLocation();
  const games = location.state?.games;
  const title = name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return (
    <>
      <HomePageAllGames games={games} />
    </>
  )
}

export default Games
