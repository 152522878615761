import { api } from "./axios";

export const getAffiliate = async () => {
    try {
      const res = await api.get(`player/affiliate/inviteFriendsOverview`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      if (res?.data) {
        return res.data;
      }
      return res;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  export const getAffiliateRecord = async (
    start_data,
    end_date,
    page
  ) => {
    try {
      // Construct the base URL
      let url = `player/affiliate/downlines?per_page=10&page=${page}`;
  
      // Append start_data if it exists
      if (start_data) {
        url += `&from_date=${start_data}`;
      }
  
      // Append end_date if it exists
      if (end_date) {
        url += `&to_date=${end_date}`;
      }
  
      const res = await api.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      if (res?.data) {
        return res.data;
      }
  
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  };