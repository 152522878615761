import { createSlice } from "@reduxjs/toolkit";
export const GameSlice = createSlice({
    name: "game",
    initialState: {
        gameCategories: null,
        gameWithdrawList: [],
        allCategoriesGame: null,
    },
    reducers: {
        setGameCategories: (state, action) => {
            state.gameCategories = action.payload;
        },
        setGameRates: (state, action) => {
            state.gameRates = action.payload;
        },
        setGameWithdrawlist: (state, action) => {
            state.gameWithdrawList = action.payload;
        },
        setAllCategoriesGame: (state, action) => {
            state.allCategoriesGame = action.payload;
        },
        setActiveCatIdRedux: (state, action) => {
            state.activeCatId = action.payload;
        },
    },
});

export const {
    setGameCategories,
    setGameWithdrawlist,
    setAllCategoriesGame,
    setGameRates,
    setActiveCatIdRedux,
} = GameSlice.actions;

export default GameSlice.reducer;
