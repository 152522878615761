import React from 'react';
import PageNotFoundImage from '../../Assets/Page Not Found Image.png';
import styles from './PageNotFound.module.css';
import { useNavigate } from 'react-router-dom';
const PageNotFound = () => {

    const navigate = useNavigate();

    const handleGoBackHome = () => {
        navigate("/");
        window.location.reload();
    };

    return (
        <div className={styles.wrapper}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column'}}>
                <div style={{ maxWidth:445, width:'100%', maxHeight: 420, height:'100%', margin:'auto' }}>
                    <img alt="Not Found" src={PageNotFoundImage} style={{ width: "100%", height: "100%", opacity: 1, background: "transparent url('../../Assets/Page Not Found Image.png') 0% 0% no-repeat padding-box", objectFit: 'contain' }} />
                </div>
                <div className={styles.goBackHomePageText} onClick={handleGoBackHome}>
                    GO BACK TO HOME PAGE
                </div>
            </div>

        </div>
    )
}

export default PageNotFound