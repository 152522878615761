import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./PopupErrorModal.module.css";
import errorIcon from "../../Assets/Error.png";
import successIcon from "../../Assets/Success.png";
import { useNavigate } from "react-router";
// import { WithdrawAPI } from "../helpers/APIs/WithdrawAPI";
// import { withdrawAllPoints } from '../helpers/APIs/GameAPIs';
// isWithdraw, setErrorMessage, setErrorModal,

const PopupErrorModal = ({
  message,
  show,
  hideModal,
  error = true,
  amount,
  selectedBankId,
  rejectDeposit = false,
  rejectWithdraw = false,
  blockDeposit = false,
  blockWithdraw = false,
  bankId,
  transactionAmount,
  bankAccountNumber,
  modalClose,
  agentOkBtn = false,
}) => {
  // invoiceFile,
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={show ? true : false}
      onClose={hideModal}
      footer={null}
      closable={false}
    >
      <div className={styles.modalOverlay}>
        <div className={styles.loadingSection}>
          {error ? (
            <img src={errorIcon} className={styles.errorImg} alt="errorImg" />
          ) : (
            <img
              src={successIcon}
              className={styles.errorImg}
              alt="successImg"
            />
          )}
          <div style={{ color: "white" }}>{message}</div>
          <div className={styles.rejectButtons}>
            <button
              onClick={() => window.open("https://direct.lc.chat/13775445/")}
            >
              Chăm sóc khách hàng
            </button>
            <button
              className={styles.okButton}
              onClick={() => {
                rejectDeposit || rejectWithdraw
                  ? navigate("/account/transactions")
                  : navigate("/");
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PopupErrorModal;
