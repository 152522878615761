import React from "react";
import styles from "./MarqueeText.module.css";
import CustomMarquee from "../HelperComponents/CustomMarquee";
import Announcement from "../../Assets/home/anounce.png";

const marqIcon = {
  maxWidth: "20px",
  maxHeight: "20px",
  width: "100%",
  height: "100%",
  margin: "5px",
};

const marqText =
  "For the security of your account and funds, please do not disclose your account and password to others to avoid losses.";

const MarqueeText = () => {
  return (
    <div className={styles.paddingWrapper}>
      <div className={styles.marqueOverlay}>
        <CustomMarquee
          cls={styles.marqueeWrapper}
          img={Announcement}
          extraStyle={marqIcon}
          text={marqText}
          textStyle={styles.textStyling}
        />
      </div>
    </div>
  );
};

export default MarqueeText;
